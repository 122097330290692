import React from 'react'
import {
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'

function ErrorMessage({ message }: { message: string }) {
  return <div className="text-red-500 text-center ">{message}</div>
}

const createErrorMessagesForFieldError = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
  key: string
) => {
  if (typeof error.message === 'string') {
    return <ErrorMessage message={error.message} key={`form_errors_${key}`} />
  } else if (Array.isArray(error.message)) {
    return error.message.map((mes) => (
      <ErrorMessage message={mes} key={`form_errors_${key}`} />
    ))
  } else {
    return Object.keys(error).map((messageKey) => {
      return createErrorMessagesForFieldError(error[messageKey], key)
    })
  }
}

const createRootErrorMessages = (errors: FieldErrors, key: string) => {
  const error = errors[key]
  return createErrorMessagesForFieldError(error, key)
}

function GlobalErrors({ errors }: { errors: FieldErrors | null }) {
  if (!errors || !errors.root) return null

  return createRootErrorMessages(errors, 'root')
}

export { GlobalErrors }
