import React, { useState, useEffect } from 'react'
import { MotionButton } from '../../../components/ui/motion-button'
import { useUsers } from '../../../hooks/users'
import { useAuth } from '../../../hooks/useAuth'
import { User } from '@/types/User'

const EditUser: React.FC = () => {
  const [user, setUser] = useState<User|null>(null)
  const {currentUser} = useAuth()
  const {updateUser} = useUsers()

  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])

  // const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   if (user) {
  //     const {name, value} = e.target
  //     setUser({...user, [name]: value})
  //   }
  // }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user) {
      const {name, value} = e.target
      setUser({...user, [name]: value})
    }
  }

  const handleSave = async () => {
    if (user) {
      try {
        await updateUser(user)
        alert('User information saved successfully.')
      } catch (error) {
        console.error('Failed to update user:', error)
        alert('An error occurred while saving user information.')
      }
    } else {
      alert('No user data available to save.')
    }
  }

  return (
    <div className="flex flex-col p-6 border w-full lg:w-1/2 m-auto bg-grays-light rounded">
      <h2 className="font-medium text-primary mb-4">Edit User</h2>
      <form className="space-y-4">
        <div>
          <label className="block mb-1">ID</label>
          <input
            type="text"
            name="id"
            value={user?.id || ''}
            readOnly
            className="w-full p-2 border border-gray-300 rounded bg-gray-200"
          />
        </div>
        <div>
          <label className="block mb-1">Username</label>
          <input
            type="text"
            name="username"
            value={user?.username || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded bg-gray-200"
            readOnly
          />
        </div>
        <div>
          <label className="block mb-1">First Name</label>
          <input
            type="text"
            name="first_name"
            value={user?.first_name || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={user?.last_name || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Email</label>
          <input
            type="email"
            name="email"
            value={user?.email || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        {/*<div>*/}
        {/*  <label className="block mb-1">User Level</label>*/}
        {/*  <select*/}
        {/*    name="user_level"*/}
        {/*    onChange={handleSelectChange}*/}
        {/*    value={user.user_level || ''}*/}
        {/*    className="w-full p-2 border border-gray-300 rounded bg-gray-200"*/}
        {/*    disabled={true}*/}
        {/*  >*/}
        {/*    <option value="user">User</option>*/}
        {/*    <option value="power_user">Shop Manager</option>*/}
        {/*  </select>*/}
        {/*</div>*/}
        <div className="flex gap-2">
          <MotionButton
            type="button"
            onClick={handleSave}
            className="text-white p-2 rounded bg-primary"
          >
            Save
          </MotionButton>
        </div>
      </form>
    </div>
  )
}

export default EditUser
