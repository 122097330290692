const HowToStartClass = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click <strong>Access Your Training</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Access_Training.png" alt="Courses to start" />
        </div>
      </li>
      <li>
        Click the <strong>User Menu</strong> in the top left corner and select{' '}
        <strong>Course Catalog</strong>
      </li>
      <li>
        Click on <strong>Advanced Online Training</strong> to display all
        available courses (this screen initially only displays a sample of the
        included courses)
        <div className="my-4 border rounded">
          <img src="/images/faqs/All_Courses.png" alt="" />
        </div>
      </li>
      <li>
        The search bar is helpful to narrow down courses by keywords (like
        hybrid or brakes)
      </li>
      <li>
        Click on a course, then click <strong>Enroll</strong>
      </li>
      <li>
        Once “Enroll” is clicked for a course it will show up on their homepage
        for easy access and tracking
      </li>
    </ol>
  </div>
)

export default HowToStartClass
