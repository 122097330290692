import { stringify } from 'qs'

function isNil(obj: any) {
  return obj == null
}

export const filterEmptyOrNull = (params: any) =>
  Object.keys(params || {})
    .filter((key) => !isNil(params[key]))
    .filter((key) => params[key] !== '')
    .reduce((obj, key) => Object.assign({}, obj, { [key]: params[key] }), {})

export const stringifyParams = (params: any) =>
  stringify(filterEmptyOrNull(params), {
    arrayFormat: 'brackets',
    skipNulls: true,
    format: 'RFC1738',
  })

export const loadMonthlySchedule = () => {
  const now = new Date()
  const currentMonth = now.getMonth() + 1
  const currentYear = now.getFullYear()

  const filename = `/pdfs/monthly_schedule_${currentMonth
    .toString()
    .padStart(2, '0')}-${currentYear}.pdf`

  return filename
}

export const loadNextMonthlySchedule = () => {
  const now = new Date()
  const currentMonth = now.getMonth() + 2
  const currentYear = now.getFullYear()

  const filename = `/pdfs/monthly_schedule_${currentMonth
    .toString()
    .padStart(2, '0')}-${currentYear}.pdf`

  return filename
}

export const formatBulletPoints = (text) => {
  const formattedText = text.replace(/\s*[-•]\s*/g, '\n• ').trim()
  return formattedText.replace(/\n• /, '\n\n• ')
}

export const getFutureMonth = (monthsAhead) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const currentDate = new Date()
  const currentMonthIndex = currentDate.getMonth()
  const targetMonthIndex = (currentMonthIndex + monthsAhead) % 12
  const adjustedTargetMonthIndex =
    targetMonthIndex < 0 ? 12 + targetMonthIndex : targetMonthIndex

  return months[adjustedTargetMonthIndex]
}

export const stripTags = (html) => {
  if (!html) return html // Return if the input is null or undefined
  return html.replace(/<\/?[^>]+(>|$)/g, '')
}

export const cleanCourses = (courses) => {
  return courses.map((course) => ({
    ...course,
    courseDescription: stripTags(course.courseDescription),
  }))
}

export const convertMinsToHrsMins = (mins: number) => {
  const h = Math.floor(mins / 60)
  const m = mins % 60
  const ms = m < 10 ? '0' + m : m
  return `${h}:${ms} hrs`
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
