const HowToEnroll = () => (
  <div>
    <div className="text-primary border-2 mb-4 bg-grays-light border-primary rounded px-4 py-2">
      <strong>Note:</strong> Your Shop Owner/Manager will have to initiate this
      process. Please refer to the Shop Owner/Manager FAQ page for Virtual
      Classroom instructions
    </div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click <strong>Access Your Training</strong>
      </li>
      <li>
        Scroll down the homepage to the <strong>Courses to Start</strong>{' '}
        section and click on the Virtual Classroom course you have been enrolled
        in
        <div className="my-4 border rounded">
          <img
            src="/images/faqs/Enroll_Virtual_Class_Course.png"
            alt="Courses to start"
          />
        </div>
      </li>
      <li>
        Click on Choose Session and select the correct upcoming date & time,
        then click Confirm (be sure to select the correct date and time if there
        are multiple options)
        <div className="my-4 border rounded">
          <img src="/images/faqs/Choose_session.png" alt="Courses to start" />
        </div>
        <div className="my-4 border rounded">
          <img
            src="/images/faqs/Choose_session_confirm.png"
            alt="Courses to start"
          />
        </div>
      </li>
      <li>
        The class will now show up on the user's Training Calendar on their
        homepage
      </li>
      <li>
        When it's time to join the class, click on the correct session in the
        Calendar on the homepage, then click <strong>Join</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Calendar.png" alt="Courses to start" />
        </div>
      </li>
      <li>
        The class workbook (syllabus) is available to download once enrolled in
        the class
        <div className="my-4 border rounded">
          <img src="/images/faqs/Syllabus.png" alt="Courses to start" />
        </div>
      </li>
    </ol>
  </div>
)

export default HowToEnroll
