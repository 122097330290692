import { create } from 'zustand'

import { API_URL } from '@/lib/constants'
import { axiosInstance } from '@/lib/services/axiosinstance'
import { User } from '@/types/User'
import { Hash } from '@/types/Hash'
import { Result } from '@/types/Result'
import handleRailsErrorResponse from '@/lib/support/handleRailsErrorResponse'

const SIGN_IN_URL = `${API_URL}/api/v1/sessions`
const SIGN_UP_URL = `${API_URL}/api/v1/registrations`
const PASSWORDS_URL = `${API_URL}/api/v1/passwords`
const RESET_PASSWORD_URL = `${API_URL}/api/v1/passwords/send_reset_link`
const TOKEN_KEY = 'cti_token'

const getAuthHeaders = () => {
  const jwt = getJWTString()
  if (jwt) {
    return {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
  } else {
    return {}
  }
}

function decodeJWT(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

function saveUserInLocalStorage(jwt: string) {
  const decodedJWT = decodeJWT(jwt)
  const user = decodedJWT.user as User

  localStorage.setItem(TOKEN_KEY, jwt)

  return user
}

function getUserFromLocalStorage() {
  const jwtString = getJWTString()
  const jwt = decodeJWT(jwtString)
  if (jwt == null) {
    console.log('jwt is null')
    return null
  }

  const seconds = Math.floor(Date.now() / 1000)
  if (jwt.exp < seconds) {
    console.log('jwt has expired')
    return null
  } else {
    console.log('found current user')
    return jwt.user as User
  }
}

function getJWTString() {
  return localStorage.getItem(TOKEN_KEY)
}

async function signInOnServer(
  email: string,
  password: string
): Promise<Result<JWTString>> {
  try {
    const res = await axiosInstance.post(SIGN_IN_URL, {
      user: {
        username: email,
        password: password,
      },
    })

    const { data } = res
    // const user = signInWithJwt(data.jwt)
    return {
      value: data.jwt,
      success: true,
    } as Result<string>
  } catch (err) {
    if (err.response) {
      const response = err.response

      switch (response.status) {
        case 401: {
          console.warn('Something went wrong on the server')
          return {
            success: false,
            errors: { base: 'Invalid email or password' },
          } as Result<JWTString>
        }
        case 422: {
          return {
            success: false,
            errors: {
              code: 'account_error',
            },
          }
        }
        default: {
          console.warn(
            'Something went wrong on the server. Status code: ',
            response.status
          )
          return {
            success: false,
            errors: {
              base: 'Something went wrong, please try again or contact us if the issue persists.',
            },
          } as Result<JWTString>
        }
      }
    } else {
      return {
        success: false,
        errors: {
          base: 'Something went wrong, please try again or contact us if the issue persists.',
        },
      } as Result<JWTString>
    }
  }
}

export interface ISignup {
  first_name: string
  last_name: string
  address_line1?: string
  address_line2?: string
  city?: string
  state?: string
  postal_code?: string
  shop_name?: string
  account_number?: string
  email: string
  phone_number?: string
  password: string
  password_confirmation: string
}

type JWTString = string

interface UserAuth {
  isAuthenticated: () => boolean
  signIn: (email: string, password: string) => Promise<Result<JWTString>>
  signUp: (params: ISignup) => Promise<boolean>
  signOut: (navigate: (path: string) => void, location: string) => void
  resetPassword: (email: string) => void
  changePassword: (
    currentPassword: string,
    password: string,
  ) => void
  getAuthHeaders: () => Hash<string>
  loading: boolean
  errors: Hash<string> | null
  currentUser: null | User
}

export const useAuth = create<UserAuth>((set, get) => ({
  loading: false,
  currentUser: getUserFromLocalStorage(),
  errors: null,
  changePassword: async (currentPassword: string, password: string) => {
    set({ loading: true })
    try {
      const response = await axiosInstance.put(
        PASSWORDS_URL,
        {
          user: {
            current_password: currentPassword,
            password: password,
          },
        },
        { headers: getAuthHeaders() }
      )

      if (response.status !== 200) {
        throw new Error('Password update failed')
      }

      return response.data
    } catch (error) {
      console.error('Password change error:', error)
      throw error
    } finally {
      set({ loading: false })
    }
  },
  signOut: (navigate: (path: string) => void, location: string) => {
    localStorage.removeItem(TOKEN_KEY)
    // clearStoredUser()
    set({ currentUser: null })

    const privateRoutes = [
      '/checkout',
      '/cart',
      '/profile',
      '/profile/settings',
      '/profile/settings/subscriptions',
      '/profile/settings/edituser',
      '/profile/settings/password',
      '/profile/settings/payment-methods',
      '/profile/settings/invoices',
      '/profile/admin',
      '/profile/admin/subscriptions',
      '/profile/admin/enroll',
      '/profile/admin/manageusers',
      '/profile/admin/enrolluser',
    ]
    if (privateRoutes.some((route) => location.startsWith(route))) {
      navigate('/')
    }
  },
  resetPassword: (email) => {
    set({ loading: true })
    axiosInstance
      .post(RESET_PASSWORD_URL, {
        user: {
          email,
        },
      })
      .then((response) => {
        console.log(response)
      })
      .catch((err) => {
        console.error('Error trying to reset password', err)
        set({ errors: { base: 'We were unable to reset your password' } })
      })
      .finally(() => {
        set({ loading: false })
      })
  },
  signIn: async (email: string, password: string) => {
    set({ loading: true })
    const result = await signInOnServer(email, password)

    if (result.success) {
      const user = saveUserInLocalStorage(result.value)
      set({ currentUser: user, errors: null })
    } else {
      set({ errors: result.errors })
    }
    set({ loading: false })
    return result
  },
  signUp: async (params: ISignup) => {
    set({ loading: true })
    try {
      const response = await axiosInstance.post(SIGN_UP_URL, {
        user: params,
      })
      const { data } = response
      const user = saveUserInLocalStorage(data.access_token)

      set({ currentUser: user, errors: null })
      return true
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const response = error.response
        if (response.data && response.data.errors) {
          set({ errors: response.data.errors })
        }
      } else {
        set({ errors: { base: error.message } })
      }

      return false
    } finally {
      set({ loading: false })
    }
  },
  isAuthenticated: () => {
    //return !!get().currentUser

    const user = getUserFromLocalStorage()
    if (user) {
      return true
    } else {
      return false
    }
  },
  getAuthHeaders: getAuthHeaders,
}))
