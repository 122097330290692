import React from 'react'
import { SessionData } from '@/lib/services/search/flattenCourseData'
import { priceInDollars } from '@/lib/priceConversions'
import useCartStore from '@/store/cart'
import Product from '@/types/Product'
import { toast } from '../ui/use-toast'
import { MotionButton } from '../ui/motion-button'
import TechnetLogo from '../Technet/TechnetLogo'
import { isTechnetPriceForProduct } from '@/lib/technet'

function SignUpLinks({ result }: { result: SessionData }) {
  const { addItemToCart } = useCartStore()

  const enroll = (plan: Product) => {
    const result = addItemToCart(plan)
    if (result.success) {
      toast({
        title: 'Added Plan to Cart',
        description: `Added plan: ${plan.name} to your cart`,
      })
    } else {
      toast({
        title: result.errors.base,
      })
    }
  }

  return (
    <div className="flex gap-x-2">
      {result.plans.map((plan) => (
        <div
          key={plan.id}
          className="flex flex-col justify-between rounded bg-white shadow-sm ring-1 ring-gray-900/10 sm:p-4"
        >
          <div className="mb-2">
            <h3 className="font-semibold text-primary">
              {plan.name}
              {isTechnetPriceForProduct(plan) && (
                <TechnetLogo className="ml-2" size="sm" />
              )}
            </h3>
            <p className="text-xl font-semibold tracking-tight text-gray-900">
              {priceInDollars(plan.price)}
            </p>
            <p className="text-sm text-gray-600">{plan.description}</p>
            <p className="text-xs">{plan.id}</p>
          </div>
          Add to cart
          <MotionButton key={plan.id} onClick={() => enroll(plan)}>
            Add to cart
          </MotionButton>
        </div>
      ))}
    </div>
  )
}

export default SignUpLinks
