import { useState, Fragment } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { User } from '@/types/User'
import useCartStore from '@/store/cart'
import { PencilIcon, CogIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid'
import LinkToLMSHamburger from './LinkToLMSHamburger'

const Hamburger = ({
  currentUser,
  signOut,
}: {
  currentUser: User | null
  signOut: (navigate: (path: string) => void, location: string) => void
}) => {
  const { hasItems } = useCartStore()
  const navigate = useNavigate()
  const router = useLocation()

  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  const handleToggle = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  const closeHamburger = () => {
    setHamburgerOpen(false)
    const menuCheckbox = document.getElementById(
      'menu_checkbox'
    ) as HTMLInputElement
    menuCheckbox.checked = false
  }

  return (
    <nav className="navBar w-full container mx-auto flex justify-between items-center">
      <Link to="/">
        <img
          src="/images/CTI_WTI_Logo.png"
          alt="Carquest Technical Institute"
          className="w-36"
        />
      </Link>
      <div>
        <input type="checkbox" id="menu_checkbox" onClick={handleToggle} />
        <label className="hamburger-label" htmlFor="menu_checkbox">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>

      <ul
        id="hamburger-menu"
        className={hamburgerOpen ? 'slide-in' : 'slide-out'}
      >
        {currentUser && (
          <li className="hamburger-menu-item text-left pl-4 py-1.5">
            <LinkToLMSHamburger />
          </li>
        )}
        <li className="hamburger-menu-item text-left py-1.5">
          <Link to="/cart" className="text-sm py-4" onClick={closeHamburger}>
            <div className="flex px-4">
              <p className="mr-2">Cart</p>
              {hasItems() ? (
                <img
                  src={'/images/shopping_cart_full_white.svg'}
                  alt="cart"
                  className="h-5 w-5 mr-2 inline text-white"
                ></img>
              ) : (
                <img
                  src={'/images/shopping_cart_empty_white.svg'}
                  alt="cart"
                  className="h-5 w-5 mr-2"
                ></img>
              )}
            </div>
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link
            to="/destination"
            className="text-sm px-4"
            onClick={closeHamburger}
          >
            Destination
          </Link>
        </li>

        <li className="hamburger-menu-item text-left py-1.5">
          <Link
            to="/help-faq"
            className="text-sm px-4"
            onClick={closeHamburger}
          >
            Help & FAQ
          </Link>
        </li>

        <li className="hamburger-menu-item text-left py-1.5">
          <Link to="/catalog" className="text-sm px-4" onClick={closeHamburger}>
            Catalog
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link
            to="/monthlyschedule"
            className="text-sm px-4"
            onClick={closeHamburger}
          >
            Monthly Schedule
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link to="/about" className="text-sm px-4" onClick={closeHamburger}>
            About CTI
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link to="/rdc" className="text-sm px-4" onClick={closeHamburger}>
            R &#38; D Center
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link
            to="/instructors"
            className="text-sm px-4"
            onClick={closeHamburger}
          >
            Meet Our Instructors
          </Link>
        </li>
        <li className="hamburger-menu-item text-left py-1.5">
          <Link
            to="/contact-us"
            className="text-sm px-4"
            onClick={closeHamburger}
          >
            Contact Us
          </Link>
        </li>
        {!currentUser && (
          <li className="hamburger-menu-item text-left py-1.5">
            <Link
              to={'/signin'}
              className="text-sm px-4"
              onClick={closeHamburger}
            >
              Sign in
            </Link>
          </li>
        )}
        {!currentUser && (
          <li className="hamburger-menu-item text-left py-1.5">
            <Link
              to={'/signup'}
              className="text-sm px-4"
              onClick={closeHamburger}
            >
              Sign up
            </Link>
          </li>
        )}
        {currentUser && (
          <Menu>
            <Menu.Button className="px-4 flex py-1.5 focus:outline-none">
              <p className="text-sm text-white">My Profile</p>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                // anchor="bottom end"
                className="relative mt-2 bg-menu/85 origin-top-right rounded border
                    border-white/5  p-1 text-sm/6 text-white transition
                    duration-100 ease-out [--anchor-gap:var(--spacing-1)]
                    focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <Menu.Item>
                  <Link
                    to="/profile/admin"
                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                  >
                    <PencilIcon className="size-4 fill-white/30" />
                    Admin
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/profile/settings"
                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                  >
                    <CogIcon className="size-4 fill-white/30" />
                    Settings
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    onClick={() => signOut(navigate, router.pathname)}
                  >
                    <ArrowRightStartOnRectangleIcon className="size-4" />
                    Signout
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </ul>
    </nav>
  )
}

export default Hamburger
