import React from 'react'
import { Invoice } from '@/hooks/useInvoices'
import { Link } from 'react-router-dom'
import CombinedInvoice from './Invoices/CombinedInvoice'
import LinkToLMSButton from './Nav/LinkToLMSButton'

export interface OrderConfirmedProps {
  message: string
  invoices: Invoice[]
}

function OrderConfirmed({ message, invoices }: OrderConfirmedProps) {
  return (
    <>
      <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
        <div className="mx-auto max-w-4xl px-4 2xl:px-0">
          <h2 className="text-center text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {message}
          </h2>
          <CombinedInvoice invoices={invoices} />
          <div className="group pointer-events-auto w-full overflow-hidden rounded-md border p-6 pr-8 shadow-lg ">
            Purchasing a seat for an in-person or virtual class? Enroll in the
            selected plan using the admin dashboard{' '}
            <Link
              className="text-blue-800 font-semibold"
              to={'/profile/admin/enroll'}
            >
              here
            </Link>{' '}
            or email us at{' '}
            <Link
              className="font-semibold "
              to={'mailto:ctisupport@carquest.com'}
            >
              ctisupport@carquest.com
            </Link>{' '}
            and tell us which class you want to attend to reserve your seat.
          </div>
          <div className="flex items-center justify-center space-x-4 mt-4">
            <Link
              to="/"
              className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Return To Shopping
            </Link>
            <LinkToLMSButton />
          </div>
        </div>
      </section>
    </>
  )
}

export default OrderConfirmed
