import React from 'react'

import { Label } from '../ui/label'
import { RadioGroupItem } from '../ui/radio-group'

interface Props {
  htmlFor: string
  value: string
  onClick: (event) => void
}

const PaymentItem: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  htmlFor,
  value,
  children,
}) => {
  return (
    <Label
      htmlFor={htmlFor}
      className="w-full p-2 cursor-pointer flex flex-center space-x-2 [&:has(:checked)]:bg-zinc-300 dark:[&:has(:checked)]:bg-zinc-800 rounded"
    >
      <div className="flex items-center gap-4">
        <RadioGroupItem value={value} id={value} onClick={onClick} />
        {children}
      </div>
    </Label>
  )
}

export default PaymentItem
