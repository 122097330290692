import React, { useState } from 'react'
import faqDocs from '@/data/faq_docs'

const HelpFaq = () => {
  const [activeTab, setActiveTab] = useState('General Users')
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const handleTabChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveTab(event.target.value)
    setSelectedQuestion(null) // Reset selected question when switching tabs
  }

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedQuestion(Number(event.target.value))
  }

  return (
    <div className="flex flex-col h-screen overflow-auto container">
      <div className="flex flex-col md:flex-row flex-1">
        {/* Sidebar */}
        <div className="md:w-1/4 bg-gray-100 p-4 overflow-y-auto">
          <label
            htmlFor="faq-dropdown"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Select FAQ Category:
          </label>
          <select
            className="w-full rounded mb-4"
            id="faq-dropdown"
            onChange={handleTabChange}
            value={activeTab}
          >
            <option value="General Users">General Users</option>
            <option value="Shop Owners and Managers">
              Shop Owners and Managers
            </option>
          </select>

          {/* Questions List */}
          <div className="hidden md:block">
            {faqDocs[activeTab].map((item, index) => (
              <button
                key={index}
                className={`block w-full text-sm text-left px-4 py-2 my-1 rounded ${
                  selectedQuestion === index ? 'bg-blue-200' : 'bg-white'
                } hover:bg-blue-100`}
                onClick={() => setSelectedQuestion(index)}
              >
                {item.question}
              </button>
            ))}
          </div>

          {/* Select menu for questions on small screens */}
          <div className="block md:hidden">
            <label
              htmlFor="question-dropdown"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Select a Question:
            </label>
            <select
              className="w-full rounded"
              id="question-dropdown"
              onChange={handleQuestionChange}
              value={selectedQuestion ?? ''}
            >
              <option value="" disabled>
                Select a question
              </option>
              {faqDocs[activeTab].map((item, index) => (
                <option key={index} value={index}>
                  {item.question}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full bg-primary rounded text-white py-4 px-1 text-sm text-center mt-6">
            <p>Need help with something else?</p>
            <p> Send us an email at </p>
            <p className="underline font-semibold text-ellipsis overflow-hidden">
              <a href="mailto:ctisupport@carquest.com">
                ctisupport@carquest.com
              </a>
            </p>
          </div>
        </div>

        {/* Documentation Display */}
        <div className="flex-1 px-6 py-4">
          {selectedQuestion !== null ? (
            <div>
              <h2 className="text-lg font-bold mb-4 text-gray-800">
                {faqDocs[activeTab][selectedQuestion].question}
              </h2>
              <div className="text-gray-700">
                {faqDocs[activeTab][selectedQuestion].answer}
              </div>
            </div>
          ) : (
            <p className="text-gray-700">
              Select a help topic from the list below.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default HelpFaq
