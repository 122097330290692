import lunr from 'lunr'
import analyze from './textAnalylzer'
import { SessionData } from './flattenCourseData'

export default function buildIndex(sessions: SessionData[]) {
  return new Promise<lunr.Index>((resolve) => {
    const idx = lunr(function () {
      this.ref('uid')

      this.field('uid')
      this.field('courseName')
      this.field('courseCode')
      this.field('courseType')
      this.field('analyzedCode')
      this.field('courseDescription')
      this.field('instructors')

      sessions.forEach((session) => {
        const instructorsList = session.instructors ?? []
        
        const doc = {
          uid: session.uid,
          courseName: session.courseName,
          courseCode: session.courseCode,
          courseType: session.courseType,
          analyzedCode: analyze(session.courseCode),
          courseDescription: session.courseDescription,
          instructors: instructorsList.length
            ? instructorsList.map(
                (instructor) => `${instructor.firstname} ${instructor.lastname}`
              ).join(', ')
            : '',
        }
        this.add(doc)
      }, this)
    })

    resolve(idx)
  })
}
