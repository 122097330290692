import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

import { useAuth } from '@/hooks/useAuth'
import convertServerErrors from '@/lib/support/convertServerErrors'
import { GlobalErrors } from '../GlobalErrors/GlobalErrors'
import { useToast } from '../ui/use-toast'

const formSchema = z
  .object({
    current_password: z.string().min(2, {
      message: 'Current password is required',
    }),
    password: z
      .string()
      .min(8, { message: 'Password must be at least 8 characters long' })
      .regex(/^(?!.*\d{3,})/, {
        message: 'Password cannot contain 3 or more consecutive numbers',
      }),
    password_confirmation: z.string().min(8, {
      message: 'Password confirmation is required',
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Passwords must match',
    path: ['password_confirmation'],
  })
  .refine((data) => data.password !== data.current_password, {
    message: 'Your new password must be different from your current password.',
    path: ['password'],
  })

function PasswordForm() {
  const { loading, errors, changePassword } = useAuth()
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    resolver: zodResolver(formSchema),
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await changePassword(values.current_password, values.password)
      toast({
        title: 'Success',
        description: 'Password updated successfully!',
      })
      form.reset()
    } catch (error) {
      console.error('Error updating password:', error)

      const errorMessage =
        (error as { response?: { data?: { message?: string } } })?.response
          ?.data?.message || 'An unknown error occurred.'

      if (errorMessage.includes('last 3 used passwords')) {
        toast({
          title: 'Error',
          description:
            'Your new password must not match the last 3 used passwords.',
          variant: 'destructive',
        })
      } else if (errorMessage.includes('not your current one')) {
        toast({
          title: 'Error',
          description: 'The current password you entered is incorrect.',
          variant: 'destructive',
        })
      } else {
        toast({
          title: 'Error',
          description: 'Failed to update password. Please try again.',
          variant: 'destructive',
        })
      }
    }
  }

  useEffect(() => {
    if (errors) {
      convertServerErrors(form.setError, errors)
    }
  }, [errors, form.setError])

  if (loading) return <p className="m-auto">Loading...</p>

  return (
    <>
      <div className="p-6">
        <GlobalErrors errors={form.formState.errors} />

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col justify-between h-full"
          >
            <FormField
              control={form.control}
              name="current_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Current Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password_confirmation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password Confirmation</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex w-full mt-6">
              <Button
                type="submit"
                className="w-1/2 m-auto text-base font-medium"
              >
                Update
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  )
}

export default PasswordForm
