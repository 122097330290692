import React, { useState, useEffect } from 'react'
import { MotionButton } from '../../components/ui/motion-button'
import { useUsers } from '../../hooks/users'
import { toast } from '../../components/ui/use-toast'
import { User, Branch, BranchList, CategorizedUsers } from '@/types/ManageUsers'
import { validateEmail } from '@/lib/helpers'
import EditUserForm from '../../components/UserForm/EditUserForm'
import NewUserForm from '../../components/UserForm/NewUserForm'

const ManageUsers: React.FC = () => {
  const { loadUsers, addUser, updateUser, loadBranches } = useUsers()
  const [categorizedUsers, setCategorizedUsers] = useState<CategorizedUsers>({
    shopOwners: [],
    shopManagers: [],
    regularUsers: [],
  })
  const [branchData, setBranchData] = useState<BranchList | null>(null)
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [newUser, setNewUser] = useState<User | null>(null)
  const [history, setHistory] = useState<Branch[]>([])
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [loadingBranches, setLoadingBranches] = useState<boolean>(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [newUserEmailError, setNewUserEmailError] = useState<string | null>(
    null
  )

  useEffect(() => {
    setLoadingBranches(true)
    loadBranches().then((data) => {
      setBranchData(data)
      setSelectedBranch(data?.branch || null)
      loadInitialUsers()
      if (data?.branch) fetchUsers(data.branch.id)
      setLoadingBranches(false)
    })
  }, [])

  const loadInitialUsers = async () => {
    setLoadingUsers(true)
    const users = await loadUsers()
    organizeUsers(users)
    setLoadingUsers(false)
  }

  const fetchUsers = async (branchId: string) => {
    setLoadingUsers(true)
    setCategorizedUsers({
      shopOwners: [],
      shopManagers: [],
      regularUsers: [],
    })
    const branchUsers = await loadUsers(branchId)
    organizeUsers(branchUsers)
    setLoadingUsers(false)
    setInitialLoadComplete(true)
  }

  const organizeUsers = (users: User[]) => {
    const shopOwners = users.filter(
      (user) =>
        user.user_level === 'power_user' && user.user_profile === 'shop_owner'
    )
    const shopManagers = users.filter(
      (user) =>
        user.user_level === 'power_user' && user.user_profile === 'shop_manager'
    )
    const regularUsers = users.filter((user) => user.user_level === 'user')

    setCategorizedUsers({
      shopOwners,
      shopManagers,
      regularUsers,
    })
  }

  const handleBranchSelect = (branch: Branch) => {
    if (selectedBranch) {
      setHistory((prevHistory) => [...prevHistory, selectedBranch])
    }

    setLoadingUsers(true)
    setLoadingBranches(true)
    setSelectedUser(null)
    setNewUser(null)

    loadBranches(branch.id).then((data) => {
      setBranchData(data)
      setSelectedBranch(branch)
      fetchUsers(branch.id).finally(() => {
        setLoadingBranches(false)
        setLoadingUsers(false)
      })
    })
  }

  useEffect(() => {
    if (branchData && selectedBranch) {
      setSelectedBranch(branchData.branch)
    }
  }, [branchData])

  const handleBack = async () => {
    if (history.length > 0) {
      const previousBranch = history[history.length - 1] // Get the last branch in history without mutating

      // Remove the last element from history to create a new array
      setHistory((prevHistory) => prevHistory.slice(0, -1))
      setSelectedBranch(previousBranch)
      setSelectedUser(null)
      setNewUser(null)

      setLoadingUsers(true) // For loading users
      setLoadingBranches(true) // For loading branches

      // Load previous branch data
      await loadBranches(previousBranch.id).then((data) => {
        setBranchData(data) // Update with previous branch data
      })

      // Fetch users for the previous branch
      await fetchUsers(previousBranch.id)

      setLoadingBranches(false) // Stop loading branches
      setLoadingUsers(false) // Stop loading users
    }
  }

  const handleUserClick = (user: User) => {
    setSelectedUser(user)
    setNewUser(null)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const { name, value } = e.target
      if (name === 'email') {
        if (!validateEmail(value)) {
          setEmailError('Invalid email format')
        } else {
          setEmailError(null)
        }
      }
      setSelectedUser({ ...selectedUser, [name]: value })
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const { name, value } = e.target
      setSelectedUser({ ...selectedUser, [name]: value })
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, active: e.target.checked })
    }
  }

  const handleCancel = () => {
    setSelectedUser(null)
    setNewUser(null)
  }

  const handleSave = async () => {
    if (selectedUser) {
      setLoadingModal(true)
      try {
        const updatedUser = await updateUser(selectedUser)

        if (updatedUser) {
          setCategorizedUsers((prev) => {
            const { shopOwners, shopManagers, regularUsers } = prev

            const updatedShopOwners = shopOwners.map((user) =>
              user.id === updatedUser.id ? updatedUser : user
            )
            const updatedShopManagers = shopManagers.map((user) =>
              user.id === updatedUser.id ? updatedUser : user
            )
            const updatedRegularUsers = regularUsers.map((user) =>
              user.id === updatedUser.id ? updatedUser : user
            )

            return {
              shopOwners: updatedShopOwners,
              shopManagers: updatedShopManagers,
              regularUsers: updatedRegularUsers,
            }
          })
          toast({
            title: 'User information saved.',
            description: 'User information saved.',
          })
          if (selectedBranch) {
            await fetchUsers(selectedBranch.id)
          }
        }
      } catch (error) {
        console.error('Failed to update user:', error)
        toast({
          title: 'Uh Oh ... the user update failed.',
          description: 'If the problem persists contact a representative.',
          variant: 'destructive',
        })
      } finally {
        setLoadingModal(false)
      }
    }
  }

  const handleNewUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (newUser) {
      const { name, value } = e.target
      if (name === 'email') {
        if (!validateEmail(value)) {
          setNewUserEmailError('Invalid email format')
        } else {
          setNewUserEmailError(null)
        }
      }
      setNewUser({ ...newUser, [name]: value })
    }
  }

  const handleNewUserSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (newUser) {
      const { name, value } = e.target
      setNewUser({ ...newUser, [name]: value })
    }
  }

  const handleNewUserCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (newUser) {
      setNewUser({ ...newUser, active: e.target.checked })
    }
  }

  const handleNewUserClick = () => {
    setSelectedUser(null)
    setNewUser({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      user_level: 'user',
      user_profile: '',
      active: false,
      uuid: '',
      username: '',
    })
  }

  const handleAddUser = async () => {
    if (
      newUser &&
      newUser.first_name &&
      newUser.last_name &&
      newUser.email &&
      newUser.user_level
    ) {
      setLoadingModal(true)

      try {
        const addedUser = await addUser(newUser)

        if (addedUser) {
          setCategorizedUsers((prevCategorizedUsers) => {
            const { shopOwners, shopManagers, regularUsers } =
              prevCategorizedUsers

            if (
              addedUser.user_level === 'power_user' &&
              addedUser.user_profile === 'shop_owner'
            ) {
              return {
                ...prevCategorizedUsers,
                shopOwners: [...shopOwners, addedUser],
              }
            } else if (
              addedUser.user_level === 'power_user' &&
              addedUser.user_profile === 'shop_manager'
            ) {
              return {
                ...prevCategorizedUsers,
                shopManagers: [...shopManagers, addedUser],
              }
            } else if (addedUser.user_level === 'user') {
              return {
                ...prevCategorizedUsers,
                regularUsers: [...regularUsers, addedUser],
              }
            }

            return prevCategorizedUsers
          })
          toast({
            title: 'New user added successfully.',
          })
          // reload page
          if (selectedBranch) {
            await fetchUsers(selectedBranch.id)
          }
          setNewUser(null)
        }
      } catch (error) {
        console.error('Error adding user:', error.message)

        toast({
          title: 'Uh Oh ... we could not add user.',
          description: 'If the problem persists contact a representative.',
          variant: 'destructive',
        })
      } finally {
        setLoadingModal(false)
      }
    }
  }

  return (
    <div className="flex flex-col lg:flex-row container gap-4 items-start">
      <section className="w-full lg:w-1/4">
        <div className="p-4 border rounded bg-white">
          <div className="flex justify-between items-start">
            <h2 className="font-medium text-primary mb-4">Branch Navigation</h2>
            {history.length !== 0 && (
              <button
                onClick={handleBack}
                disabled={history.length === 0}
                className="text-primary hover:underline cursor-pointer"
              >
                &larr; Back
              </button>
            )}
          </div>

          {loadingBranches ? (
            <p>Loading branches...</p>
          ) : branchData ? (
            <>
              <button
                onClick={() => handleBranchSelect(branchData.branch)}
                className={`p-2 text-left w-full ${
                  selectedBranch?.id === branchData.branch.id
                    ? 'bg-primary rounded text-white'
                    : ''
                }`}
              >
                {branchData.branch.name}
              </button>
              <div className="mt-2 pl-4 flex flex-col gap-1 overflow-auto max-h-96">
                {branchData.children.map((child) => (
                  <button
                    key={child.id}
                    onClick={() => handleBranchSelect(child)}
                    className={`p-2 text-left font-normal w-full rounded border hover:bg-gray-100 ${
                      selectedBranch?.id === child.id
                        ? 'bg-primary text-white'
                        : ''
                    }`}
                  >
                    {child.name}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p>No branch data available.</p> // Fallback if branchData is null
          )}
        </div>
      </section>

      <section className="w-full lg:w-1/4 flex flex-col gap-2">
        {loadingUsers ? (
          <div className="border rounded border-gray-300 p-4">
            <p>Loading users...</p>
          </div>
        ) : (
          <>
            <div className="border rounded border-gray-300 p-4">
              <h2 className="font-medium text-primary mb-4">
                Shop Owners in {selectedBranch?.name}
              </h2>
              {initialLoadComplete &&
              categorizedUsers.shopOwners.length === 0 ? (
                <p>No Shop Owners available.</p>
              ) : (
                <ul className="space-y-1">
                  {categorizedUsers.shopOwners.map((user) => (
                    <li key={user.id}>
                      <div className="w-full text-left font-normal p-2 rounded bg-gray-100">
                        {user.first_name} {user.last_name}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="border rounded border-gray-300 p-4">
              <h2 className="font-medium text-primary mb-4">
                Shop Managers in {selectedBranch?.name}
              </h2>
              {initialLoadComplete &&
              categorizedUsers.shopManagers.length === 0 ? (
                <p>No Shop Managers available.</p>
              ) : (
                <ul className="space-y-1">
                  {categorizedUsers.shopManagers.map((user) => (
                    <li key={user.id}>
                      <button
                        onClick={() => handleUserClick(user)}
                        className={`w-full text-left font-normal p-2 bg-white border border-gray-200 rounded hover:bg-gray-100 ${
                          selectedUser?.id === user.id
                            ? 'bg-gray-100 border-primary text-primary font-medium'
                            : ''
                        }`}
                      >
                        {user.first_name} {user.last_name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="p-4 border rounded bg-white">
              <h2 className="font-medium text-primary mb-4">
                Users in {selectedBranch?.name}
              </h2>
              {initialLoadComplete &&
              categorizedUsers.regularUsers.length === 0 ? (
                <p>No Users available.</p>
              ) : (
                <ul className="space-y-1 ">
                  {categorizedUsers.regularUsers.map((user) => (
                    <li key={user.id}>
                      <button
                        onClick={() => handleUserClick(user)}
                        className={`w-full text-left font-normal p-2 bg-white border border-gray-200 rounded hover:bg-gray-100 ${
                          selectedUser?.id === user.id
                            ? 'bg-gray-100 border-primary text-primary font-medium'
                            : ''
                        }`}
                      >
                        {user.first_name} {user.last_name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
        <MotionButton
          type="button"
          onClick={handleNewUserClick}
          className="mb-4 p-2 bg-primary text-white rounded"
        >
          New User
        </MotionButton>
      </section>

      <section className="w-full lg:w-1/4 basis-1/2">
        {selectedUser && (
          <EditUserForm
            selectedUser={selectedUser}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleCheckboxChange={handleCheckboxChange}
            handleSave={handleSave}
            handleCancel={handleCancel}
            emailError={emailError}
            loadingModal={loadingModal}
          />
        )}
        {newUser && (
          <NewUserForm
            newUser={newUser}
            handleInputChange={handleNewUserInputChange}
            handleSelectChange={handleNewUserSelectChange}
            handleCheckboxChange={handleNewUserCheckboxChange}
            handleAddUser={handleAddUser}
            handleCancel={handleCancel}
            emailError={newUserEmailError}
            loadingModal={loadingModal}
            selectedBranchName={selectedBranch?.name}
          />
        )}
        {!selectedUser && !newUser && <p>Select a user to edit.</p>}
      </section>
    </div>
  )
}

export default ManageUsers
