import React from 'react'
import { User } from '@/types/ManageUsers'
import { MotionButton } from '../../components/ui/motion-button'

interface NewUserFormProps {
  newUser: User | null
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleAddUser: () => void
  handleCancel: () => void
  emailError: string | null
  loadingModal: boolean
  selectedBranchName: string | undefined
}

const NewUserForm: React.FC<NewUserFormProps> = ({
  newUser,
  handleInputChange,
  handleSelectChange,
  handleCheckboxChange,
  handleAddUser,
  handleCancel,
  emailError,
  loadingModal,
  selectedBranchName,
}) => {
  if (!newUser) return null

  const isFormIncomplete =
    !newUser.first_name ||
    !newUser.last_name ||
    !newUser.email ||
    !newUser.user_level

  const isDisabled = loadingModal || emailError !== null || isFormIncomplete

  return (
    <div className="p-4 border rounded bg-white">
      <h2 className="font-medium text-primary mb-4">
        Add New User to {selectedBranchName}
      </h2>
      <form className="space-y-4">
        <div>
          <label className="block mb-1">First Name</label>
          <input
            type="text"
            name="first_name"
            value={newUser.first_name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={newUser.last_name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Email</label>
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p>
          )}
        </div>
        <div>
          <label className="block mb-1">User Level</label>
          <select
            name="user_level"
            value={newUser.user_level}
            onChange={handleSelectChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">--Please choose an option--</option>
            <option value="user">User</option>
            <option value="power_user">Shop Manager</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Active</label>
          <input
            type="checkbox"
            checked={newUser.active}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          Active
        </div>
        <div className="flex gap-2">
          <MotionButton
            type="button"
            onClick={handleAddUser}
            className={`text-white p-2 rounded ${
              isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'
            }`}
            disabled={isDisabled}
          >
            {loadingModal ? 'Processing...' : 'Add User'}
          </MotionButton>

          <MotionButton
            type="button"
            variant="secondary"
            onClick={handleCancel}
            className="text-white p-2 rounded"
          >
            Cancel
          </MotionButton>
        </div>
      </form>
    </div>
  )
}

export default NewUserForm
