import { Fragment, useContext, useEffect, useState } from 'react'
import SearchResults from '../components/Search/searchResults'
import NoSearchResultsCard from '../components/Search/noSearchResultsCard'
import { useTranslation } from 'react-i18next'
import SearchFilterContext, {
  INITIAL_SEARCH_FILTERS,
  SearchFilterProps,
} from '../hooks/search'
import { Tab, TabPanels, TabPanel, TabGroup, TabList } from '@headlessui/react'
import SelfPacedCourses from '../components/SelfPacedCourses'
import VirtualInstructorCourses from '../components/VirtualInstructorCourses'
import InstructorLedCourses from '../components/InstructorLedCourses'
import SearchForm from '../components/Search/SearchForm'
import { useSearchParams } from 'react-router-dom'

const Home: React.FC = () => {
  const { t } = useTranslation('pages')

  const [searchParams, setSearchParams] = useSearchParams()
  const { search, sessionData, loading } = useContext(SearchFilterContext)
  const [searchResults, setSearchResults] = useState<SearchFilterProps[]>([])
  const [activeCourseType, setActiveCourseType] = useState<
    'vilt' | 'ilt' | 'olt'
  >('vilt')

  const tabIndexMapping: Record<'vilt' | 'ilt' | 'olt', number> = {
    vilt: 0,
    ilt: 1,
    olt: 2,
  }

  const onSearch = (values: SearchFilterProps) => {
    const results = search({ ...values, courseType: activeCourseType })
    setSearchResults(results)
    setSearchParams(convertFiltersToQueryParams(values))
  }

  const onReset = () => {
    setSearchParams({})
    const resetFilters = {
      ...INITIAL_SEARCH_FILTERS,
      courseType: activeCourseType,
    }
    setSearchResults(search(resetFilters))
  }

  const convertParamsToFilters = (
    params: URLSearchParams
  ): SearchFilterProps => {
    return {
      ...INITIAL_SEARCH_FILTERS,
      ...Object.fromEntries(params.entries()),
    } as SearchFilterProps
  }

  const convertFiltersToQueryParams = (
    filters: SearchFilterProps
  ): Record<string, string> => {
    return Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value?.trim())
    )
  }

  useEffect(() => {
    if (!loading && sessionData.length > 0) {
      setSearchResults(
        search({
          ...INITIAL_SEARCH_FILTERS,
          courseType: activeCourseType,
        })
      )
    }
  }, [loading, sessionData, activeCourseType])

  useEffect(() => {
    const courseType = searchParams.get('courseType') as 'ilt' | 'vilt' | 'olt'
    if (courseType && courseType !== activeCourseType) {
      setActiveCourseType(courseType)
    }
  }, [searchParams, activeCourseType])

  const instructorList = sessionData.reduce<string[]>((unique, item) => {
    if (item.instructors) {
      item.instructors.forEach(({ firstname, lastname }) => {
        const fullName = `${firstname} ${lastname}`
        if (!unique.includes(fullName)) unique.push(fullName)
      })
    }
    return unique
  }, [])

  const filteredSessionData = sessionData.filter(
    (course) => course.courseType === activeCourseType
  )

  return (
    <main className="grow display-linebreak main-container-height">
      <div className="py-8 px-4 sm:px-12 container mx-auto shadow-md">
        <section className="container mx-auto">
          <h2 className="text-grays-dark text-2xl font-bold mb-4">
            {t('index.title')}
          </h2>
          <p className="text-grays-dark font-normal mb-8">
            {t('index.description')}
          </p>
        </section>
        <TabGroup
          selectedIndex={tabIndexMapping[activeCourseType]}
          onChange={(index) => {
            const newCourseType = (
              Object.keys(tabIndexMapping) as ('ilt' | 'vilt' | 'olt')[]
            )[index]
            setActiveCourseType(newCourseType)
            setSearchParams({ courseType: newCourseType })
          }}
        >
          <div className="p-4 bg-primary rounded-t shadow gap-4 w-full">
            <h2 className="mb-2 font-semibold text-white">
              Search by Course Type:
            </h2>

            <TabList className="flex gap-4 w-full">
              {[
                { type: 'vilt', fallback: 'Virtual Classroom Courses' },
                { type: 'ilt', fallback: 'In-person Instructor Led Courses' },
                { type: 'olt', fallback: 'Online Self Paced Courses' },
              ].map(({ type, fallback }) => (
                <Tab key={type} as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setSearchParams({ courseType: type })}
                      className={
                        selected
                          ? 'active-tab bg-secondary cursor-pointer border-secondary basis-1/3'
                          : 'inactive-tab bg-white cursor-pointer basis-1/3'
                      }
                    >
                      {t(`index.tabs.${type}`, fallback)}
                    </button>
                  )}
                </Tab>
              ))}
            </TabList>
          </div>

          <TabPanels>
            <TabPanel>
              <VirtualInstructorCourses
                searchParams={searchParams}
                instructorList={instructorList}
                searchResults={searchResults}
                onSearch={onSearch}
                onReset={onReset}
                activeCourseType={activeCourseType}
              />
            </TabPanel>
            <TabPanel>
              <InstructorLedCourses
                searchParams={searchParams}
                instructorList={instructorList}
                searchResults={searchResults}
                onSearch={onSearch}
                onReset={onReset}
                activeCourseType={activeCourseType}
              />
            </TabPanel>

            <TabPanel>
              <SelfPacedCourses />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </main>
  )
}

export default Home
