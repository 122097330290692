import React from 'react'
import { Invoice as InvoiceType } from '@/hooks/useInvoices'
import { priceInDollars } from '@/lib/priceConversions'
import { displayDate } from '@/lib/dateConversions'

interface Props {
  invoice: InvoiceType
}

function Invoice({ invoice }: Props) {
  const address = invoice.billing_address
  return (
    <section className="border-b border-gray-300">
      <div className="px-6 flex flex-col mx-auto justify-center sm:px-8 lg:px-12 max-w-6xl pb-12">
        <div className="flex justify-between items-start mt-12">
          <img
            src="/images/CTI_WTI_Logo.png"
            alt="Carquest Technical Institute"
            className="w-36"
          />
          <div className="text-right text-sm space-y-1 text-gray-500">
            <p>
              <strong className="text-black">Invoice Number:</strong>{' '}
              {invoice.id}
            </p>
            <p>
              {address.first_name} {address.last_name}
            </p>
          </div>
        </div>

        <div className="sm:flex sm:items-center mt-6">
          <div className="sm:flex-auto">
            <h1 className="text-xl">Invoice</h1>
            <p className="max-w-xs mt-2 text-gray-500">
              Invoiced on {displayDate(invoice.date)}
            </p>
          </div>
        </div>

        <div className="-mx-4 mt-6 flow-root sm:mx-0">
          <table className="min-w-full">
            <thead className="border-b border-gray-300 text-gray-900">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left sm:pl-0">
                  Product
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right sm:table-cell"
                >
                  Units
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right sm:table-cell"
                >
                  Unit Price
                </th>
                <th scope="col" className="py-3.5 pl-3 pr-4 text-right sm:pr-0">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.line_items.map((item) => (
                <tr className="border-b border-gray-200" key={item.id}>
                  <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="font-medium text-base text-gray-900">
                      {item.entity_id}
                    </div>
                    <div className="mt-1 truncate text-gray-500">
                      {item.description}
                    </div>
                  </td>
                  <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                    {item.quantity}
                  </td>
                  <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                    {item.unit_amount}
                  </td>
                  <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                    {priceInDollars(item.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="text-right">
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 sm:table-cell sm:pl-0"
                >
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-blue-600 sm:pr-0">
                  {priceInDollars(invoice.total)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>
  )
}

export default Invoice
