const HowToModifyUsers = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click the <strong>Menu</strong> icon in the top right corner of the page
        next to the shopping cart then the <strong>Admin</strong> button in the
        dropdown
        <div className="my-4 border rounded">
          <img src="/images/faqs/Admin_Panel.png" alt="Admin Panel" />
        </div>
      </li>
      <li>
        Click on <strong>Manage Users</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Manage_Users.png" alt="Courses to start" />
        </div>
      </li>
      <li>
        Click on <strong>New User</strong> and fill out all required fields
      </li>
      <li>
        Click on <strong>Add User</strong> to complete the process
      </li>
      <li>
        To modify an existing user, select a user from the list and make changes
        to their profile, then click <strong>Save</strong>
      </li>
      <li>
        To deactivate an existing user uncheck the <strong>Active</strong> box,
        then click <strong>Save</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Deactive_User.png" alt="Deactivate User" />
        </div>
      </li>
    </ol>
  </div>
)

export default HowToModifyUsers
