import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import catalogPdf from '../../public/pdfs/2024_course_catalog.pdf'

function Catalog() {
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scaleSize, setScaleSize] = useState(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handleResize = () => {
    if (window.innerWidth > 1280) {
      setScaleSize(1)
    } else {
      setScaleSize(0.9)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
  })

  return (
    <div className="grow py-8 px-4 md:mx-auto main-container-height">
      <div className="w-full flex justify-center gap-4 mb-4">
        <motion.button
          className="font-normal text-primary"
          onClick={previousPage}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          &#60; previous
        </motion.button>
        <motion.button
          className="font-normal text-primary"
          onClick={() => setPageNumber(1)}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          Home
        </motion.button>
        <motion.button
          className="font-normal text-primary"
          onClick={nextPage}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          next &#62;
        </motion.button>
      </div>

      <div className="container overflow-x-scroll drop-shadow-lg p-0">
        <Document
          file={catalogPdf}
          onLoadSuccess={onDocumentLoadSuccess}
          onItemClick={({ pageNumber }) => setPageNumber(Number(pageNumber))}
        >
          <Page
            pageNumber={pageNumber}
            scale={scaleSize}
            renderTextLayer={false}
            renderAnnotationLayer={true}
          />
        </Document>
      </div>
      <div className="w-full flex justify-center mt-4">
        <p className="text-grays-dark text-sm">
          Page {pageNumber} of {numPages}
        </p>
      </div>
    </div>
  )
}

export default Catalog
