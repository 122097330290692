import { Button } from '@/components/ui/button'

import OrderSummary from '@/components/Cart/OrderSummary'
import OrderConfirmed, {
  OrderConfirmedProps,
} from '@/components/OrderConfirmed'
import { useEffect, useRef, useState } from 'react'
import useCartStore from '@/store/cart'
import { useAuth } from '@/hooks/useAuth'
import { API_URL } from '@/lib/constants'
import { axiosInstance } from '@/lib/services/axiosinstance'
import SelectPaymentMethod from '@/components/PaymentMethods/SelectPaymentMethod'
import { useForm } from 'react-hook-form'
import {
  BillingAddress,
  BillingAddressSchema,
  defaultBillingAddress,
} from '@/components/PaymentMethods/CreditCardSchema'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { PaymentType } from '@/types/CartTypes'
import { getTokenForCreditCard } from '@/lib/getTokenForCreditCard'
import CreditCardComponent from '@/components/ChargeBee/CreditCardComponent'
import { StatesDropdown } from '@/components/StatesDropdown/StatesDropdown'
import CardNumber from '@/components/ChargeBee/CardNumber'
import CardExpiry from '@/components/ChargeBee/CardExpiry'
import CardCVV from '@/components/ChargeBee/CardCVV'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import Product from '@/types/Product'
import { hasTechnetProduct } from '@/lib/technet'
import { User } from '@/types/User'
import QuoteReason from '@/components/Checkout/QuoteReason'
import { Link } from 'react-router-dom'
import getTotal from '@/lib/cart/getTotal'
import isEmpty from '@/lib/support/isEmpty'
import convertServerErrors from '@/lib/support/convertServerErrors'
import { GlobalErrors } from '@/components/GlobalErrors/GlobalErrors'

type FormParams = BillingAddress & {
  order_form_id: string
}

export default function Checkout() {
  const { cartItems, clearCart } = useCartStore()
  const { getAuthHeaders, currentUser } = useAuth()

  const [showCreditCardForm, setShowCreditCardForm] = useState(false)
  const [orderConfirmation, setOrderConfirmation] =
    useState<OrderConfirmedProps | null>(null)
  const [paymentSourceId, setPaymentSourceId] = useState<string | null>(null)
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const cardRef = useRef(null)

  const order_form_id = Math.random().toString(36).substr(2, 5)

  const card = {}
  const form = useForm<BillingAddress & { order_form_id: string }>({
    resolver: (...args) => {
      if (paymentType === 'new_card') {
        return zodResolver(
          BillingAddressSchema.and(
            z.object({
              order_form_id: z.string(),
            })
          )
        )(...args)
      }
      return zodResolver(
        z.object({
          order_form_id: z.string(),
        })
      )(...args)
    },
    defaultValues: {
      order_form_id: order_form_id,
      ...defaultBillingAddress(card),
    },
  })

  const errors = form.formState.errors

  const toggleCreditCardForm = () => {
    form.clearErrors()
    setPaymentType('new_card')
    setPaymentSourceId(null)
    setShowCreditCardForm((prev) => !prev)
  }

  useEffect(() => {
    if (canOnlyCreateQuote(currentUser, cartItems)) {
      setPaymentType('quote')
    }
  }, [])

  /* Checkout */
  async function onSubmit(values: FormParams) {
    setSubmitting(true)

    if (isEmpty(paymentType)) {
      form.setError('root', {
        type: 'global',
        message: 'Please add a credit card or select a valid payment type',
      })
      setSubmitting(false)
      return
    }

    const total = getTotal(cartItems)
    const products = cartItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      price: item.price,
    }))

    const order = {
      order_form_id: values.order_form_id,
      products: products,
      total_price: total.totalPrice,
      total_quantity: total.totalQuantity,
      email: currentUser.email,
      payment_type: paymentType,
    }

    switch (paymentType) {
      case 'new_card': {
        const tokenData = await getTokenForCreditCard(
          cardRef,
          currentUser,
          values
        )
        order['token_id'] = tokenData.token
        break
      }
      case 'saved_card':
        order['paymentSourceId'] = paymentSourceId
        break
      case 'charge_account':
        break
      case 'quote':
        break
      default:
        throw new Error('Invalid paymentType')
    }
    axiosInstance
      .post(
        `${API_URL}/api/v1/orders`,
        { order: order },
        {
          headers: getAuthHeaders(),
        }
      )
      .then((res) => {
        clearCart()
        setOrderConfirmation(res.data)
      })
      .catch((err) => {
        const response = err.response
        if (response) {
          const serverErrors = response.data.errors
          convertServerErrors<BillingAddress>(form.setError, serverErrors, [
            'total_price',
            'total_quantity',
          ])
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (orderConfirmation) {
    return (
      <OrderConfirmed
        message={orderConfirmation.message}
        invoices={orderConfirmation.invoices}
      />
    )
  }

  const onSelectPaymentMethod = (
    paymentType: PaymentType,
    paymentSourceId?: string
  ) => {
    if (paymentType !== 'new_card') {
      setShowCreditCardForm(false)
    }
    setPaymentSourceId(paymentSourceId)
    setPaymentType(paymentType)
  }

  const canOnlyCreateQuote = (user: User, products: Product[]) => {
    if (hasTechnetProduct(products)) {
      return !user.is_technet_member
    }
    return false
  }

  if (currentUser.is_individual) {
    // toast({
    //   title: "Problem with account",
    //   description: "Sorry there is a problem with your account. Please contact us.",
    // })
    // return <Navigate to="/contact-us" replace={true} />
    return null
  }

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Checkout</h2>
        {errors.root && (
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-red-500 font-semibold">
              Please correct the following errors
            </h2>
            <GlobalErrors errors={errors} />
          </div>
        )}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
          >
            <FormField
              control={form.control}
              name="order_form_id"
              render={({ field }) => <input type="hidden" {...field} />}
            />

            <div className="container">
              {canOnlyCreateQuote(currentUser, cartItems) ? (
                <QuoteReason user={currentUser} products={cartItems} />
              ) : (
                <div>
                  <SelectPaymentMethod
                    onSelectPaymentMethod={onSelectPaymentMethod}
                    currentUser={currentUser}
                    paymentType={paymentType}
                  />

                  <div className="mt-4">
                    <h2 className="text-lg font-medium text-gray-900 mb-2">
                      <Button type="button" onClick={toggleCreditCardForm}>
                        Add a credit card
                      </Button>
                    </h2>

                    {showCreditCardForm && (
                      <CreditCardComponent ref={cardRef} paymentMethod={null}>
                        <div className="grid grid-cols-4 gap-x-4 gap-y-2">
                          <div className="col-span-4">
                            <CardNumber
                              id="number"
                              label="Card Number"
                              errorText="Please enter a valid card number"
                              invalid={false}
                              setInvalid={(val: boolean) => {
                                console.log('setting cardnumber valid', val)
                              }}
                            />
                          </div>

                          <div className="col-span-2">
                            <FormField
                              control={form.control}
                              name="firstName"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>First Name</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                          <div className="col-span-2">
                            <FormField
                              control={form.control}
                              name="lastName"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Last Name</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="col-span-2">
                            <CardExpiry
                              id="expiry"
                              label="Card Expiry"
                              errorText="Please enter a valid card number"
                              invalid={false}
                              setInvalid={(val: boolean) => {
                                console.log('setting cardnumber valid', val)
                              }}
                            />
                          </div>
                          <div className="col-span-2">
                            <CardCVV
                              id="cvv"
                              label="Card CVV"
                              errorText="Please enter a valid card number"
                              invalid={false}
                              setInvalid={(val: boolean) => {
                                console.log('setting cardnumber valid', val)
                              }}
                            />
                          </div>
                          <div className="col-span-4">
                            <FormField
                              control={form.control}
                              name="addressLine1"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Address</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                          <div className="col-span-4">
                            <FormField
                              control={form.control}
                              name="addressLine2"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Extended Address</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                          <div className="col-span-2">
                            <FormField
                              control={form.control}
                              name="city"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>City</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="col-span-1">
                            <FormField
                              control={form.control}
                              name="stateCode"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>State</FormLabel>
                                  <StatesDropdown
                                    onChange={field.onChange}
                                    value={field.value}
                                    placeholder={''}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="col-span-1">
                            <FormField
                              control={form.control}
                              name="zip"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Zip Code</FormLabel>
                                  <FormControl>
                                    <Input placeholder="" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </CreditCardComponent>
                    )}
                  </div>
                </div>
              )}
            </div>

            <OrderSummary>
              <>
                <Button
                  className="bg-primary hover:bg-blue-800 w-full rounded-md border border-transparent px-4 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  type="submit"
                  disabled={submitting}
                >
                  Confirm order
                </Button>
                <div className="mt-2 text-center text-gray-600">
                  Problems with this order?{' '}
                  <Link
                    to="/contact-us"
                    className="leading-6 text-primary hover:text-primary-500"
                  >
                    Contact us
                  </Link>
                </div>
              </>
            </OrderSummary>
          </form>
        </Form>
      </div>
    </div>
  )
}
