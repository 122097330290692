import InvoiceComponent from '@/components/Invoices/Invoice'
import { Invoice, useInvoices } from '@/hooks/useInvoices'
import { divide } from 'lodash'
import React, { useState, useEffect } from 'react'

function Invoices() {
  const { listInvoices } = useInvoices()

  const [invoices, setInvoices] = useState<Invoice[] | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true)
      try {
        const fetched = await listInvoices()
        setInvoices(fetched)
      } catch (error) {
        console.error('Error fetching invoices:', error)
      }
      setLoading(false)
    }

    fetchInvoices()
  }, [])

  if (loading) {
    return <p className="w-full">Loading Invoices...</p>
  }

  if (!invoices) {
    return <p className="w-full">No invoices available.</p>
  }

  return (
    <div className="">
      <h1 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
        Your Invoices
      </h1>
      <div>
        {invoices.map((invoice) => (
          <InvoiceComponent key={invoice.id} invoice={invoice} />
        ))}
      </div>
    </div>
  )
}

export default Invoices
