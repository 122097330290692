import { useState, useEffect } from 'react'
import Dropdown from './Dropdown'
import Hamburger from './Hamburger'
import { useAuth } from '../../hooks/useAuth'

const Header = () => {
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const { currentUser, signOut, loading } = useAuth()

  const updateMedia = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateMedia()
    }

    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  if (loading) return

  return (
    <div className="w-full shadow-sm py-3 px-4 mx-auto">
      <div className="flex justify-end items-end">
        {windowWidth < 1024 ? (
          <Hamburger currentUser={currentUser} signOut={signOut} />
        ) : (
          <Dropdown currentUser={currentUser} signOut={signOut} />
        )}
      </div>
    </div>
  )
}

export default Header
