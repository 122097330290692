import React, { useEffect, useState } from 'react'
import { useSubscriptions, ISubscription } from '@/hooks/useSubscriptions'
import { MotionButton } from '@/components/ui/motion-button'
import { Badge } from '@/components/ui/badge'
import { priceForPlan } from '@/lib/priceConversions'

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)
  const { listSubscriptions, updateSubscription } = useSubscriptions()

  const getSubscriptions = async () => {
    setLoading(true)
    const subs = await listSubscriptions()
    setSubscriptions(subs)
    setLoading(false)
  }

  const cancelSubscription = async (subcription: ISubscription) => {
    const updatedSubscription = await updateSubscription({
      id: subcription.id,
      active: false,
    })
    if (updatedSubscription.status == 'non_renewing') {
      setSubscriptions((prev) =>
        prev.map((sub) =>
          sub.id === updatedSubscription.id ? updatedSubscription : sub
        )
      )
      // toast
    }
  }

  useEffect(() => {
    getSubscriptions()
  }, [])

  return (
    <div className="flex flex-col justify-center items-center space-y-4 w-full m-auto">
      {loading ? (
        <p className="w-full">Loading subscriptions...</p>
      ) : (
        <div className="flex flex-col justify-center items-center space-y-4 w-full m-auto">
          <h1 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            Your Subscriptions
          </h1>
          <section aria-labelledby="order-heading" className="mt-10">
            <h3 className="sr-only">Items</h3>
            {subscriptions.map((subscription) => (
              <div
                key={subscription.id}
                className="flex grow flex-col items-start gap-x-2 rounded-md border
                border-solid border-neutral-border bg-default-background px-4 py-4 shadow-sm mb-2"
              >
                <div className="flex w-full flex-col items-start mb-2">
                  <div className="flex w-full flex-col">
                    <div className="flex justify-between w-full">
                      <span className="text-base font-semibold">
                        {subscription.name} - {subscription.id}
                      </span>
                      <span>
                        {subscription.status === 'active' ? (
                          <Badge className="bg-green-600">Active</Badge>
                        ) : (
                          <Badge>Inactive</Badge>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col items-start gap-2">
                  {subscription.plans.map((plan) => (
                    <div
                      className="flex-col w-full items-start gap-2"
                      key={plan.id}
                    >
                      <div className="grow shrink-0">
                        {plan.id} - {plan.name}
                      </div>

                      <div className="">{priceForPlan(plan)}</div>

                      <div className="flex justify-end w-full">
                        {plan.status === 'active' &&
                          !plan.id.startsWith('TECHNET') && (
                            <MotionButton
                              className="bg-red-600"
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                event.preventDefault()
                                cancelSubscription(subscription)
                              }}
                              variant="secondary"
                              size="sm"
                            >
                              Cancel
                            </MotionButton>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </section>
        </div>
      )}
    </div>
  )
}

export default Subscriptions
