const HowToEnrollUsers = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click the <strong>Menu</strong> icon in the top right corner of the page
        next to the shopping cart then the <strong>Admin</strong> button in the
        dropdown
        <div className="my-4 border rounded">
          <img src="/images/faqs/Admin_Panel.png" alt="Admin Panel" />
        </div>
      </li>
      <li>
        Click on <strong>Enroll Users</strong>
      </li>
      <li>
        Select a Subscription from the drop-down menu
        <div className="my-4 border rounded">
          <img
            src="/images/faqs/SelectSubscription.png"
            alt="Select Subscription"
          />
        </div>
      </li>
      <li>
        For Online Courses, check the box(es) for the courses and users to be
        assigned (you can make multiple selections at once)
      </li>
      <li>
        Click <strong>Enroll Students</strong>. These courses will now show on
        the selected users' homepage under <strong>Courses to Start</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Enroll_Students.png" alt="Enroll Students" />
        </div>
      </li>
      <li>
        For Virtual Classroom and In-Person Classes, check the box(es) for the
        courses and users to be assigned (you can make multiple selections at
        once)
        <div className="my-4 border rounded">
          <img
            src="/images/faqs/Enroll_Students_VC.png"
            alt="Enroll Students Virtual Classroom"
          />
        </div>
      </li>
      <li>
        Each user who has been assigned a Virtual Classroom or In-Person class
        will now need to log in and select the event date(s) they will attend.
        The user will not be added to the class roster until this step has been
        completed.
        <ol className="ps-6 py-4 list-decimal list-inside text-grays-dark space-y-4">
          <li>
            Have the user visit{' '}
            <a className="underline text-blue-800" href="http://ctionline.com/">
              CTI Online
            </a>
          </li>
          <li>
            Click on <strong>Access Your Training</strong>
          </li>
          <li>
            Scroll down the homepage to <strong>Courses to Start</strong> and
            click on the Virtual or In-Person course
            <div className="my-4 border rounded">
              <img
                src="/images/faqs/Enroll_Virtual_Class_Course.png"
                alt="Enroll in virtual course"
              />
            </div>
          </li>
          <li>
            Click on <strong>Choose Session</strong> and select the correct
            upcoming date & time, then click <strong>Confirm</strong>
          </li>
          <li>
            The class will now show up on the user's Training Calendar on their
            homepage
          </li>
          <li>
            For Virtual Classroom, the user will join their class sessions at
            the start date/time by clicking on the session in the calendar, then
            click Join. The link to Join will be available 15 minutes prior to
            the class start time
          </li>
          <li>
            If the user has never logged into Zoom before with the email address
            used to enroll in the class they will need to create a free Zoom
            account.{' '}
            <a
              className="underline text-blue-800"
              href="https://zoom.us/signup#/signup"
            >
              Sign Up Free | Zoom
            </a>
            <div className="my-4 border rounded">
              <img src="/images/faqs/Choose_session.png" alt="Choose Session" />
            </div>
            <div className="my-4 border rounded">
              <img
                src="/images/faqs/Choose_session_confirm.png"
                alt="Choose Session"
              />
            </div>
            <p>
              <strong>*Note:</strong> to unenroll a user from a course, please
              send an email with the user's name, email and course details to
              <a
                className="text-blue-800"
                href="mailto:ctisupport@carquest.com"
              >
                ctisupport@carquest.com
              </a>
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
)

export default HowToEnrollUsers
