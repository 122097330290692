import React from 'react'
import Element, { ElementProps } from './Element'
import { CreditCardComponentContext } from './CreditCardComponent'

export interface CardNumberProps extends ElementProps {
  onBlur?: React.MouseEventHandler
  onChange?: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
  onReady?: React.EventHandler<React.SyntheticEvent>
}

const CardCVV = (props: CardNumberProps) => {
  // const { onBlur, onChange, onFocus, onReady, ...rest } = props
  // const listeners = { onBlur, onChange, onFocus, onReady }

  return (
    <CreditCardComponentContext.Consumer>
      {(ctx) => (
        <Element type="cvv" cardComponent={ctx.cardComponent} {...props} />
      )}
    </CreditCardComponentContext.Consumer>
  )
}

CardCVV.displayName = 'CardExpiry'

export default CardCVV
