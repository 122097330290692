import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { motion } from 'framer-motion'
import Subscription from '@/types/Subscription'

interface PaymentChangeWarningProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  subscriptions: Subscription[]
  isLoading: boolean
  error: string | null
}

const PaymentChangeWarning: React.FC<PaymentChangeWarningProps> = ({
  isOpen,
  onClose,
  onConfirm,
  subscriptions,
  error,
}) => {
  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-lg border-4 border-secondary shadow-md">
        <h2 className="text-xl font-semibold text-secondary">Warning!</h2>
        <p className="mt-4 text-gray-900">
          Changing your payment method will apply this change to all your
          current subscriptions:
        </p>
        <ul className="mt-4 space-y-2 text-gray-900">
          {subscriptions.length > 0 ? (
            subscriptions
              .flatMap((subscription) => subscription.plans)
              .filter((plan) => !plan.id.includes('ONETIME'))
              .map((plan) => (
                <li key={plan.id} className="list-disc list-inside">
                  {plan.name}
                </li>
              ))
          ) : (
            <li className="font-semibold">No subscriptions found.</li>
          )}
        </ul>
        <div className="mt-6 flex justify-center space-x-2">
          <motion.button
            className="blue-btn bg-gray-300 text-sm font-normal text-graysdark block rounded-sm px-4 py-1.5 hover:shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            onClick={onClose}
          >
            Cancel
          </motion.button>
          <motion.button
            className="blue-btn bg-secondary text-sm font-normal text-white block rounded-sm px-4 py-1.5 hover:shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            onClick={onConfirm}
          >
            Confirm
          </motion.button>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default PaymentChangeWarning
