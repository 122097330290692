import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { SearchFilterProvider } from '../hooks/search'

import i18n from './i18n'

import Routes from './routes'

console.log('Initialized i18n', i18n)

function App() {
  return (
    <BrowserRouter>
      <SearchFilterProvider>
        <Routes />
      </SearchFilterProvider>
    </BrowserRouter>
  )
}

export default App
