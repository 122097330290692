import { Hash } from '@/types/Hash'
import { Path, UseFormSetError } from 'react-hook-form'

// const capitalize = (s) =>
//   (s && String(s[0]).toUpperCase() + String(s).slice(1)) || ''

// const convertKeyToFieldName = (key: string) => {
//   return capitalize(key.replaceAll(/_/g, ' '))
// }

export default function convertServerErrors<T>(
  setError: UseFormSetError<T>,
  errors: Hash<string>,
  rootKeys: string[] = []
) {
  Object.keys(errors).forEach(function (key) {
    const value = errors[key]
    if (key === 'base') {
      setError('root', {
        message: value,
      })
    } else {
      if (rootKeys.includes(key)) {
        setError(`root.${key}`, {
          message: value,
        })
      } else {
        setError(key as Path<T>, {
          message: value,
        })
      }
    }
  })
}
