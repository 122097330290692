import React, { FC } from 'react'
import { ChargeAccountDetails } from '@/hooks/usePaymentMethods'

interface Props {
  chargeAccountDetails: ChargeAccountDetails | null
}

const ShowChargeAccountDetails: FC<Props> = ({ chargeAccountDetails }) => {
  if (!chargeAccountDetails) return null

  return (
    <div className="w-1/2">
      <div className="flex items-center justify-between bg-grays-light p-6 rounded mb-1 border border-grays-lightmedium">
        <div className="flex items-start gap-x-3 text-sm/6">
          <p className="font-semibold text-gray-900">
            AAP Account {chargeAccountDetails.aap_account_number} (
            {chargeAccountDetails.credit_account_number})
          </p>
          <p className="whitespace-nowrap">
            (Net terms: {chargeAccountDetails.net_term_days} days)
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShowChargeAccountDetails
