import React, { SVGProps } from 'react'
import Visa from './icons/Visa'
import Generic from './icons/Generic'
import Amex from './icons/Amex'
import MasterCard from './icons/MasterCard'

export type SVGComponentProps = {
  width?: number
  height?: number
}

type PaymentIconProps = {
  brand: string
} & SVGProps<SVGSVGElement>

const icons = {
  amex: Amex,
  mastercard: MasterCard,
  visa: Visa,
}

const aspectRatio = 780 / 500 // Width / Height of the svgs.
const defaultWidth = 30

/**
 * Icons sourced from: https://github.com/aaronfagan/svg-credit-card-payment-icons/
 * @param props
 * @returns
 */
export function PaymentIcon(props: PaymentIconProps) {
  const Component: (props: SVGProps<SVGSVGElement>) => JSX.Element =
    icons[props.brand] ?? Generic

  const width =
    props.width === undefined && props.height === undefined
      ? defaultWidth
      : (props.width as number)

  return (
    <Component
      {...props}
      fill="#000"
      width={width}
      height={width / aspectRatio}
      viewBox="0 0 780 500"
    />
  )
}
