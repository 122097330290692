function RDCenter() {
  return (
    <div className="grow py-8 px-4 sm:px-12 container mx-auto shadow-md flex gap-8 flex-col main-container-height">
      <div className="w-full">
        <h2 className="text-grays-dark text-2xl font-bold mb-4">
          Research and Development Center
        </h2>
        <p className="text-grays-dark font-normal mb-8">
          CTI industry experts continually develop state-of-the-art curriculum
          to meet our customers needs. The Research and Development Center (RDC)
          allows the CTI team to join forces in one location to optimize the
          research and development process, including on-vehicle testing and
          validation, shop equipment evaluations, and case study creation. This
          9,000-square-foot facility boasts a 50-seat primary classroom for
          instructor-led programs, a 20-seat hands-on classroom and a six-bay
          shop equipped with a comprehensive collection of state-of-the-art
          tools and calibration equipment. The RDC Video Studio allows CTI to
          develop online content, support classroom training, and stream live
          events.
        </p>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4">
        <div className="mb-4">
          <img
            src="/images/diagnostic_tool.png"
            className="h-full object-contain"
            alt=""
          />
        </div>
        <div className="mb-4">
          <img
            src="/images/diagnostic_car_hood.png"
            className="h-full object-contain"
            alt=""
          />
        </div>
        <div className="mb-4">
          <img
            src="/images/shop_training.jpg"
            className="h-full object-contain"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default RDCenter
