import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import CartProduct from '../types/CartTypes'
import Product from '../types/Product'
import { Result } from '@/types/Result'

interface CartState {
  cartItems: CartProduct[]
  hasItems: () => boolean
  addItemToCart: (item: Product) => Result<string>
  increaseQuantity: (productId: string) => void
  decreaseQuantity: (productId: string) => void
  setQuantity: (productId: string, quantity: number) => void
  removeItemFromCart: (productId: string) => void
  clearCart: () => void
}

const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      cartItems: [],

      hasItems: () => {
        return get().cartItems.length > 0
      },

      addItemToCart: (item, quantity = 1) => {
        const existingItem = get().cartItems.find(
          (cartItem) => cartItem.id === item.id
        )

        if (existingItem) {
          // You can't add more than one item to the cart
          // if (typeof existingItem.quantity === 'number') {
          //   existingItem.quantity++
          // }

          // set({ cartItems: [...get().cartItems] })
          return {
            success: false,
            errors: { base: `You already have ${item.id} in your cart` },
          }
        } else {
          set({
            cartItems: [...get().cartItems, { ...item, quantity: quantity }],
          })
          return {
            success: true,
            value: ``,
          }
        }
      },

      setQuantity: (productId, quantity) => {
        const existingItem = get().cartItems.find(
          (cartItem) => cartItem.id === productId
        )

        if (existingItem) {
          if (quantity === 0) {
            const updatedCartItems = get().cartItems.filter(
              (item) => item.id !== productId
            )
            set({ cartItems: updatedCartItems })
          } else {
            existingItem.quantity = quantity
            set({ cartItems: [...get().cartItems] })
          }
        }
      },

      increaseQuantity: (productId) => {
        const existingItem = get().cartItems.find(
          (cartItem) => cartItem.id === productId
        )

        if (existingItem) {
          // Don't do anything currenctly
          // if (typeof existingItem.quantity === 'number') {
          //   existingItem.quantity++
          // }
          // set({ cartItems: [...get().cartItems] })
        }
      },

      decreaseQuantity: (productId) => {
        const existingItem = get().cartItems.find(
          (cartItem) => cartItem.id === productId
        )

        if (existingItem) {
          if (typeof existingItem.quantity === 'number') {
            if (existingItem.quantity === 1) {
              const updatedCartItems = get().cartItems.filter(
                (item) => item.id !== productId
              )
              set({ cartItems: updatedCartItems })
            } else {
              existingItem.quantity--
              set({ cartItems: [...get().cartItems] })
            }
          }
        }
      },

      removeItemFromCart: (productId) => {
        const existingItem = get().cartItems.find(
          (cartItem) => cartItem.id === productId
        )

        if (existingItem) {
          if (typeof existingItem.quantity === 'number') {
            const updatedCartItems = get().cartItems.filter(
              (item) => item.id !== productId
            )
            set({ cartItems: updatedCartItems })
          }
        }
      },

      clearCart: () => {
        set({ cartItems: [] })
      },

      setCartItems: (items: CartProduct[]) => {
        set({ cartItems: items })
      },
    }),
    {
      name: 'cart-storage',
    }
  )
)

export default useCartStore
