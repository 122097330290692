import { useState } from 'react'
import { API_URL } from '@/lib/constants'
import { useAuth } from '@/hooks/useAuth'

export function useLMSRedirect() {
  const [loading, setLoading] = useState(false)
  const { getAuthHeaders } = useAuth()

  const handleRedirect = async () => {
    const newTab = window.open('', '_blank')

    setLoading(true)
    try {
      const response = await fetch(
        `${API_URL}/user_api/v1/users/lms_login_link`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            ...getAuthHeaders(),
            Accept: 'application/json',
          },
        }
      )

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(`HTTP ${response.status}: ${errorText}`)
      }

      const data = await response.json()
      if (data.lms_login_link) {
        if (newTab) {
          newTab.location.href = data.lms_login_link
        } else {
          window.open(data.lms_login_link, '_blank')
        }
      } else {
        console.error('Invalid response structure:', data)
      }
    } catch (error) {
      console.error('Error fetching LMS login link:', error)
      if (newTab) newTab.close()
    } finally {
      setLoading(false)
    }
  }

  return { handleRedirect, loading }
}
