import { Link } from 'react-router-dom'

function About() {
  return (
    <div className="grow py-8 px-4 sm:px-12 container mx-auto shadow-md flex gap-8 flex-col main-container-height">
      <div className="w-full">
        <h2 className="text-grays-dark text-2xl font-bold mb-4">
          About CTI
        </h2>
        <p className="text-grays-dark font-normal mb-8">
          CTI offers world-class technical training for automotive service
          professionals, as well as business management education for shop
          owners, technicians, service advisors, service managers, and
          automotive aftermarket fleets and franchises.
        </p>
        <p className="text-grays-dark font-normal mb-8">
          Established in 1999, CTI is committed to providing
          industry leading training and support. CTI class development begins at
          the CTI Research & Development Center in Raleigh, NC. The CTI
          development team uses OE information, case studies, and on-vehicle
          testing to create real-world proprietary content. Subject matter
          experts create enhanced class content using factory materials, case
          studies, hands-on testing and real-time field experiences. Many of the
          CTI instructors are factory-trained technicians and shop foremen.
        </p>
        <Link to="/">
          <a className="text-primary hover:underline block">Search Classes</a>
        </Link>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4 max-h-72">
        <div className="mb-4">
          <img
            src="/images/instructor_talking_3.jpg"
            className="h-full object-contain"
            alt=""
          />
        </div>
        <div className="mb-4">
          <img
            src="/images/diagnostic_car_hood.png"
            className="h-full object-contain"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default About
