import { useAuth } from '@/hooks/useAuth'
import React from 'react'
import TechnetLogo from '@/components/Technet/TechnetLogo'

function Home() {
  const { currentUser } = useAuth()
  return (
    <div>
      <div>Welcome {currentUser.email}</div>
      {currentUser.is_technet_member && (
        <div>
          <div>Congratulations on being a TechNet member</div>
          <TechnetLogo size="md" />
        </div>
      )}
    </div>
  )
}

export default Home
