const HowToDeactivateUsers = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Click the User Menu in the top right corner of the screen, the click{' '}
        <strong>Admin</strong>
      </li>
      <li>
        Click <strong>Manage Users</strong>
      </li>
      <li>
        Select a user from the list and uncheck the <strong>Active</strong> box,
        then click <strong>Save</strong>
      </li>
    </ol>
  </div>
)

export default HowToDeactivateUsers
