import axios, { AxiosRequestConfig } from 'axios'
// import { API_URL } from '../constants'

// export const TEST_BASE_URL = 'http://127.0.0.1:6006'

const config: AxiosRequestConfig = {
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' },
}

const axiosInstance = axios.create(config)

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

export { axiosInstance }
