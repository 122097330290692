import CartProduct from '../../types/CartTypes'

const getTotal = (cartItem: CartProduct[]) => {
  let totalQuantity = 0
  let totalPrice = 0
  cartItem.forEach((item) => {
    totalQuantity += item.quantity!
    totalPrice += item.price! * item.quantity!
  })
  return { totalPrice, totalQuantity }
}

export default getTotal
