import { useEffect } from 'react'

const MeinekeRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://forms.office.com/r/00zHbdjV9V'
  }, [])

  return null
}

export default MeinekeRedirect
