import { RAILS_ENV, SENTRY_DSN } from '@/lib/constants'
import * as Sentry from '@sentry/react'
// TODO: look into tracing interactions using Sentry. See:
// https://docs.sentry.io/platforms/javascript/guides/react/#configure
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from 'react-router-dom'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: RAILS_ENV,
    integrations: [
      // Sentry.reactRouterV6BrowserTracingIntegration({
      //   useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes,
      // }),
    ],
    tracesSampleRate: 1.0, // Adjust sampling rate as needed
  })
}
