import { Outlet } from 'react-router'

function LoggedInLayout() {
  return (
    <>
      <Outlet />
    </>
  )
}

export default LoggedInLayout
