import React from 'react'
import PasswordForm from '@/components/PasswordForm/PasswordForm'

function Password() {
  return (
    <div className="flex-col">
      <div className="flex flex-col py-6 border w-full lg:w-1/2 m-auto bg-grays-light rounded">
        <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Change Password
        </h3>
        <PasswordForm />
      </div>
    </div>
  )
}

export default Password
