import { z } from 'zod'

export const BillingAddressSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  // company: z.string().min(3, {
  //   message: 'Please enter your shop name',
  // }),
  addressLine1: z.string().min(2),
  addressLine2: z.string().optional(),
  city: z.string().min(2),
  stateCode: z.string().min(2).max(2),
  zip: z.string().min(5),
  countryCode: z.string().min(2).max(2).default('US'),
})

export type BillingAddress = z.infer<typeof BillingAddressSchema>

export const defaultBillingAddress = (card) => ({
  firstName: card.first_name || '',
  lastName: card.last_name || '',
  addressLine1: card.addressLine1 || '',
  billing_addr1: card.billing_addr1 || '',
  company: import.meta.env.PUBLIC_COMPANY,
  city: import.meta.env.PUBLIC_CITY,
  stateCode: import.meta.env.PUBLIC_STATE_CODE,
  zip: import.meta.env.PUBLIC_ZIP_CODE,
  countryCode: import.meta.env.PUBLIC_COUNTRY_CODE,
})
