import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SearchFilterProps } from '../../hooks/search'

function SearchForm({
  onSearch,
  onReset,
  instructorList,
  initialValues,
  activeCourseType,
}: {
  onSearch: (values: SearchFilterProps) => void
  onReset: () => void
  instructorList: string[]
  initialValues: SearchFilterProps
  activeCourseType: 'ilt' | 'vilt' | 'olt'
}) {
  const { handleSubmit, register, reset, setValue } =
    useForm<SearchFilterProps>({
      defaultValues: initialValues,
    })

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const instructor = searchParams.get('instructor')

    if (instructor) {
      setValue('instructor', instructor)
      handleSubmit(onSubmit)() // Auto-submit when instructor is set
    }
  }, [searchParams, handleSubmit, setValue])

  function onSubmit(values: SearchFilterProps) {
    onSearch(values)
  }

  const clearSearchBoxes = () => {
    reset()
    onReset()
  }

  return (
    <form
      id="search-form"
      className="bg-grays-light p-4 w-full rounded-sm flex flex-col text-grays-medium h-full border border-grays-lightmedium shadow"
      onSubmit={handleSubmit(onSubmit)}
    >
      {activeCourseType == 'ilt' ? (
        <h2 className="text-primary font-semibold mb-2">
          Filter In-person Instructor Led Classes
        </h2>
      ) : (
        <h2 className="text-primary font-semibold mb-2">
          Filter Virtual Classroom Courses
        </h2>
      )}

      <input
        type="text"
        placeholder="Filter course by keyword"
        className="border border-grays-mediumlight keyword-input w-full rounded-sm mb-4 h-8 text-sm text-grays-medium placeholder:text-grays-medium placeholder:text-sm"
        {...register('keyword')}
      />

      <select
        className="border border-grays-mediumlight w-full rounded-sm mb-4 py-1.5 h-8 text-sm text-grays-medium"
        {...register('instructor')}
      >
        <option value="">Filter by Instructor</option>
        {instructorList.map((instructor, i) => (
          <option key={i} value={instructor}>
            {instructor}
          </option>
        ))}
      </select>

      <div className="flex w-full gap-3">
        <motion.input
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 17,
          }}
          type="submit"
          className="blue-btn bg-primary font-normal rounded shadow-sm text-white basis-1/2 py-1.5"
          value="Filter"
        />
        <motion.input
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 17,
          }}
          type="button"
          className="white-btn basis-1/2 bg-white border border-primary text-primary rounded py-1.5"
          value="Clear"
          onClick={() => {
            clearSearchBoxes()
          }}
        />
      </div>
    </form>
  )
}

export default SearchForm
