import React, { useState } from 'react'
import { PaymentMethod } from '@/hooks/usePaymentMethods'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { PaymentIcon } from '@/components/PaymentIcon/PaymentIcon'

const statuses = {
  default: 'text-green-700 bg-green-50 ring-green-600/20',
  valid: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  paymentMethods: PaymentMethod[]
  onEditPaymentMethod: (paymentMethod: PaymentMethod) => void
  onSetPrimary: (paymentId: string) => void
  onDeleteCard: (paymentMethod: PaymentMethod) => void
}

const ListCreditCards: React.FC<Props> = ({
  paymentMethods,
  onEditPaymentMethod,
  onSetPrimary,
  onDeleteCard,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    paymentMethods.find((sub) => sub.is_default)?.id || ''
  )

  if (!paymentMethods.length) {
    return <p>No saved credit cards.</p>
  }

  return (
    <div className="w-1/2">
      <ul className="">
        {paymentMethods.map((paymentMethod) => {
          const card = paymentMethod.card
          return (
            <li
              key={paymentMethod.id}
              className="flex items-center justify-between gap-x-6 bg-grays-light w-full p-6 rounded mb-1 border border-grays-lightmedium"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3 text-sm/6">
                  <PaymentIcon brand={card.brand} />
                  <p className="font-semibold text-gray-900">
                    {card.masked_number}
                  </p>
                  <p className="whitespace-nowrap">
                    Expiry{' '}
                    <time dateTime={paymentMethod.due_date_time}>
                      {card.expiry_month}/{card.expiry_year}
                    </time>
                  </p>
                  <p className="whitespace-nowrap">
                    {card.first_name} {card.last_name}
                  </p>
                  <p
                    className={classNames(
                      statuses[
                        paymentMethod.id === selectedPaymentMethod
                          ? 'default'
                          : 'valid'
                      ],
                      'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                  >
                    {paymentMethod.id === selectedPaymentMethod
                      ? 'Default'
                      : paymentMethod.status}
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                {/* Action Menu */}
                <Menu as="div" className="relative flex-none">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <button
                        className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50"
                        onClick={() => onEditPaymentMethod(paymentMethod)}
                      >
                        Edit
                        <span className="sr-only">
                          Edit card ending in {card.last4}
                        </span>
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        onClick={() => {
                          setSelectedPaymentMethod(paymentMethod.id)
                          onSetPrimary(paymentMethod.id)
                        }}
                        className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50"
                      >
                        Make Default
                        <span className="sr-only">
                          Set default payment method to card ending in{' '}
                          {card.last4}
                        </span>
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50"
                        onClick={() => onDeleteCard(paymentMethod)}
                      >
                        Delete
                        <span className="sr-only">
                          Delete card ending in {card.last4}
                        </span>
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ListCreditCards
