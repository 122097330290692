import { useLMSRedirect } from '@/hooks/useLMSRedirect'

function LinkToLMSHamburger() {
  const { handleRedirect, loading } = useLMSRedirect()

  return (
    <button
      className="text-sm text-white"
      onClick={handleRedirect}
      disabled={loading}
    >
      {loading ? 'Redirecting...' : 'Access Your Training'}
    </button>
  )
}

export default LinkToLMSHamburger
