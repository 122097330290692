const HowToAccessCerts = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click <strong>Access Your Training</strong>
        <div className="my-4 border rounded">
          <img src="/images/faqs/Access_Training.png" alt="Courses to start" />
        </div>
      </li>
      <li>
        Click the <strong>User Menu</strong> in the top left corner and select{' '}
        <strong>My Activities</strong>
      </li>
      <li>
        Click the <strong>Courses</strong> tab
      </li>
      <li>
        Click the ribbon on completed courses to download a copy of your
        certificate
        <div className="my-4 border rounded">
          <img src="/images/faqs/Certs.png" alt="" />
        </div>
      </li>
    </ol>
  </div>
)

export default HowToAccessCerts
