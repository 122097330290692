import React, { forwardRef } from 'react'

interface Course {
  courseName: string
  courseCode: string
  instructors: { firstname: string; lastname: string }[]
  events: { date: string; start_time: string; end_time: string }[]
}

interface PrintScheduleProps {
  filteredCourses: Course[]
  currentDate: Date
  months: string[]
  convertTime: (time: string) => string
}

export const PrintSchedule = forwardRef<HTMLDivElement, PrintScheduleProps>(
  function PrintSchedule(
    { filteredCourses, currentDate, months, convertTime },
    ref
  ) {
    return (
      <div className="print-schedule p-4" ref={ref}>
        <div className="flex justify-between">
          <h2 className="text-2xl font-bold mb-4">
            Virtual Classroom Schedule <br />
            <span className="text-primary">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
          </h2>
          <div className="">
            <img
              className="w-32"
              src="/images/CTI_WTI_Logo.png"
              alt="cti logo"
            />
          </div>
        </div>
        <div className="bg-grays-light rounded-md">
          {filteredCourses.map((course, index) => (
            <div
              key={index}
              className="border-b border-grays-medium px-4 py-3 last:border-0"
            >
              <h4 className="text-sm font-semibold">{course.courseName}</h4>
              <div>
                {course.instructors.map((instructor, i) => (
                  <p
                    className="text-xs"
                    key={i}
                  >{`${instructor.firstname} ${instructor.lastname}`}</p>
                ))}
                {course.events.map((event, i) => (
                  <p key={i} className="text-xs">
                    {event.date} | {convertTime(event.start_time)} -{' '}
                    {convertTime(event.end_time)}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="bg-primary w-full p-2 mt-4">
          <p className="text-white text-sm font-semibold text-center">
            Register at www.ctionline.com or contact your local sales
            representative
          </p>
        </div>
      </div>
    )
  }
)
