import React from 'react'

function CardHeader({ children }) {
  return (
    <h3 className="text-md text-primary font-semibold text-center">
      {children}
    </h3>
  )
}

export default CardHeader
