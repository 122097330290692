import React, { useImperativeHandle, useRef } from 'react'
import { useEffect, useState } from 'react'
import { ChargebeeCardComponent, ChargebeeInstance } from '@/global'
import { useAuth } from '@/hooks/useAuth'
import { PaymentMethod } from '@/hooks/usePaymentMethods'
import { BillingAddress } from '@/components/PaymentMethods/CreditCardSchema'

interface CbTokenError extends Error {
  code: string
  name: string
  type: 'ClientError'
  message: string
}

export interface CreditCardComponentProps {
  children?: React.ReactNode
  className?: string
  paymentMethod: PaymentMethod | null
}

interface CreditCardComponentHandle {
  tokenize: (
    billingAddress: BillingAddress
  ) => Promise<{ token: string } | { error: string }>
}

interface ComponentContext {
  cardComponent: ChargebeeCardComponent
  cbComponentsInitialized: boolean
}

const ComponentDefaultContext: ComponentContext = {
  cardComponent: null,
  cbComponentsInitialized: false,
}

export const CreditCardComponentContext = React.createContext(
  ComponentDefaultContext
)

/**
 * Much of this is a rewrite of:
 * https://github.com/chargebee/chargebee-js-wrappers/blob/master/chargebee-js-react
 * which unfortunately does not work properly.
 *
 * This is also a good reference for a ChargeBee site using react by one of the
 * ChargeBee devs, but notice he also defines the components himself:
 *
 * https://github.com/chargebee/chargebee-samples/blob/master/payment-components/next-js/src/components/Content.tsx
 */
const CreditCardComponent = React.forwardRef<
  CreditCardComponentHandle,
  CreditCardComponentProps
>(function CreditCardComponent({ children, className, paymentMethod }, ref) {
  const cardComponentId = 'cardComponentID'
  const [loading, setLoading] = useState(true)
  const [invalidCardNumber, setInvalidCardNumber] = useState(false)
  const [invalidCardExpiry, setInvalidCardExpiry] = useState(false)
  const [invalidCardCVV, setInvalidCardCVV] = useState(false)
  const [chargeBeeErrors, setChargeBeeErrors] = useState<string | null>(null)

  const [cbInstance, setCbInstance] = useState<ChargebeeInstance | null>(null)
  const [cbComponentsInitialized, setCbComponentsInitialized] = useState(false)
  const [cardComponent, setCardComponent] =
    useState<ChargebeeCardComponent>(null)

  const { currentUser } = useAuth()

  const containerRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.Chargebee) {
        setLoading(true)

        const initCb = window.Chargebee.init({
          site: import.meta.env.PUBLIC_CHARGEBEE_SITE,
          publishableKey: import.meta.env.PUBLIC_CHARGEBEE_PUBLISHABLE_KEY,
        })

        setCbInstance(initCb)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (cbInstance && !cbComponentsInitialized && containerRef.current) {
      console.log('Initializing card component')
      const options = {}
      cbInstance.load('components').then(() => {
        const cbComponent: ChargebeeCardComponent = cbInstance.createComponent(
          'card',
          options
        )

        setCbComponentsInitialized(true)
        setCardComponent(cbComponent)
      })
    }
    return () => {
      if (cardComponent) cardComponent.deregister()
    }
  }, [cbInstance, cbComponentsInitialized, cardComponent])

  useEffect(() => {
    if (
      cardComponent &&
      cbComponentsInitialized &&
      containerRef.current &&
      cardComponent.status == 0
    ) {
      cardComponent.mount(`#${cardComponentId}`)
    }
  }, [cardComponent, cbComponentsInitialized])

  useImperativeHandle(
    ref,
    () => ({
      tokenize: async (billingAddress: BillingAddress) => {
        const cardValid = await cardComponent.validateCardDetails()
        if (!cardValid) {
          setChargeBeeErrors(
            'Sorry, there was an error with your card details.'
          )
          return null
        }

        try {
          const data = await cardComponent.tokenize({
            // zip: values.zip,
            billingAddress,
            email: currentUser.email,
          })

          if (data.token) {
            return data
          } else {
            setChargeBeeErrors(
              'Sorry, there was an issue validating credit card with our processor.'
            )
            return { error: 'could not tokenize card' }
          }
        } catch (error: any) {
          if ('type' in error && error.type == 'ClientError') {
            const cbError = error as CbTokenError
            switch (cbError.name) {
              case 'INVALID_CARD':
                setInvalidCardNumber(true)
                break
              case 'CARD_EXPIRY_INVALID':
                setInvalidCardExpiry(true)
                break
              case 'CARD_CVV_INVALID':
                setInvalidCardCVV(true)
                break
              case 'invalid_request':
                console.error(cbError)
                break
              default:
                console.error(
                  `Don't know how to handled: ${cbError.name}`,
                  cbError
                )
            }
          } else {
            console.error("Can't handle", error)
            throw error
          }
          return null
        }
      },
    }),
    [cardComponent, currentUser.email]
  )

  if (loading && !cardComponent) {
    return null
  }

  return (
    <CreditCardComponentContext.Provider
      value={{
        cardComponent,
        cbComponentsInitialized,
      }}
    >
      <div id={cardComponentId} className="container" ref={containerRef}>
        {(cardComponent && children) || []}
      </div>
    </CreditCardComponentContext.Provider>
  )
})

export default CreditCardComponent
