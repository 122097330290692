import { BillingAddress } from '@/components/PaymentMethods/CreditCardSchema'
import { ChargebeeCardComponent } from '@/global'
import { User } from '@/types/User'

export async function getTokenForCreditCard(
  cardRef: React.RefObject<ChargebeeCardComponent>,
  currentUser: User,
  billingAddress: BillingAddress
) {
  if (!cardRef.current) {
    return {
      error:
        'Sorry, there was an issue validating credit card with our processor.',
    }
  }

  const additionalData = {
    ...billingAddress,
    email: currentUser.email,
  }
  const tokenData = await cardRef.current.tokenize(additionalData)
  if (!tokenData.token) {
    return {
      error:
        'Sorry, there was an issue validating credit card with our processor.',
    }
  }
  return {
    token: tokenData.token,
  }
}
