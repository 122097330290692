import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import Modal from 'react-modal'
import { convertMinsToHrsMins } from '../../lib/helpers'
import { SessionData } from '@/lib/services/search/flattenCourseData'
import SignUpLinks from './SignUpLinks'
import CourseDescription from './CourseDescription'
import { convertTime } from '@/lib/dateConversions'
import EventDate from './EventDate'

export default function Result({ result }: { result: SessionData }) {
  const [modalOpen, setModalOpen] = useState(false)
  const modalContentRef = useRef<HTMLDivElement | null>(null)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        closeModal()
      }
    }

    if (modalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalOpen])

  const durationInMinutes = result.events.reduce(
    (sum, event) => sum + event.effective_duration,
    0
  )
  const duration = convertMinsToHrsMins(durationInMinutes)

  return (
    <div className="w-full p-8 bg-grays-light border-b border-grays-mediumlight display-linebreak">
      <div className="sm:flex sm:flex-row w-full sm:gap-x-4">
        <div className="mb-4 sm:mb-0 sm:basis-1/3">
          <h3 className="font-bold text-primary mb-2">
            {result.courseCode} - {result.courseName}
          </h3>
          <h4 className="font-medium text-grays-dark mb-0">Event Dates:</h4>
          {result.events.map((event) => {
            if (event.startDateTime) {
              return (
                <EventDate
                  key={event.startDateTime.toDateString()}
                  event={event}
                />
              )
            } else {
              return (
                <p
                  className="font-normal text-grays-dark"
                  key={`${event.date}-${event.start_time}`}
                >
                  No event times scheduled
                </p>
              )
            }
          })}
          {result.locations.map((location) => (
            <h4
              key={location.id}
              className="font-medium text-grays-dark mt-1.5 mb-1.5"
            >
              Location:
              <span className="font-normal text-grays-dark">
                {location.name}
                <br />
                {location.address}
              </span>
            </h4>
          ))}

          <h4 className="font-medium text-grays-dark mt-1.5 mb-1.5">
            Type:{' '}
            <span className="font-normal text-grays-dark">
              {result.courseType === 'ilt' ? 'In Person' : 'Virtual'}
            </span>
          </h4>
          <h4 className="font-medium text-grays-dark mt-1.5 mb-1.5">
            Instructor(s):{' '}
            <span className="font-normal text-grays-dark">
              {result.instructors
                .flatMap(
                  (instructor) =>
                    `${instructor.firstname} ${instructor.lastname}`
                )
                .join(' | ')}
            </span>
          </h4>
          <h4 className="font-medium text-grays-dark mt-1.5 mb-1.5">
            Seats Filled:{' '}
            <span className="font-normal text-grays-dark">
              {result.numEnrolled}
              {' / '}
              {result.maxEnrollment}
            </span>
          </h4>
          <h4 className="font-medium text-grays-dark mt-1.5 mb-1.5">
            Duration:{' '}
            <span className="font-normal text-grays-dark">{duration}</span>
          </h4>
        </div>
        <div className="sm:basis-2/3">
          <CourseDescription
            className="maxlines font-normal text-grays-dark mb-4 card-paragraph"
            descriptionHtml={result.courseDescription}
          />
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={() => openModal()}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            href="#"
            className="blue-btn inline-block border-primary border rounded-sm bg-primary text-white px-4 py-1.5 font-normal mr-2 hover:shadow-md"
          >
            More Details
          </motion.a>
          <div className="mt-2">
            {result.numEnrolled >= result.maxEnrollment ? (
              <div className="inline-block text-secondary ml-4">SOLD OUT</div>
            ) : (
              <div className="inline-block text-secondar">
                <SignUpLinks result={result} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="text-black max-h-screen p-4 overflow-y-scroll"
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        closeTimeoutMS={500}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0, 0.85)',
          },
        }}
      >
        <section className="w-full flex justify-between">
          <button className="text-2xl text-white transition ease-in-out delay-50 hover:scale-125">
            &times;
          </button>
        </section>
        <section>
          <div
            ref={modalContentRef}
            className="modal-width flex flex-col m-auto bg-white shadow-lg p-4 rounded"
          >
            <div className="flex flex-row p-4 gap-6 justify-between">
              <section className="basis-2/3">
                <h2 className="text-primary font-semibold text-2xl mb-4">
                  {result.courseName}
                </h2>
                <p className="mb-2 text-primary text-xs uppercase font-bold">
                  Class Description
                </p>
                <CourseDescription
                  className="text-sm whitespace-pre-wrap"
                  descriptionHtml={result.courseDescription}
                />
              </section>
              <section>
                <div className="bg-gray-200 p-4 rounded basis-1/3">
                  <p className="text-primary text-xs uppercase font-bold">
                    Class Type
                  </p>
                  {result.courseType === 'vilt' ? (
                    <p className="text-sm">Virtual Training</p>
                  ) : (
                    <p className="text-sm">In-person Training</p>
                  )}
                  <p className="mt-4 text-primary text-xs uppercase font-bold">
                    Class Code
                  </p>
                  <p className="text-sm">{result.courseCode}</p>

                  {result.locations.map((location) => (
                    <div key={location.id}>
                      <p className="mt-4 text-primary text-xs uppercase font-bold">
                        Location
                      </p>
                      <p className="text-sm">
                        {location.name}
                        <br />
                        {location.address}
                      </p>
                      <p className="mt-4 text-primary text-xs uppercase font-bold">
                        Date & Time
                      </p>
                    </div>
                  ))}
                  {result.events.map((event) => (
                    <p
                      key={`${event.date}-${event.start_time}`}
                      className="text-sm"
                    >
                      {event.date} | {convertTime(event.start_time)}
                    </p>
                  ))}
                  <p className="mt-4 text-primary text-xs uppercase font-bold">
                    Instructor(s)
                  </p>
                  <p className="text-sm">
                    {result.instructors
                      .flatMap(
                        (instructor) =>
                          `${instructor.firstname} ${instructor.lastname}`
                      )
                      .join(' | ')}
                  </p>
                  <p className="mt-4 text-primary text-xs uppercase font-bold">
                    Total Class Hours
                  </p>
                  <p className="text-sm">{duration}</p>
                  <p>Plan prices </p>
                </div>
              </section>
            </div>
          </div>
        </section>
      </Modal>
    </div>
  )
}
