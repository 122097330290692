import OrderSummary from '@/components/Cart/OrderSummary'

import { Button } from '../components/ui/button'
import { useNavigate } from 'react-router'
import { useAuth } from '@/hooks/useAuth'
import { createSearchParams } from 'react-router-dom'
import useCartStore from '@/store/cart'
// import { RAILS_ENV } from '@/lib/constants'

function CheckoutButton({ checkout }: { checkout: () => void }) {
  // if (RAILS_ENV !== 'production') {
  return (
    <Button
      type="button"
      className="w-full text-base font-medium text-white hover:bg-blue-800"
      onClick={checkout}
      variant="default"
    >
      Checkout
    </Button>
  )
  // } else {
  //   return (
  //     <Button
  //       type="button"
  //       className="w-full text-base font-medium text-white"
  //       variant="secondary"
  //       disabled
  //     >
  //       Checkout will be enabled soon!
  //     </Button>
  //   )
  // }
}

function Cart() {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  const { cartItems } = useCartStore()

  const checkout = () => {
    if (isAuthenticated()) {
      navigate('/checkout')
    } else {
      navigate({
        pathname: '/signin',
        search: createSearchParams({ return_path: '/checkout' }).toString(),
      })
    }
  }

  return (
    <div className="flex items-center justify-center flex-col main-container-height">
      <div className="rounded border border-secondary p-4 w-2/5 text-secondary mb-4">
        <p>
          <strong>Note: </strong>We’re currently experiencing an issue that only
          allows for one item to be purchased per transaction. We’re working on
          it, but in the meantime, please use a separate transaction for each
          item.
        </p>
      </div>
      <h1 className="text-3xl">Cart</h1>
      <div className="flex justify-center">
        <OrderSummary>
          <div className="flex gap-x-2">
            {cartItems.length > 0 && <CheckoutButton checkout={checkout} />}
            <Button
              type="button"
              className="w-full text-base font-medium text-white"
              variant="secondary"
              onClick={() => navigate('/')}
            >
              Continue Shopping
            </Button>
          </div>
        </OrderSummary>
      </div>
    </div>
  )
}

export default Cart
