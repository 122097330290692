const HowToMoveBranches = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Send email request to{' '}
        <a className="text-blue-800" href="mailto:ctisupport@carquest.com">
          ctisupport@carquest.com
        </a>{' '}
        and include the user's first/last name and email address and where they
        need to be moved to
      </li>
    </ol>
  </div>
)

export default HowToMoveBranches
