import { API_URL } from '../constants'
import { axiosInstance } from './axiosinstance'

const COURSES_API_URL = `${API_URL}/api/v1/courses`

export const loadCourseData = async () => {
  try {
    const response = await axiosInstance.get(COURSES_API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.status != 200) {
      console.warn(
        `WARN: Couldn't load course data from LMS: '${response.status}':'${response.statusText}'`
      )
      return []
    }

    const json = await response.data

    return json
  } catch (error) {
    console.warn(
      "WARN: Couldn't load course data from LMS: '" + error.message + "'"
    )
    return []
  }
}

export const loadSelfPacedCourses = async () => {
  try {
    const response = await axiosInstance.get(
      `${COURSES_API_URL}?course_type=olt`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (response.status != 200) {
      console.warn(
        `WARN: Couldn't load course data from LMS: '${response.status}':'${response.statusText}'`
      )
      return []
    }

    const json = await response.data
    return json
  } catch (error) {
    console.log(error)
    console.warn(
      "WARN: Couldn't load course data from LMS: '" + error.message + "'"
    )
    return []
  }
}
