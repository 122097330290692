import Result from './Result'
import ResultInProgress from './ResultInProgress'
import { SessionData } from '../../lib/services/search/flattenCourseData'
import { EventImpl } from '@/types/Course'

const firstEventDateTime = (events: EventImpl[]) => {
  return events[0].endDateTime.getTime()
}

const isInProgressOrPast = (events: EventImpl[]) => {
  const now = new Date()
  return events.some((event) => {
    const eventDate = event.startDateTime ?? new Date(0)
    return eventDate <= now
  })
}

const SearchResults = ({ results }: { results: SessionData[] }) => {
  const sortedResults = results.sort((a, b) => {
    return firstEventDateTime(a.events) - firstEventDateTime(b.events)
  })

  return (
    <div>
      {sortedResults.map((result) => {
        const renderInProgress = isInProgressOrPast(result.events)
        return renderInProgress ? (
          <ResultInProgress result={result} key={result.uid} />
        ) : (
          <Result result={result} key={result.uid} />
        )
      })}
    </div>
  )
}

export default SearchResults
