import { BillingAddress } from '@/components/PaymentMethods/CreditCardSchema'
import { API_URL } from '../lib/constants'
import { axiosInstance } from '../lib/services/axiosinstance'
import { useAuth } from './useAuth'
import { ApiError } from '@/lib/ApiError'

const PAYMENT_METHODS_API_URL = `${API_URL}/api/v1/payment_methods`

export interface Token {
  token: string
}
export interface PaymentMethod {
  id: string
  card: Card
  is_default?: boolean
  status?: string 
  due_date_time?: string
}


export interface ChargeAccountDetails {
  net_term_days: number
  card_status: string
  credit_account_number: string
  aap_account_number: string
}

export interface Card extends BillingAddress {
  last4?: string
  expiry_month?: number
  expiry_year?: number
  brand?: string
  masked_number?: string
  first_name?: string
  last_name?: string
}

export function usePaymentMethods() {
  const { getAuthHeaders } = useAuth()

  const selectPaymentMethod = async (id: string) => {
    try {
      const response = await axiosInstance.put(
        `${PAYMENT_METHODS_API_URL}/${id}/select`,
        {
          payment_method_id: id,
        },
        {
          headers: getAuthHeaders(),
        }
      )

      const json = await response.data

      return json
    } catch (error) {
      console.error("Couldn't load payment methods", error)
      return {
        error: "Couldn't update payment method",
      }
    }
  }

  const deletePaymentMethod = async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `${PAYMENT_METHODS_API_URL}/${id}.json`,
        {
          headers: getAuthHeaders(),
        }
      )

      const json = await response.data

      return json
    } catch (error) {
      return {
        error: new ApiError(
          `Couldn't delete payment method ${error.message}`,
          400
        ),
      }
    }
  }

  const listPaymentMethods = async () => {
    try {
      const response = await axiosInstance.get(PAYMENT_METHODS_API_URL, {
        headers: getAuthHeaders(),
      })

      const json = await response.data

      return json
    } catch (error) {
      return []
    }
  }

  const getChargeAccountDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${PAYMENT_METHODS_API_URL}/charge_account_details`,
        {
          headers: getAuthHeaders(),
        }
      )

      const json = await response.data

      return json
    } catch (error) {
      return {}
    }
  }

  const createPaymentMethod = async (
    token: Token,
    billingAddress: BillingAddress
  ) => {
    try {
      const response = await axiosInstance.post(
        PAYMENT_METHODS_API_URL,
        {
          payment_method: token,
          billing_address: billingAddress,
        },
        {
          headers: getAuthHeaders(),
        }
      )

      return response.data
    } catch (error) {
      if (error.response) {
        const response = error.response
        console.error('Could not create payment method', response)
        return {
          error: new ApiError(response.statusText, response.status),
        }
      }
    }
  }

  return {
    listPaymentMethods,
    createPaymentMethod,
    selectPaymentMethod,
    deletePaymentMethod,
    getChargeAccountDetails,
  }
}
