import React, { ReactElement } from 'react'

import useCartStore from '../../store/cart'
import CartItem from './CartItem'
import { priceInDollars } from '../../lib/priceConversions'
import getTotal from '@/lib/cart/getTotal'

function OrderSummary({ children }: { children: ReactElement }) {
  const { cartItems } = useCartStore()

  const { totalPrice } = getTotal(cartItems)

  return (
    <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
      <h3 className="sr-only">Items in your cart</h3>
      {cartItems.length === 0 && (
        <div className="text-xl text-center">Your cart is empty</div>
      )}
      {cartItems.length > 0 && (
        <>
          <ul className="divide-y divide-gray-200">
            {cartItems.map((item) => (
              <li key={item.id} className="flex px-4 py-6 sm:px-6">
                <CartItem product={item} />
              </li>
            ))}
          </ul>
          <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base font-medium">Total</dt>
              <dd className="text-base font-medium text-gray-900">
                {priceInDollars(totalPrice)}
              </dd>
            </div>
          </dl>
        </>
      )}

      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
        {children}
      </div>
    </div>
  )
}

export default OrderSummary
