import Invoice from '@/components/Invoices/Invoice'
import { API_URL } from '../lib/constants'
import { axiosInstance } from '../lib/services/axiosinstance'
import { useAuth } from './useAuth'

const INVOICES_API_URL = `${API_URL}/api/v1/invoices`

interface InvoiceBillingAddress {
  first_name: string
  last_name: string
}

export interface LineItem {
  id: string
  entity_id: string
  description: string
  quantity: number
  unit_amount: number
  amount: number
}

export interface Invoice {
  id: string
  status: string
  date: string
  total: number
  line_items: LineItem[]
  billing_address: InvoiceBillingAddress
}

export function useInvoices() {
  const { getAuthHeaders } = useAuth()

  async function listInvoices(): Promise<Invoice[]> {
    try {
      const response = await axiosInstance.get(INVOICES_API_URL, {
        headers: getAuthHeaders(),
      })

      const json = (await response.data) as Invoice[]

      return json
    } catch (error) {
      return []
    }
  }

  return {
    listInvoices,
  }
}
