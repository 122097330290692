import React from 'react'

function Card({ children }) {
  return (
    <div className="flex flex-col w-full h-full bg-grays shadow p-8 md:p-6 rounded-md border border-grays-lightmedium">
      {children}
    </div>
  )
}

export default Card
