import lunr from 'lunr'
import React, {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import analyze from '@/lib/services/search/textAnalylzer'
import { useLocation } from 'react-router-dom'
import { loadCourseData } from '../lib/services/courses'
import { CourseType } from '@/types/Course'
import buildIndex from '../lib/services/search/buildIndex'
import {
  flattenCourseData,
  SessionData,
} from '../lib/services/search/flattenCourseData'

type Sessions = SessionData[]

export interface SearchFilterProps {
  keyword: string
  instructor: string
  courseType: CourseType
}

type SearchFilterContextType = {
  sessionData: Sessions
  loading: boolean
  search: (searchParams: SearchFilterProps) => Sessions
} | null

export const INITIAL_SEARCH_FILTERS: SearchFilterProps = {
  keyword: '',
  instructor: '',
  courseType: 'vilt',
}

const SearchFilterContext = createContext<SearchFilterContextType>(null)

export const SearchFilterProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const [sessionData, setSessionData] = useState<Sessions>([])

  const [searchIndex, setSearchIndex] = useState<lunr.Index>(
    lunr(function () {
      this.ref('uid')
    })
  )
  const location = useLocation()
  const previousPathname = useRef(location.pathname)

  useEffect(() => {
    const loadIndex = async () => {
      const courses = await loadCourseData()
      const sessions = flattenCourseData(courses)
      const idx = await buildIndex(sessions)
      setSessionData(sessions)
      setSearchIndex(idx)
    }

    setLoading(true)
    loadIndex()
    setLoading(false)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const instructorName = params.get('instructor')

    if (instructorName) {
      // Trigger search with instructor name if it exists in the query
      search({
        keyword: '',
        instructor: instructorName,
        courseType: 'ilt',
      })
    }
  }, [location.search])

  useEffect(() => {
    // Only scroll to top when the pathname changes, meaning a navigation to a new page
    if (previousPathname.current !== location.pathname) {
      window.scrollTo(0, 0)
      previousPathname.current = location.pathname
    }
  }, [location.pathname])

  const search = (filters: SearchFilterProps) => {
    const keywordFilter = filters.keyword
    const instructorFilter = filters.instructor
    const courseTypeFilter = filters.courseType

    if (keywordFilter || instructorFilter || courseTypeFilter) {
      const results = searchIndex.query(function (q) {
        if (keywordFilter) {
          keywordFilter.split(/\s+/).forEach((term) => {
            const analyzedTerm = analyze(term)
            if (analyzedTerm) {
              q.term(analyzedTerm, {
                presence: lunr.Query.presence.REQUIRED,
              })
            }
          })
        }

        if (instructorFilter) {
          const analyzedInstructor = analyze(instructorFilter)

          if (analyzedInstructor) {
            // Ensure the term is valid before querying
            q.term(analyzedInstructor, {
              fields: ['instructors'],
              presence: lunr.Query.presence.OPTIONAL,
              boost: 10,
            })
          } else {
            console.warn('⚠️ Invalid instructor search term:', instructorFilter)
          }
        }

        if (courseTypeFilter && courseTypeFilter !== 'all') {
          q.term(courseTypeFilter, {
            fields: ['courseType'],
            presence: lunr.Query.presence.REQUIRED,
          })
        }

        // TODO: Allow for filtering by course location
        // if (locationFilter) {
        //   const analyzedLocation = analyze(locationFilter)
        //   if (analyzedLocation) {
        //     q.term(analyzedLocation, {
        //       fields: ['Type'],
        //       presence: lunr.Query.presence.REQUIRED,
        //     })
        //   }
        // }
      })
      const searchResults = results.reduce((searchResults, res) => {
        const session = sessionData.find((session) => session.uid === res.ref)

        if (session) {
          if (
            !instructorFilter ||
            session.instructors.some((inst) =>
              `${inst.firstname} ${inst.lastname}`
                .toLowerCase()
                .includes(instructorFilter.toLowerCase())
            )
          ) {
            searchResults.push(session)
          }
        } else {
          console.log('NO SESSION for ', res)
        }
        return searchResults
      }, [])

      return searchResults
    } else {
      return sessionData
    }
  }

  return (
    <SearchFilterContext.Provider
      value={{
        search,
        sessionData,
        loading,
      }}
    >
      {children}
    </SearchFilterContext.Provider>
  )
}

export default SearchFilterContext
