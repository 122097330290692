import React, { Suspense } from 'react'
import ApplicationLayout from '@/layouts/ApplicationLayout'
import {
  Route,
  Routes as ReactRoutes,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import { toast } from '@/components/ui/use-toast'

// Pages
import Home from '@/pages/home'
import About from '@/pages/about'
import Destination from '@/pages/destination'
import Schedule from '@/pages/monthlyschedule'
import Catalog from '@/pages/catalog'
import HelpFaq from '@/pages/help-faq'
import RDCenter from '@/pages/rdc'
import ContactUs from '@/pages/contact-us'
import Cart from '@/pages/cart'
import Signin from '@/pages/signin'
import Signup from '@/pages/signup'
import Subscriptions from '@/pages/profile/subscriptions'
import Admin from '@/pages/profile/admin'
import Settings from '@/pages/profile/settings'
import Password from '@/pages/profile/settings/password'
import PaymentMethods from '@/pages/profile/settings/payment_methods'
import EditUser from '@/pages/profile/settings/edit_user'
import Enroll from '@/pages/profile/enroll'
import ManageUsers from '@/pages/profile/manageUsers'
import EnrollUser from '@/pages/profile/enrollUser'
import Checkout from '@/pages/checkout'
import ResetPassword from '@/pages/reset-password'
import Invoices from '@/pages/profile/settings/invoices'
import SettingsHome from '@/pages/profile/settings/home'
import LoggedInLayout from '@/layouts/LoggedInLayout'
import NotFound from '@/pages/not-found'
import MeinekeRedirect from '@/components/Redirects/MeinekeRedirect'

// See: https://github.com/vitejs/vite/issues/10460 for why the .js and paths
const Instructors = React.lazy(() => import('../pages/instructors.js'))

const PrivateRoute: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated()) {
    return <>{children ?? <Outlet />}</>
  } else {
    toast({
      title: 'Not signed in',
      description: 'You need to sign in to access that page',
      variant: 'destructive',
    })
    return <Navigate to="/signin" replace />
  }
}

function Routes() {
  return (
    <ReactRoutes>
      <Route element={<ApplicationLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/cart" element={<Cart />} />

        {/* Redirect Route */}
        <Route path="/meinekepromo" element={<MeinekeRedirect />} />

        {/* Training Menu */}
        <Route path="/monthlyschedule" element={<Schedule />} />
        <Route path="/destination" element={<Destination />} />

        {/* Catalog Menu */}
        <Route path="/catalog" element={<Catalog />} />

        {/* About Us Menu */}
        <Route path="/about" element={<About />} />
        <Route path="/rdc" element={<RDCenter />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/instructors"
          element={
            <Suspense fallback={<div>Loading Instructors...</div>}>
              <Instructors />
            </Suspense>
          }
        />

        {/* Help & Faq */}
        <Route path="/help-faq" element={<HelpFaq />} />
        <Route path="*" element={<NotFound />} />

        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<LoggedInLayout />}>
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/profile">
              <Route path="settings" element={<Settings />}>
                <Route index element={<SettingsHome />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="edituser" element={<EditUser />} />
                <Route path="password" element={<Password />} />
                <Route path="payment-methods" element={<PaymentMethods />} />
                <Route path="invoices" element={<Invoices />} />
              </Route>
              <Route path="admin" element={<Admin />}>
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="enroll" element={<Enroll />} />
                <Route path="manageusers" element={<ManageUsers />} />
                <Route path="enrolluser" element={<EnrollUser />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </ReactRoutes>
  )
}

export default Routes
