import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

import { useNavigate } from 'react-router'
import { useAuth } from '../hooks/useAuth'
import { FormErrors } from '@/components/FormErrors/FormErrors'
import { toast } from '@/components/ui/use-toast'

const formSchema = z.object({
  email: z.string().min(2, {
    message: 'Email required',
  }),
})

function ResetPassword() {
  const navigate = useNavigate()

  const { loading, errors, resetPassword } = useAuth()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await resetPassword(values.email)
    toast({
      title:
        'A password reset email has been sent to your inbox. If you do not see it, please check your spam or junk folder.',
    })
    navigate('/')
  }

  if (loading) return <p>Loading...</p>

  return (
    <>
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex-col items-center justify-center">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
          <FormErrors errors={errors} />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="email@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div>
                <Button type="submit" className="w-full text-base font-medium">
                  Reset Password
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
