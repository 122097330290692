const HowToViewSchedule = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Scroll down to <strong>Live Instructor Led</strong> training in the
        search box
      </li>
      <li>
        Filter by <strong>keyword, class type or instructor</strong> if you wish
      </li>
    </ol>
  </div>
)

export default HowToViewSchedule
