import React from 'react'
import { User } from '@/types/ManageUsers'
import { MotionButton } from '../ui/motion-button'

interface UserFormProps {
  selectedUser: User | null
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSave: () => void
  handleCancel: () => void
  emailError: string | null
  loadingModal: boolean
}

const EditUserForm: React.FC<UserFormProps> = ({
  selectedUser,
  handleInputChange,
  handleSelectChange,
  handleCheckboxChange,
  handleSave,
  handleCancel,
  emailError,
  loadingModal,
}) => {
  if (!selectedUser) return null

  return (
    <div className="p-4 border rounded bg-white">
      <h3 className="font-medium text-primary mb-4">Edit User</h3>
      <form className="space-y-4">
        <div>
          <label className="block mb-1">First Name</label>
          <input
            type="text"
            name="first_name"
            value={selectedUser.first_name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={selectedUser.last_name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block mb-1">Email</label>
          <input
            type="email"
            name="email"
            value={selectedUser.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p>
          )}
        </div>
        <div>
          <label className="block mb-1">User Level</label>
          <select
            name="user_level"
            value={selectedUser.user_level}
            onChange={handleSelectChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="user">User</option>
            <option value="power_user">Shop Manager</option>
          </select>
        </div>
        <div>
          <label className="block mb-1">Active</label>
          <input
            type="checkbox"
            checked={selectedUser.active}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          Active
        </div>
        <div className="flex gap-2">
          <MotionButton
            type="button"
            onClick={handleSave}
            className={`text-white p-2 rounded ${loadingModal ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'}`}
            disabled={loadingModal}
          >
            {loadingModal ? 'Processing...' : 'Save'}
          </MotionButton>

          <MotionButton
            type="button"
            variant="secondary"
            onClick={handleCancel}
            className="text-white p-2 rounded"
          >
            Cancel
          </MotionButton>
        </div>
      </form>
    </div>
  )
}

export default EditUserForm
