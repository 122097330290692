const HowToUpdatePayment = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click the User Menu in the top right corner of the screen, the click{' '}
        <strong>Settings</strong>
      </li>
      <li>
        Click <strong>Payment Methods</strong>
      </li>
      <li>
        Click <strong>Add a new card</strong> or make a selection from the
        existing payment methods displayed
      </li>
    </ol>
  </div>
)

export default HowToUpdatePayment
