import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router'
import Header from '@/components/Nav/Header'
import Footer from '@/components/Nav/Footer'
import { Toaster } from '@/components/ui/toaster'
import { useAuth } from '@/hooks/useAuth'
import useCartStore from '@/store/cart'

function ApplicationLayout() {
  const { clearCart } = useCartStore()
  // TODO
  // This hook will change the i18n instance language to the current locale
  // detected, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  //useChangeLanguage(locale)

  const [isCartStoreHydrated, setIsCartStoreHydrated] = useState(false)
  const { currentUser } = useAuth()
  const { cartItems, addItemToCart } = useCartStore()

  useEffect(() => {
    // see: https://github.com/pmndrs/zustand/issues/513
    if (currentUser && !isCartStoreHydrated) {
      console.log('Hydrating cart')

      const loggedOutCartItems = cartItems

      useCartStore.persist.setOptions({
        name: `cart-${currentUser.id}-storage`,
      })
      useCartStore.persist.rehydrate()
      localStorage.removeItem('cart-storage')

      console.log(`Adding existing ${loggedOutCartItems.length} items to cart`)
      loggedOutCartItems.forEach((cartItem) => {
        const product = {
          id: cartItem.id,
          name: cartItem.name,
          price: cartItem.price,
          description: cartItem.description,
          period_unit: cartItem.period_unit,
          product_type: cartItem.product_type,
        }
        addItemToCart(product)
      })

      setIsCartStoreHydrated(useCartStore.persist.hasHydrated())
    }

    if (!currentUser && isCartStoreHydrated) {
      console.log('Clearing cart storage')
      useCartStore.persist.setOptions({
        name: `cart-storage`,
      })
      useCartStore.persist.rehydrate()
      clearCart()
      setIsCartStoreHydrated(false)
    }
  }, [currentUser, isCartStoreHydrated])

  return (
    <>
      <div className="h-screen flex flex-col">
        <Header />
        <main className="flex flex-col items-stretch px-4">
          <Outlet />
        </main>
        <Footer />
      </div>
      <Toaster />
    </>
  )
}

export default ApplicationLayout
