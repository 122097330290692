import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TypeOf, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '../components/ui/form'
import { Button } from '../components/ui/button'
import { Input } from '../components/ui/input'

import { useNavigate } from 'react-router'
import { ISignup, useAuth } from '../hooks/useAuth'
import { Link } from '@/components/ui/link'
import { FormErrors } from '@/components/FormErrors/FormErrors'
import { StatesDropdown } from '@/components/StatesDropdown/StatesDropdown'
import { PhoneInput } from '@/components/ui/phone-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from '@/components/ui/use-toast'

const baseSchema = z
  .object({
    first_name: z.string({ required_error: 'First name is required' }).min(2, {
      message: 'First name required',
    }),
    last_name: z.string().min(2, {
      message: 'Last name required',
    }),
    email: z
      .string()
      .min(2, {
        message: 'Email required',
      })
      .email('Email is not valid'),
    password: z.string().min(2, {
      message: 'Password required',
    }),
    password_confirmation: z.string().min(2, {
      message: 'Password confirmation required',
    }),
    phone_number: z
      .string()
      .refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
    // .or(z.literal("")) if we want it to be optional,
    account_type: z.string(),
    address_line1: z.string().optional(),
    address_line2: z.string().optional(),
    city: z.string().optional(),
    state_code: z.string().optional(),
    postal_code: z.string().optional(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password'],
  })

const formSchema = z
  .discriminatedUnion('account_type', [
    z.object({
      account_type: z.literal('shop'),
      shop_name: z.string().min(2, {
        message: 'A shop name is required',
      }),
      account_number: z.string().regex(/\d{8,12}/, {
        message: 'Please enter an account number between 8 and 12 digits',
      }),
    }),
    z.object({
      account_type: z.literal('individual'),
    }),
  ])
  .and(baseSchema)

function Signup() {
  const navigate = useNavigate()
  const { loading, errors, signUp, currentUser } = useAuth()

  useEffect(() => {
    if (currentUser) {
      navigate('/')
    }
  }, [currentUser, navigate])

  const form = useForm<TypeOf<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      account_type: 'shop',
      shop_name: '',
    },
  })

  const accountType = form.watch('account_type', 'shop')

  const onSubmit = async (values: ISignup) => {
    const res = await signUp(values)
    if (res) {
      toast({
        title: 'Successfully signed up!',
      })
    }
  }

  if (loading) return <p>Loading...</p>

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex-col items-center justify-center mb-4">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign up for a new account
          </h2>
          <FormErrors errors={errors} />
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
          >
            <div className="mb-4">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Personal Information
              </h2>

              <div className="container">
                <div className="sm:col-span-3">
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>First Name*</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="sm:col-span-3">
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Last Name*</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                {/*
                    TODO: Is country required?
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select>
                    </div>
                  </div> */}

                <div className="col-span-full">
                  <FormField
                    control={form.control}
                    name="address_line1"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address Line 1</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="col-span-full">
                  <FormField
                    control={form.control}
                    name="address_line2"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address Line 2</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                  <FormField
                    control={form.control}
                    name="city"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>City</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="sm:col-span-2">
                  <FormField
                    control={form.control}
                    name="state_code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>State / Province</FormLabel>
                        <StatesDropdown
                          onChange={field.onChange}
                          value={field.value}
                          placeholder={''}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="sm:col-span-2">
                  <FormField
                    control={form.control}
                    name="postal_code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>ZIP / Postal code</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="mb-4">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Account Information
                </h2>

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email*</FormLabel>
                      <FormControl>
                        <Input placeholder="email@example.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password*</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password_confirmation"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password Confirmation*</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number*</FormLabel>
                      <FormControl>
                        <PhoneInput {...field} defaultCountry={'US'} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {/* <FormField
                control={form.control}
                name="account_type"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormControl>
                      <RadioGroup
                        className="flex items-center space-x-2 mb-2"
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormItem className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="shop" />
                          </FormControl>
                          <FormLabel className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            Sign up your shop
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="individual" />
                          </FormControl>
                          <FormLabel className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                            Sign up for yourself
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              /> */}

              {/* {accountType == 'shop' && ( */}
              <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Shop Information
                </h2>
                <FormField
                  control={form.control}
                  name="shop_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Shop Name*</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="account_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Advance Auto Parts Customer Number*</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
              {/* )} */}
            </div>
            <div>
              <p className="text-xs">* Required Information</p>
            </div>

            <div className="col-span-2 m-auto">
              <Button type="submit" className="text-base min-w-72 font-medium">
                Sign Up
              </Button>
              <p className="mt-4 text-center text-sm text-gray-500">
                Already a member?{' '}
                <Link
                  to={'/signin'}
                  className="font-semibold leading-6 text-primary hover:text-primary-500"
                >
                  Sign in to your account
                </Link>
              </p>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default Signup
