import isEmpty from './support/isEmpty'

export const MAX_DATE = new Date(Date.parse('2050-01-01'))

export function displayDate(date: string) {
  return new Date(date).toLocaleString()
}

/**
 * Docebo sends the times over in this format: 22:00:00
 * @param str
 * @param timeZone
 */
export function convertTime(str: string) {
  // Check correct time format and split into components
  const match = str.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [str]

  let res = ''
  if (match.length > 1) {
    const hours = Number(match[1])
    res += hours % 12 || 12 // Adjust hours
    res += ':'
    res += match[3]
    res += hours < 12 ? ' AM' : ' PM' // Set AM/PM
  }
  return res
}

export function convertToDate(dateStr: string, time: string, timeZone: string) {
  if (isEmpty(dateStr) || isEmpty(time) || isEmpty(timeZone)) {
    // We want anything that might be displayed without a scheduled time to be
    // displayed last
    return MAX_DATE
  }

  const match = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/)
  const hours = Number(match[1])
  const minutes = Number(match[3])

  const [year, month, date] = dateStr.trim().split('-')

  return dateWithTimeZone(
    timeZone,
    parseInt(year),
    parseInt(month) - 1,
    parseInt(date),
    hours,
    minutes,
    0
  )
}

function dateWithTimeZone(
  timeZone: string,
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number
) {
  const date = new Date(Date.UTC(year, month, day, hour, minute, second))

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }))
  const offset = utcDate.getTime() - tzDate.getTime()

  date.setTime(date.getTime() + offset)

  return date
}
