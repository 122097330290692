import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import { Link } from '@/components/ui/link'
import { FormErrors } from '@/components/FormErrors/FormErrors'

import { Hash } from '@/types/Hash'
import { toast } from '@/components/ui/use-toast'

const formSchema = z.object({
  email: z.string().min(2, {
    message: 'Email required',
  }),
  password: z.string().min(2, {
    message: 'Password required',
  }),
})

function Signin() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { loading, signIn } = useAuth()
  const [errors, setErrors] = useState<Hash<string>>()
  const [accountError, setAccountError] = useState<boolean>(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await signIn(values.email, values.password)
    setAccountError(false)

    if (result.success) {
      toast({
        title: 'Successfully logged in',
      })

      const returnPath = searchParams.get('return_path')
      if (returnPath) {
        navigate(returnPath)
      } else {
        navigate('/')
      }
    } else {
      if (result.errors.code === 'account_error') {
        setAccountError(true)
      } else {
        setErrors(result.errors)
      }
    }
  }

  if (loading) return <p>Loading...</p>

  return (
    <>
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex-col items-center justify-center">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          <FormErrors errors={errors} />
          {accountError && (
            <div className="text-red-500 text-center">
              There is a problem with your account, please contact us at{' '}
              <a href="mailto:ctisupport@carquest.com">
                ctisupport@carquest.com
              </a>
            </div>
          )}
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email or Username</FormLabel>
                    <FormControl>
                      <Input placeholder="email@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center justify-between">
                      <FormLabel>Password</FormLabel>
                      <div className="text-sm">
                        <Link
                          to="/reset-password"
                          className="font-semibold text-primary hover:text-primary-500"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <FormControl>
                      <Input type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div>
                <Button type="submit" className="w-full text-base font-medium">
                  Sign in
                </Button>
              </div>
            </form>
          </Form>

          <p className="mt-10 text-center text-sm text-gray-600">
            Not a member?{' '}
            <Link
              to={'/signup'}
              className="font-semibold leading-6 text-primary hover:text-primary-500"
            >
              Sign up today!
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default Signin
