/**
 * Some helper methods for dealing with TECHNET specific prices etc
 */

import Product from '@/types/Product'

export function isTechnetPriceForProduct(plan: Product) {
  return plan.id.toString().match(/TECHNET/)
}

export function hasTechnetProduct(products: Product[]) {
  return products.some((product) => isTechnetPriceForProduct(product))
}
