import React, { useState, useEffect } from 'react'
import { MotionButton } from '../../components/ui/motion-button'
import { useEnrollments } from '../../hooks/enrollment'
import TabContentNoStudents from '@/components/Tabs/TabContentNoStudents'
import { useAuth } from '../../hooks/useAuth'
import { toast } from '../../components/ui/use-toast'

interface Subscription {
  id: string
  name: string
  bundle_code: string
  plan_name: string
  plan_code: string
}

interface Course {
  item_id: string
  item_name: string
  item_code: string
  type: number
}

const EnrollUser: React.FC = () => {
  const {
    fetchSubContentAndStudents,
    enrollCourseStudents,
    enrollLPStudents,
    fetchSubscriptions,
  } = useEnrollments()

  const { currentUser } = useAuth()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [selectedSubscription, setSelectedSubscription] = useState<
    string | null
  >(null)
  const [courses, setCourses] = useState<Course[]>([])
  const [learningPlans, setLearningPlans] = useState<Course[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState<'Courses' | 'Learning Plans'>(
    'Courses'
  )
  const [loadingSubscriptions, setLoadingSubscriptions] =
    useState<boolean>(false)
  const [loadingContent, setLoadingContent] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([])
  const [filteredLearningPlans, setFilteredLearningPlans] = useState<Course[]>(
    []
  )

  useEffect(() => {
    setLoadingSubscriptions(true)
    fetchSubscriptions().then((data) => {
      setSubscriptions(data)
      setLoadingSubscriptions(false)
    })
  }, [])

  useEffect(() => {
    if (selectedSubscription) {
      setLoadingContent(true)
      fetchSubContentAndStudents(selectedSubscription).then(({ courses }) => {
        const learningPlans = courses.filter(
          (course) => course.item_type === 'learning_plan'
        )
        const regularCourses = courses.filter(
          (course) => course.item_type === 'course'
        )

        setCourses(regularCourses)
        setLearningPlans(learningPlans)
        setLoadingContent(false)
      })
    }
  }, [selectedSubscription])

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase()
    setFilteredCourses(
      courses.filter(
        (course) =>
          course.item_name.toLowerCase().includes(lowerCaseQuery) ||
          course.item_code.toLowerCase().includes(lowerCaseQuery)
      )
    )
    setFilteredLearningPlans(
      learningPlans.filter(
        (plan) =>
          plan.item_name.toLowerCase().includes(lowerCaseQuery) ||
          plan.item_code.toLowerCase().includes(lowerCaseQuery)
      )
    )
  }, [searchQuery, courses, learningPlans])

  const handleSubscriptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSubscription(e.target.value)
    setSelectedCourses([])
  }

  const handleCourseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSelectedCourses((prev) =>
      prev.includes(value)
        ? prev.filter((id) => id !== value)
        : [...prev, value]
    )
  }

  const handleTabChange = (tab: 'Courses' | 'Learning Plans') => {
    setActiveTab(tab)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleEnroll = async () => {
    const arrCurrentUserId = [currentUser.id]
    if (selectedCourses.length > 0) {
      const enrollFunction =
        activeTab === 'Courses' ? enrollCourseStudents : enrollLPStudents
      const response = await enrollFunction(
        selectedSubscription,
        selectedCourses,
        arrCurrentUserId
      )
      if (response?.error) {
        toast({
          title: 'Uh Oh ... An unexpected error occurred.',
          description: response.error,
          variant: 'destructive',
        })
      } else {
        toast({
          title: 'Success!',
          description: 'Students successfully enrolled!.',
        })
        setSelectedCourses([])
      }
    } else {
      toast({
        description: 'Please select at least one course.',
        variant: 'destructive',
      })
    }
  }

  const isFormComplete = selectedCourses.length > 0

  return (
    <div className="container flex flex-col gap-4">
      <section className="w-full">
        <div className="p-4 border rounded bg-white">
          <label className="block mb-2 text-primary">
            Select Subscription:
          </label>
          {loadingSubscriptions ? (
            <p>Loading subscriptions...</p>
          ) : subscriptions.length > 0 ? (
            <select
              className="p-2 border border-gray-300 rounded w-full cursor-pointer"
              value={selectedSubscription || ''}
              onChange={handleSubscriptionChange}
            >
              <option value="" disabled>
                Select a subscription
              </option>
              {subscriptions.map((sub) => (
                <option key={sub.id} value={sub.id}>
                  {sub.plan_name} | {sub.plan_code}
                </option>
              ))}
            </select>
          ) : (
            <p>No subscriptions available.</p>
          )}
        </div>
      </section>

      {selectedSubscription && (
        <section className="w-full">
          {loadingContent ? (
            <p>Loading courses and learning plans...</p>
          ) : (
            <>
              <div className="flex gap-4 mt-4">
                <button
                  onClick={() => handleTabChange('Courses')}
                  className={`px-4 py-2 ${
                    activeTab === 'Courses'
                      ? 'bg-primary text-white rounded border-secondary'
                      : ''
                  }`}
                >
                  Courses
                </button>
                <button
                  onClick={() => handleTabChange('Learning Plans')}
                  className={`px-4 py-2 ${
                    activeTab === 'Learning Plans'
                      ? 'bg-primary text-white rounded border-secondary'
                      : ''
                  }`}
                >
                  Learning Plans
                </button>
              </div>
              <div className="w-full lg:w-1/2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search courses or learning plans"
                  className="p-2 border rounded w-full my-4"
                />
              </div>

              <TabContentNoStudents
                activeTab={activeTab}
                courses={filteredCourses}
                learningPlans={filteredLearningPlans}
                selectedCourses={selectedCourses}
                handleCourseChange={handleCourseChange}
              />
            </>
          )}
          <div className="flex mt-4">
            <MotionButton
              onClick={handleEnroll}
              disabled={!isFormComplete}
              className={`p-2 rounded ${
                isFormComplete
                  ? 'bg-secondary text-white'
                  : 'bg-gray-300 text-white cursor-not-allowed'
              }`}
            >
              Enroll
            </MotionButton>
          </div>
        </section>
      )}
    </div>
  )
}

export default EnrollUser
