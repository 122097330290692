const HowToManageLocations = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click the User Menu in the top right corner of the screen, the click{' '}
        <strong>Admin</strong>
      </li>
      <li>
        Click <strong>Manage Users</strong>
      </li>
      <li>Click the Branch you want to edit</li>
      <li>Click the user you want to edit</li>
    </ol>
  </div>
)

export default HowToManageLocations
