const HowToResetPassword = () => (
  <div>
    <ol className="list-decimal list-inside text-grays-dark space-y-4">
      <li>
        Visit{' '}
        <a className="underline text-blue-800" href="http://ctionline.com/">
          CTI Online
        </a>
      </li>
      <li>
        Click <strong>Sign In</strong>
      </li>
      <li>
        Click <strong>Forgot Password</strong>
      </li>
      <li>
        Enter your email address and click <strong>Reset Password</strong>
      </li>
      <li>
        You will receive an email with a link to reset your password (check your
        spam folder if you don't see the email)
      </li>
    </ol>
  </div>
)

export default HowToResetPassword
