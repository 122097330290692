import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'

import { loadSelfPacedCourses } from '../lib/services/courses'
import { Card, CardHeader, CardContent } from './Card'
import { priceForPlan } from '@/lib/priceConversions'
import useCartStore from '@/store/cart'
import { useToast } from './ui/use-toast'
import Product from '@/types/Product'
import { MotionButton } from './ui/motion-button'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function SelfPacedCourses() {
  const { t } = useTranslation('pages')
  const [courses, setCourses] = useState<Product[]>([])
  const { addItemToCart } = useCartStore()
  const { toast } = useToast()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState<Product | null>(null)
  const modalContentRef = useRef<HTMLDivElement | null>(null)

  const openModal = (course: Product) => {
    setSelectedCourse(course)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSelectedCourse(null)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        closeModal()
      }
    }

    if (modalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalOpen])

  useEffect(() => {
    const loadCourses = async () => {
      const data = (await loadSelfPacedCourses()) as Product[]

      const filteredData = data.filter(
        (product) => product.id != 'ADVOLT-TECHNET'
      )

      setCourses(filteredData)
    }
    loadCourses()
  }, [])

  const enroll = (plan: Product) => {
    const result = addItemToCart(plan)
    if (result.success) {
      toast({
        title: 'Added Plan to Cart',
        description: `Added plan: ${plan.name} to your cart`,
      })
    } else {
      toast({
        title: result.errors.base,
        variant: 'destructive',
      })
    }
  }

  return (
    <div>
      <div className="bg-grays-light p-6 w-full rounded-b flex flex-col text-grays-medium h-full border-x border-b border-grays-lightmedium shadow">
        <p className="text-grays-dark font-normal">
          CTI offers Self-Paced Online Training so you can train at a time and
          pace that meets your needs. Our comprehensive online catalog includes
          courses in technical training, business management, ASE test
          preparation and SP2 Safety/Pollution prevention presented in various
          eLearning formats including interactive online content, video courses,
          and simulator training. We also offer training in Spanish. Check out
          our{' '}
          <Link to="/catalog" className="text-primary hover:underline">
            Course Catalog
          </Link>{' '}
          to see all the online courses available from advanced vehicle
          technologies to chassis, steering, and suspension to diagnostics and
          more.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4">
        {courses.map((course, index) => (
          <Card key={`spc_${index}`}>
            <CardHeader>{course.name}</CardHeader>
            <p className="text-sm text-grays-dark mb-4 font-bold mt-2 text-center">
              {course.id}
            </p>
            <CardContent>
              <hr className="text-primary mt-4" />
              <p className="text-sm text-grays-dark mb-4 font-normal mt-4 text-center">
                {course.description}
              </p>
              <div className="w-auto px-4 mx-auto mb-4">
                <p className="text-md text-primary font-semibold my-2 text-center">
                  {priceForPlan(course)}
                </p>
              </div>
              {(course.id === 'OPUSS1-ONETIME' ||
                course.id === 'OPUSU-ONETIME') && (
                <button
                  className="hover:underline mb-2 text-primary"
                  onClick={() => openModal(course)}
                >
                  More Details
                </button>
              )}
              <MotionButton onClick={() => enroll(course)}>
                Add to cart
              </MotionButton>
            </CardContent>
          </Card>
        ))}
        <Modal
          className="text-black max-h-screen p-4 "
          isOpen={modalOpen}
          onRequestClose={() => closeModal()}
          closeTimeoutMS={500}
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0, 0.85)',
            },
          }}
        >
          <section className="w-full flex justify-end">
            <button className="text-2xl text-white transition ease-in-out delay-50 hover:scale-125">
              &times;
            </button>
          </section>
          {selectedCourse && (
            <section>
              <CardContent>
                <div
                  ref={modalContentRef}
                  className="modal-width flex flex-col m-auto bg-white shadow-lg p-4 rounded"
                >
                  <CardHeader>{selectedCourse.name}</CardHeader>
                  <p className="text-sm font-semibold text-grays-dark text-center mb-4">
                    {selectedCourse.id}
                  </p>
                  <hr className="text-primary" />
                  <p className="text-sm text-grays-dark mb-4 font-normal mt-4 text-center">
                    {selectedCourse.description}
                  </p>
                  {(selectedCourse.id === 'OPUSS1-ONETIME' ||
                    selectedCourse.id === 'OPUSU-ONETIME') && (
                    <p className="text-sm text-grays-dark mb-4 font-normal text-center">
                      *Subscription access valid for one (1) year after date of
                      purchase
                    </p>
                  )}
                  <div className="w-auto px-4 mx-auto mb-4">
                    <p className="text-md text-primary font-semibold my-2 text-center">
                      {priceForPlan(selectedCourse)}
                    </p>
                  </div>
                  <MotionButton
                    className="w-1/2 m-auto"
                    onClick={() => enroll(selectedCourse)}
                  >
                    Add to cart
                  </MotionButton>
                </div>
              </CardContent>
            </section>
          )}
        </Modal>
      </div>
    </div>
  )
}

export default SelfPacedCourses
