export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-900">
      <h1 className="text-6xl font-bold">404</h1>
      <p className="text-xl mt-4">
        Oops! The page you are looking for does not exist.
      </p>
      <a
        href="/"
        className="mt-6 px-6 py-3 text-white bg-primary hover:bg-primary/90 rounded-lg shadow-md transition-all"
      >
        Go Home
      </a>
    </div>
  )
}
