import { axiosInstance } from '../lib/services/axiosinstance'
import { API_URL } from '../lib/constants'
import { useAuth } from './useAuth'

const COURSE_ENROLLMENT_API_URL = `${API_URL}/user_api/v1/enrollments/courses`
const LP_ENROLLMENT_API_URL = `${API_URL}/user_api/v1/enrollments/learning_plans`
const SUBSCRIPTIONS_API_URL = `${API_URL}/user_api/v1/subscriptions`

export const useEnrollments = () => {
  const { getAuthHeaders } = useAuth()

  const fetchSubscriptions = async (subscriptionId?: string) => {
    try {
      const endpoint = subscriptionId
        ? `${SUBSCRIPTIONS_API_URL}?branch_id=${subscriptionId}`
        : SUBSCRIPTIONS_API_URL

      const response = await axiosInstance.get(endpoint, {
        headers: getAuthHeaders(),
      })

      if (response.status !== 200) {
        console.warn(
          `WARN: Couldn't load subscriptions: '${response.status}':'${response.statusText}'`
        )
        return []
      }

      return response.data
    } catch (error) {
      console.error('ERROR: Could not fetch subscriptions', error)
      return []
    }
  }

  const fetchSubContentAndStudents = async (subscriptionId?: string) => {
    try {
      const coursesEndpoint = subscriptionId
        ? `${SUBSCRIPTIONS_API_URL}/${subscriptionId}/courses_and_learning_plans`
        : `${SUBSCRIPTIONS_API_URL}/courses_and_learning_plans`

      const studentsEndpoint = `${SUBSCRIPTIONS_API_URL}/${subscriptionId}/students`

      const [coursesResponse, studentsResponse] = await Promise.all([
        axiosInstance.get(coursesEndpoint, {
          headers: getAuthHeaders(),
        }),
        axiosInstance.get(studentsEndpoint, {
          headers: getAuthHeaders(),
        }),
      ])

      if (coursesResponse.status !== 200 || studentsResponse.status !== 200) {
        console.warn(
          `WARN: Couldn't fetch data: Courses '${coursesResponse.status}':'${coursesResponse.statusText}', Students '${studentsResponse.status}':'${studentsResponse.statusText}'`
        )
        return { courses: [], students: [] }
      }

      return {
        courses: coursesResponse.data,
        students: studentsResponse.data,
      }
    } catch (error) {
      console.error('ERROR: Could not fetch courses and students', error)
      return { courses: [], students: [] }
    }
  }

  const enrollCourseStudents = async (
    selectedSubscription: string,
    courseIds: string[],
    studentIds: string[]
  ) => {
    try {
      const response = await axiosInstance.post(
        COURSE_ENROLLMENT_API_URL,
        {
          subscription_id: selectedSubscription,
          course_ids: courseIds,
          user_ids: studentIds,
        },
        { headers: getAuthHeaders() }
      )

      return response.data
    } catch (error) {
      console.error('ERROR: Could not enroll students', error)
      return { error: 'Enrollment failed' }
    }
  }

  const enrollLPStudents = async (
    selectedSubscription: string,
    learningPlanIds: string[],
    studentIds: string[]
  ) => {
    try {
      const response = await axiosInstance.post(
        LP_ENROLLMENT_API_URL,
        {
          subscription_id: selectedSubscription,
          learning_plan_ids: learningPlanIds,
          user_ids: studentIds,
        },
        { headers: getAuthHeaders() }
      )

      return response.data
    } catch (error) {
      console.error('ERROR: Could not enroll students', error)
      return { error: 'Enrollment failed' }
    }
  }

  return {
    fetchSubContentAndStudents,
    enrollCourseStudents,
    enrollLPStudents,
    fetchSubscriptions,
  }
}
