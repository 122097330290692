import Product from '@/types/Product'

export const priceInDollars = (priceInCents: number) => {
  const dollars = (priceInCents / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })
  return `$${dollars}`
}

export const priceForPlan = (plan: Product) => {
  const dollars = priceInDollars(plan.price)
  if (plan.period_unit === 'month' || plan.period_unit == 'year') {
    return `${dollars} / ${plan.period_unit}`
  }

  return dollars
}
