import React from 'react'

function CardContent({ children }) {
  return (
    <div className="w-full h-full flex flex-col justify-between">
      {children}
    </div>
  )
}

export default CardContent
