/* Docebo Course Structure */

import { convertToDate } from '@/lib/dateConversions'
import Product from './Product'

export type CourseType = 'ilt' | 'vilt' | 'olt' | 'all'

interface Instructor {
  firstname: string
  lastname: string
}

interface Location {
  id: number
  name: string
  address: string
}

export interface Event {
  date: string
  start_time: string
  end_time: string
  effective_duration: number
  timezone: string
}

export class EventImpl implements Event {
  date: string
  start_time: string
  end_time: string
  effective_duration: number
  timezone: string
  startDateTime: Date | null
  endDateTime: Date | null

  constructor(
    date: string,
    start_time: string,
    end_time: string,
    effective_duration: number,
    timezone: string
  ) {
    this.date = date
    this.start_time = start_time
    this.end_time = end_time
    this.effective_duration = effective_duration
    this.timezone = timezone

    this.startDateTime = convertToDate(date, start_time, timezone)
    this.endDateTime = convertToDate(date, end_time, timezone)
  }
}

export interface Session {
  uid: string
  code: string
  locations: Location[]
  events: Event[]
  instructors: Instructor[]
  num_enrolled: number
  max_enrollment: number
  is_private: boolean
}

export interface Course {
  id: string
  uid: string
  slug: string
  name: string
  course_type: CourseType
  duration_in_minutes: number
  code: string
  description: string
  sessions: Session[]
  plans: Product[]
}
