import { cn } from '@/lib/utils'

const ChargebeeErrorMessage = ({
  className,
  children,
  error,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & { error?: string }) => {
  const body = error || children

  if (!body) {
    return null
  }

  return (
    <p
      className={cn('text-sm font-medium text-destructive', className)}
      {...props}
    >
      {body}
    </p>
  )
}

export default ChargebeeErrorMessage
