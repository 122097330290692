import React, { useState, useEffect } from 'react'
import { usePaymentMethods } from '@/hooks/usePaymentMethods'
import { RadioGroup } from '@/components/ui/radio-group'
import { User } from '@/types/User'
import { PaymentType } from '@/types/CartTypes'
import PaymentItem from './PaymentItem'
import PaymentChangeWarning from './PaymentChangeWarning'
import { useSubscriptions } from '@/hooks/useSubscriptions'
import { useModal } from '@/hooks/useModal'
import Subscription from '@/types/Subscription'

interface Params {
  onSelectPaymentMethod: (
    paymentType: PaymentType,
    paymentSourceId: string
  ) => void
  currentUser: User
  paymentType: PaymentType | null
}

function SelectPaymentMethod({
  onSelectPaymentMethod,
  currentUser,
  paymentType,
}: Params) {
  const { listPaymentMethods, getChargeAccountDetails } = usePaymentMethods()
  const { isOpen, openModal, closeModal } = useModal()

  const [creditCards, setCreditCards] = useState([])
  const [chargeAccountDetails, setChargeAccountDetails] = useState(null)
  const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(
    paymentType
  )

  const [pendingSelection, setPendingSelection] = useState<{
    paymentType: PaymentType
    paymentSourceId?: string
  } | null>(null)
  const { listSubscriptions } = useSubscriptions()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchChargeAccountDetails = async () => {
      try {
        const details = await getChargeAccountDetails()
        setChargeAccountDetails(details)
      } catch (err) {
        console.error('Failed to fetch charge account details:', err)
      }
    }

    fetchChargeAccountDetails()
  }, [])

  const handleConfirmChange = () => {
    if (pendingSelection) {
      const { paymentType, paymentSourceId } = pendingSelection
      setSelectedPaymentType(
        paymentType === 'saved_card'
          ? `${paymentType}-${paymentSourceId}`
          : paymentType
      )
      onSelectPaymentMethod(paymentType, paymentSourceId)
      setPendingSelection(null)
    }
    closeModal()
  }

  const handleCancelChange = () => {
    setPendingSelection(null)
    closeModal()
  }

  const getCreditCards = async () => {
    const methods = await listPaymentMethods()
    setCreditCards(methods)
    const defaultMethod = methods.find((method) => method.is_default)
    if (defaultMethod) {
      selectPaymentType('saved_card', defaultMethod.id)
      onSelectPaymentMethod('saved_card', defaultMethod.id)
    }
  }

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const subs = await listSubscriptions()
        setSubscriptions(subs)
      } catch (err) {
        console.error('Failed to fetch subscriptions:', err)
        setError('Failed to fetch subscriptions. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchSubscriptions()
  }, [])

  useEffect(() => {
    getCreditCards()
  }, [])

  const formatAddress = (address) => {
    if (address) {
      return `${address.city}, ${address.state_code} ${address.zip}`
    } else {
      return ''
    }
  }

  const selectPaymentType = (
    paymentType: PaymentType,
    paymentSourceId?: string
  ) => {
    if (paymentType == 'saved_card') {
      setSelectedPaymentType(`${paymentType}-${paymentSourceId}`)
    } else {
      setSelectedPaymentType(paymentType)
    }
  }

  const onClick = (paymentType: PaymentType, paymentSourceId?: string) => {
    const newSelection =
      paymentType === 'saved_card'
        ? `${paymentType}-${paymentSourceId}`
        : paymentType

    // Check if the change requires confirmation
    if (
      (selectedPaymentType?.startsWith('charge_account') &&
        newSelection.startsWith('saved_card')) ||
      (selectedPaymentType?.startsWith('saved_card') &&
        newSelection.startsWith('charge_account'))
    ) {
      setPendingSelection({ paymentType, paymentSourceId })
      openModal()
    } else {
      setSelectedPaymentType(newSelection)
      onSelectPaymentMethod(paymentType, paymentSourceId)
    }
  }

  return (
    <div>
      <h2 className="text-lg font-medium text-gray-900 mb-2">
        Select your payment method
      </h2>
      <RadioGroup
        value={selectedPaymentType}
        className="flex flex-col space-y-2 items-start w-full"
      >
        {chargeAccountDetails &&
          Object.keys(chargeAccountDetails).length > 0 && (
            <PaymentItem
              htmlFor={'charge_account'}
              value={'charge_account'}
              onClick={() => onClick('charge_account')}
            >
              <span className="font-semibold first-letter:capitalize">
                AAP Account {chargeAccountDetails.aap_account_number} (Net
                terms: {chargeAccountDetails.net_term_days} days)
              </span>
            </PaymentItem>
          )}

        {/*
          We are not currently doing this, though maybe we do want to allow a quote?
          <PaymentItem
          htmlFor={'quote'}
          onClick={() => onClick('quote')}
          value={'quote'}
        >
          <span className="font-semibold first-letter:capitalize">
            Create a quote
          </span>
        </PaymentItem> */}
        {creditCards.map((paymentMethod) => {
          const card = paymentMethod.card
          const address = formatAddress(card.billing_address)

          return (
            <PaymentItem
              key={paymentMethod.id}
              htmlFor={paymentMethod.id}
              value={`saved_card-${paymentMethod.id}`}
              onClick={() => onClick('saved_card', paymentMethod.id)}
            >
              <span className="font-semibold first-letter:capitalize">
                {card.brand} ending in {card.last4}
              </span>
              <span className="text-gray-500">
                &bull; Expires {card.expiry_month}/{card.expiry_year}
                &bull; {card.first_name} {card.last_name} {address}
              </span>
            </PaymentItem>
          )
        })}
      </RadioGroup>
      <PaymentChangeWarning
        isOpen={isOpen}
        onClose={handleCancelChange}
        onConfirm={handleConfirmChange}
        subscriptions={subscriptions}
        isLoading={isLoading}
        error={error}
      />
    </div>
  )
}

export default SelectPaymentMethod
