import { useState, Fragment, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid'
import { MotionButton } from '../ui/motion-button'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import DropdownNavItem from './DropdownNavItem'
import {
  PencilIcon,
  UserCircleIcon,
  CogIcon,
} from '@heroicons/react/24/outline'
import { User } from '@/types/User'
import useCartStore from '@/store/cart'
import LinkToLMSButton from './LinkToLMSButton'

const sidebar = {
  open: {
    opacity: 1,
    height: 'auto',
    transition: { duration: 0.2 },
    display: 'block',
  },
  closed: {
    opacity: 0,
    height: '0px',
    transition: { duration: 0.2, delay: 0 },
    transitionEnd: { display: 'none' },
  },
}

const Dropdown = ({
  currentUser,
  signOut,
}: {
  currentUser: User | null
  signOut: (navigate: (path: string) => void, location: string) => void
}) => {
  const router = useLocation()
  const navigate = useNavigate()

  const { cartItems } = useCartStore()
  const [isTrainingOpen, setIsTrainingOpen] = useState(false)
  const [isAboutOpen, setIsAboutOpen] = useState(false)

  let trainingNav = 'nav-dropdown-link'
  let aboutNav = 'about-dropdown-link'
  let carrotColorTrain = '#2C2C2C'
  let carrotColorAbout = '#2C2C2C'

  const sumItems = (array) => {
    return array.reduce((total, obj) => total + (obj.quantity || 0), 0)
  }

  if (
    [
      '/virtualclassroom',
      '/onlinetraining',
      '/inpersontraining',
      '/managementtraining',
      '/monthlyschedule',
    ].includes(router.pathname)
  ) {
    trainingNav = 'active-nav-dropdown-link'
    carrotColorTrain = '#005CA7'
  }

  if (['/about', '/rdc'].includes(router.pathname)) {
    aboutNav = 'active-about-dropdown-link'
    carrotColorAbout = '#005CA7'
  }

  let timeoutId: NodeJS.Timeout | null = null

  const handleMouseEnter = (
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (timeoutId) clearTimeout(timeoutId)
    setOpen(true)
  }

  const handleMouseLeave = (
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    timeoutId = setTimeout(() => setOpen(false), 25)
  }

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    setIsTrainingOpen(false)
    setIsAboutOpen(false)
  }, [router])

  return (
    <>
      <nav className="w-full mx-auto flex justify-between nav-dropdown">
        <Link to="/">
          <img
            src="/images/CTI_WTI_Logo.png"
            alt="Carquest Technical Institute"
            className="w-36"
          />
        </Link>
        <div className="flex items-center justify-center gap-1">
          {/* <DropdownNavItem to={'/'} title="Home" /> */}
          <div
            className="nav-item nav-item-dropdown"
            onMouseEnter={() => handleMouseEnter(setIsTrainingOpen)}
            onMouseLeave={() => handleMouseLeave(setIsTrainingOpen)}
          >
            <button
              className={`text-xs md:text-base hover:text-primary nav-dropdown-link ${trainingNav}`}
              style={{
                borderBottom: isTrainingOpen
                  ? '2px solid rgb(0 92 167)'
                  : '2px solid transparent',
              }}
            >
              Training
            </button>
            <i className="inline-block pl-2">
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke={carrotColorTrain}
                  strokeWidth="2"
                />
              </svg>
            </i>
            <motion.ul
              className="dropdown-menu"
              variants={sidebar}
              animate={isTrainingOpen ? 'open' : 'closed'}
            >
              <li className="dropdown-menu-item text-left py-1.5">
                <Link
                  to="/destination"
                  className="text-sm p-4 nav-dropdown-link"
                >
                  Destination
                </Link>
              </li>
              <li className="dropdown-menu-item text-left py-1.5">
                <Link
                  to="/monthlyschedule"
                  className="text-sm p-4 nav-dropdown-link"
                >
                  Monthly Schedule
                </Link>
              </li>
            </motion.ul>
          </div>

          <DropdownNavItem to={'/catalog'} title="Catalog" />

          <div
            className="nav-item nav-item-dropdown"
            onMouseEnter={() => handleMouseEnter(setIsAboutOpen)}
            onMouseLeave={() => handleMouseLeave(setIsAboutOpen)}
          >
            <button
              className={`text-xs md:text-base hover:text-primary nav-dropdown-link ${aboutNav}`}
              style={{
                borderBottom: isAboutOpen
                  ? '2px solid rgb(0 92 167)'
                  : '2px solid transparent',
              }}
            >
              About Us
            </button>
            <i className="inline-block pl-2">
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke={carrotColorAbout}
                  strokeWidth="2"
                />
              </svg>
            </i>

            <motion.ul
              className="dropdown-menu"
              variants={sidebar}
              animate={isAboutOpen ? 'open' : 'closed'}
            >
              <li className="dropdown-menu-item text-left py-1.5">
                <Link to="/about" className="text-sm p-4 nav-dropdown-link">
                  About CTI
                </Link>
              </li>
              <li className="dropdown-menu-item text-left py-1.5">
                <Link to="/rdc" className="text-sm p-4 nav-dropdown-link">
                  R &#38; D Center
                </Link>
              </li>
              <li className="dropdown-menu-item text-left py-1.5">
                <Link
                  to="/instructors"
                  className="text-sm p-4 nav-dropdown-link"
                >
                  Meet Our Instructors
                </Link>
              </li>
              <li className="dropdown-menu-item text-left py-1.5">
                <Link
                  to="/contact-us"
                  className="text-sm p-4 nav-dropdown-link"
                >
                  Contact Us
                </Link>
              </li>
            </motion.ul>
          </div>
          <DropdownNavItem to={'/help-faq'} title="Help & FAQ" />
          {currentUser && <LinkToLMSButton />}
        </div>

        {/* Cart */}
        <div className="flex justify-center items-center">
          <Link to="/cart">
            <div className="relative">
              {cartItems.length > 0 && (
                <>
                  <div className="bg-secondary rounded-full text-xs text-white absolute -top-1 left-3 text-center w-4">
                    {sumItems(cartItems)}
                  </div>
                  <img
                    src={'/images/shopping_cart_full.svg'}
                    alt="cart"
                    className="h-6 w-6 mr-2"
                  />
                </>
              )}

              {cartItems.length === 0 && (
                <img
                  src={'/images/shopping_cart_empty.svg'}
                  alt="cart"
                  className="h-6 w-6 mr-2"
                />
              )}
            </div>
          </Link>
          {/* Profile dropdown */}
          {!currentUser && (
            <>
              <div className="signin-signup-item mr-6">
                <Link to={'/signin'}>Sign in</Link>
              </div>
              <div className="signin-signup last:pr-0">
                <MotionButton variant="secondary">
                  <Link to={'/signup'}>Sign up</Link>
                </MotionButton>
              </div>
            </>
          )}
          {currentUser && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex items-center gap-2 rounded-md mb-0 py-1.5 px-3 white/10 focus:outline-none">
                  <UserCircleIcon className="size-6" />
                </MenuButton>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems
                  className="absolute right-0 z-10 bg-menu/85 min-w-52 origin-top-right border-white/5
                    p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)]
                    focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                  <MenuItem>
                    <div className="divide-y divide-stroke divide-gray-200 overflow-hidden text-sm text-gray-200 px-1 py-1.5">
                      {currentUser.email}
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/profile/admin"
                      className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <PencilIcon className="size-4 fill-white/30" />
                      Admin
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/profile/settings"
                      className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <CogIcon className="size-4 fill-white/30" />
                      Settings
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <button
                      className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                      onClick={() => signOut(navigate, router.pathname)}
                    >
                      <ArrowRightStartOnRectangleIcon className="size-4" />
                      Signout
                    </button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          )}
        </div>
      </nav>
    </>
  )
}

export default Dropdown
