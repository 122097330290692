import React, { useState, useEffect, useRef } from 'react'

import CreditCardComponent from '@/components/ChargeBee/CreditCardComponent'
import ListCreditCards from '@/components/PaymentMethods/ListCreditCards'
import {
  PaymentMethod,
  ChargeAccountDetails,
  usePaymentMethods,
} from '@/hooks/usePaymentMethods'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { useAuth } from '@/hooks/useAuth'
import ShowChargeAccountDetails from '@/components/PaymentMethods/ShowChargeAccountDetails'
import CardNumber from '@/components/ChargeBee/CardNumber'
import CardExpiry from '@/components/ChargeBee/CardExpiry'
import CardCVV from '@/components/ChargeBee/CardCVV'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  BillingAddress,
  BillingAddressSchema,
  defaultBillingAddress,
} from '@/components/PaymentMethods/CreditCardSchema'

import { Input } from '@/components/ui/input'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import ChargebeeErrorMessage from '@/components/PaymentMethods/ChargebeeErrorMessage'
import { StatesDropdown } from '@/components/StatesDropdown/StatesDropdown'

function PaymentMethods() {
  const {
    listPaymentMethods,
    getChargeAccountDetails,
    createPaymentMethod,
    selectPaymentMethod,
    deletePaymentMethod,
  } = usePaymentMethods()
  const { currentUser } = useAuth()

  const cardRef = useRef(null)
  const [showCreditCardForm, setShowCreditCardForm] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [chargeBeeErrors, setChargeBeeErrors] = useState<string | null>(null)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [chargeAccountDetails, setChargeAccountDetails] =
    useState<ChargeAccountDetails | null>(null)
  const [loading, setLoading] = useState(true)

  // Fetch both payment methods and charge account details in parallel
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const [methods, chargeDetails] = await Promise.all([
          listPaymentMethods(),
          getChargeAccountDetails(),
        ])
        setPaymentMethods(methods)
        setChargeAccountDetails(chargeDetails)
      } catch (error) {
        console.error('Error fetching payment details:', error)
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  const setPrimary = async (paymentId: string) => {
    try {
      await selectPaymentMethod(paymentId)
      setPaymentMethods((prevMethods) =>
        prevMethods.map((method) =>
          method.id === paymentId
            ? { ...method, is_default: true }
            : { ...method, is_default: false }
        )
      )
      toast({ title: 'Payment method set as default' })
    } catch (error) {
      toast({
        title: 'Error setting default payment method',
        description: error.message,
        variant: 'destructive',
      })
    }
  }

  // Function to delete a payment method
  const deleteCard = async (paymentMethod: PaymentMethod) => {
    try {
      const res = await deletePaymentMethod(paymentMethod.id)
      if (res.error) {
        toast({
          title: `Could not delete credit card: ${paymentMethod.card.last4}`,
          variant: 'destructive',
          description: res.error.message,
        })
      } else {
        setPaymentMethods((prevMethods) =>
          prevMethods.filter((m) => m.id !== paymentMethod.id)
        )
        toast({ title: `Removed credit card: ${paymentMethod.card.last4}` })
      }
    } catch (error) {
      toast({
        title: 'Error deleting payment method',
        description: error.message,
        variant: 'destructive',
      })
    }
  }

  const form = useForm<BillingAddress>({
    resolver: zodResolver(BillingAddressSchema),
    defaultValues: defaultBillingAddress(selectedPaymentMethod?.card || {}),
  })

  const onEditPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    setShowCreditCardForm(true)
  }

  const onAddNewCard = () => {
    setSelectedPaymentMethod(null)
    setShowCreditCardForm(true)
  }

  const closeCardForm = () => {
    setSelectedPaymentMethod(null)
    setShowCreditCardForm(false)
  }

  async function onSubmit(values: BillingAddress) {
    if (!cardRef.current) {
      setChargeBeeErrors(
        'Sorry, there was an issue validating the credit card with our processor.'
      )
      return
    }

    const additionalData = { ...values, email: currentUser.email }
    const tokenData = await cardRef.current.tokenize(additionalData)
    if (!tokenData.token) {
      setChargeBeeErrors(
        'Sorry, there was an issue validating the credit card with our processor.'
      )
      return
    }

    const result = await createPaymentMethod(tokenData, values)
    if (result.error) {
      toast({
        title: 'Error adding your credit card',
        description: result.error.message,
        variant: 'destructive',
      })
    } else {
      toast({ title: 'Payment method saved successfully' })
      setSelectedPaymentMethod(null)
      setShowCreditCardForm(false)
      try {
        const updatedMethods = await listPaymentMethods()
        setPaymentMethods(updatedMethods)
      } catch (error) {
        console.error('Error refreshing payment methods:', error)
      }
    }
  }

  if (loading) {
    return <p>Loading payment details...</p>
  }

  return (
    <div className="flex flex-col justify-center items-center space-y-4 w-full m-auto">
      {/* Charge Account Details */}
      <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
        Your Credit Cards
      </h3>

      {/* List Credit Cards */}
      <ListCreditCards
        paymentMethods={paymentMethods}
        onEditPaymentMethod={onEditPaymentMethod}
        onSetPrimary={setPrimary}
        onDeleteCard={deleteCard}
      />

      {chargeAccountDetails && Object.keys(chargeAccountDetails).length > 0 && (
        <>
          <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            Other
          </h3>
          <ShowChargeAccountDetails
            chargeAccountDetails={chargeAccountDetails}
          />
        </>
      )}

      {/* Add a New Card Button */}
      <div className="w-full h-full flex flex-col items-center">
        <Button onClick={onAddNewCard} className="mb-4 w-64">
          Add a new card
        </Button>

        {/* Credit Card Form */}
        {showCreditCardForm && (
          <Form {...form}>
            <ChargebeeErrorMessage error={chargeBeeErrors} />
            {selectedPaymentMethod && (
              <h2 className="text-red-500 text-sm">
                For your security please re-enter your credit card information
              </h2>
            )}
            <form onSubmit={form.handleSubmit(onSubmit)} className="container">
              <CreditCardComponent
                paymentMethod={selectedPaymentMethod}
                ref={cardRef}
              >
                <div className="grid grid-cols-4 gap-x-4 gap-y-2">
                  <div className="col-span-4">
                    <CardNumber
                      id="number"
                      label="Card Number"
                      errorText="Please enter a valid card number"
                      invalid={false}
                      setInvalid={(val: boolean) => {
                        console.log('setting cardnumber valid', val)
                      }}
                    />
                  </div>

                  <div className="col-span-2">
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2">
                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="col-span-2">
                    <CardExpiry
                      id="expiry"
                      label="Card Expiry"
                      errorText="Please enter a valid card number"
                      invalid={false}
                      setInvalid={(val: boolean) => {
                        console.log('setting cardnumber valid', val)
                      }}
                    />
                  </div>
                  <div className="col-span-2">
                    <CardCVV
                      id="cvv"
                      label="Card CVV"
                      errorText="Please enter a valid card number"
                      invalid={false}
                      setInvalid={(val: boolean) => {
                        console.log('setting cardnumber valid', val)
                      }}
                    />
                  </div>
                  <div className="col-span-4">
                    <FormField
                      control={form.control}
                      name="addressLine1"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-4">
                    <FormField
                      control={form.control}
                      name="addressLine2"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Extended Address</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2">
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="col-span-1">
                    <FormField
                      control={form.control}
                      name="stateCode"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State</FormLabel>
                          <StatesDropdown
                            onChange={field.onChange}
                            value={field.value}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="col-span-1">
                    <FormField
                      control={form.control}
                      name="zip"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Zip Code</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                <div className="w-full mt-4 flex justify-center gap-2">
                  <Button type="submit" className="w-64">
                    Save
                  </Button>
                  <Button
                    onClick={closeCardForm}
                    type="button"
                    className="w-64"
                  >
                    Close
                  </Button>
                </div>
              </CreditCardComponent>
            </form>
          </Form>
        )}
      </div>
    </div>
  )
}

export default PaymentMethods
