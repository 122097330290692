/**
 * Most of this is based on the tutorial found here:
 *  https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import HTTPBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HTTPBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false, // process.env.NODE_ENV === 'development',
    ns: ['common', 'pages'],
    defaultNS: 'common',

    // only look for en not en_US etc
    load: 'languageOnly',
    // Fallback namespace if a key isn't found. Can be a string or an array
    // of namespaces. For more details see: https://www.i18next.com/how-to/backend-fallback
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
