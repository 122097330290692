import { useAuth } from '@/hooks/useAuth'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'

const menuItems = [
  // { id: 'edit-user', label: 'Edit User', path: 'edituser' },
  { id: 'password', label: 'Manage Password', path: 'password' },
  { id: 'subscriptions', label: 'My Subscriptions', path: 'subscriptions' },
  {
    id: 'payment-methods',
    label: 'Payments Methods',
    path: 'payment-methods',
  },
  {
    id: 'invoices',
    label: 'Invoices',
    path: 'invoices',
  },
]

const Settings = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated } = useAuth()

  // Extract the current path (e.g., 'subscriptions', 'manageusers', 'enroll')
  const currentPath = location.pathname.split('/').pop()

  return (
    <div className="flex flex-col md:flex-row container mt-6 mb-6 gap-2 items-start">
      {/* Left menu section */}
      <nav className="w-52 border rounded border-gray-300 p-4 md:w-auto">
        <ul className="space-y-1">
          {menuItems.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => navigate(item.path)}
                className={`w-full text-left p-2 pl-4 rounded ${
                  currentPath === item.path
                    ? 'bg-primary text-white'
                    : 'bg-white text-gray-900 hover:bg-gray-200'
                }`}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Content section */}
      <div className="flex flex-col border rounded border-gray-300 p-6 w-full">
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
