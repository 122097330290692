import React from 'react'
import { hasTechnetProduct } from '@/lib/technet'
import Product from '@/types/Product'
import { User } from '@/types/User'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

const QuoteCard = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <Card className="p-6">
      <CardTitle className="mb-2">{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </Card>
  )
}
/**
 * TODO: Add some styling to this component
 * @param user User
 * @param products Product[]
 * @returns JSXElement detailing the reason for a quote
 */
function QuoteReason({ user, products }: { user: User; products: Product[] }) {
  if (hasTechnetProduct(products) && !user.is_technet_member) {
    const description =
      'You have selected TechNet Pricing for one of your products. We will create a quote and call you to confirm your Technet membership.'
    return (
      <QuoteCard
        title={'We need to verify your TechNet account'}
        description={description}
      />
    )
  }

  if (user.is_cqi_store) {
    const description =
      'We will create a quote for this order and get back to you.'
    return (
      <QuoteCard
        title={'We will create a quote for your CQI Store'}
        description={description}
      />
    )
  }

  return null
}

export default QuoteReason
