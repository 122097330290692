import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'

function Destination() {
  const { t } = useTranslation('pages')
  return (
    <div className="grow py-8 px-4 sm:px-12 container mx-auto shadow-md flex gap-8 flex-col main-container-height">
      <div className="sm:w-full">
        <h2 className="text-grays-dark text-2xl font-bold mb-4">
          {t('destination.title')}
        </h2>
        <div className="w-full flex flex-col md:flex-row justify-center mt-6 gap-8">
          <div className="">
            <h2 className="mb-2 font-semibold text-primary">
              All Inclusive White Glove Experience: $2,750
            </h2>
            <ul className="list-disc list-inside mb-6 leading-6">
              <li className="mb-2">
                Airfare: to and from Raleigh/Durham Airport
              </li>
              <li className="mb-2">
                Lodging: 3 nights stay hotel accommodations in Raleigh, NC
              </li>
              <li className="mb-2">
                Training: 2 1/2 days of Instructor-Led, Hands-On Training
              </li>
              <li className="mb-2">
                Location: CTI's Research & Development Center (RDC)
              </li>
              <li className="mb-2">
                Transportation: to and from RDU airport, Hotel, CTI RDC, Dinners
                and Events
              </li>
              <li className="mb-2">
                Meals: Breakfast, Lunches, Dinners and Snacks provided
              </li>
              <li className="mb-2">Welcome Happy Hour: Night of Arrival</li>
              <li className="mb-2">
                Special Offsite Event: Cocktails, Appetizers, Dinner &
                Entertainment
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-2 font-semibold text-primary">
              2025 Dates and Courses
            </h2>
            <ul className="text-spacing leading-6">
              <li className="mb-2">
                <p className="inline mb-2 mr-2">
                  <strong>May 13 - 16:</strong> Getting Aligned with ADAS{' '}
                </p>
                <a
                  href="https://na.eventscloud.com/cti-adas-may"
                  target="_blank"
                  className="text-primary hover:text-white hover:bg-primary border px-2 py-1 border-primary rounded"
                >
                  Register Now
                </a>
              </li>
              <li className="mb-2">
                <strong>June 24 - 27:</strong> Powering Up Your xEV Services
              </li>
              <li className="mb-2">
                <strong>July 22 - 25:</strong> Getting Aligned with ADAS{' '}
              </li>
              <li className="mb-2">
                <strong>August 19 - 22:</strong> Powering Up Your xEV Services
              </li>
              <li className="mb-2">
                <strong>September 16 - 19:</strong> ADAS Troubleshooting and
                Diagnostics
              </li>
              <li className="mb-2">
                <strong>October 21 - 24:</strong> ADAS Troubleshooting and
                Diagnostics
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="mb-4 w-3/4">
          <img
            src="/images/Destination.png"
            className=" h-full object-contain"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Destination
