import HowToEnroll from './faq_answers/HowToEnroll'
import HowToAccessCerts from './faq_answers/HowToAccessCerts'
import HowToDeactivateUsers from './faq_answers/HowToDeactiveUsers'
import HowToEnrollOLT from './faq_answers/HowToEnrollOLT'
import HowToEnrollUsers from './faq_answers/HowToEnrollUsers'
import HowToManageLocations from './faq_answers/HowToManageLocations'
import HowToModifyUsers from './faq_answers/HowToModifyUsers'
import HowToMoveBranches from './faq_answers/HowToMoveBranches'
import HowToResetPassword from './faq_answers/HowToResetPassword'
import HowToRunReports from './faq_answers/HowToRunReports'
import HowToStartClass from './faq_answers/HowToStartClass'
import HowToUpdatePayment from './faq_answers/HowToUpdatePayment'
import HowToViewSchedule from './faq_answers/HowToViewSchedule'

const faqDocs = {
  'General Users': [
    {
      question: 'How to reset password',
      answer: <HowToResetPassword />,
    },
    {
      question: 'How to enroll in a Virtual class or In-Person class',
      answer: <HowToEnroll />,
    },
    {
      question: 'How to start an online class',
      answer: <HowToStartClass />,
    },
    {
      question: 'How to access certificates and transcripts',
      answer: <HowToAccessCerts />,
    },
    {
      question:
        'How to view the Virtual Classroom or In-person Instructor-Led schedule',
      answer: <HowToViewSchedule />,
    },
  ],
  'Shop Owners and Managers': [
    {
      question: 'How to add users or modify existing user profiles',
      answer: <HowToModifyUsers />,
    },
    {
      question:
        'How to enroll users in Online, Virtual Classroom or In-Person Classes',
      answer: <HowToEnrollUsers />,
    },
    {
      question: 'How to deactivate users',
      answer: <HowToDeactivateUsers />,
    },
    {
      question: 'How to manage multiple locations and users',
      answer: <HowToManageLocations />,
    },
    {
      question: 'How to move users between branches',
      answer: <HowToMoveBranches />,
    },
    // {
    //   question: 'How to run reports',
    //   answer: <HowToRunReports />,
    // },
    {
      question: 'How to update payment details',
      answer: <HowToUpdatePayment />,
    },
  ],
}

export default faqDocs
