import { EventImpl } from '@/types/Course'
import React from 'react'

function EventDate({
  event,
  className = '',
}: {
  event: EventImpl
  className?: string
}) {
  return (
    <p className={`font-normal text-grays-dark ${className}`}>
      {event.startDateTime.toLocaleDateString()} |{' '}
      {event.startDateTime.toLocaleTimeString(navigator.language, {
        timeStyle: 'long',
      })}{' '}
      -{' '}
      {event.endDateTime.toLocaleTimeString(navigator.language, {
        timeStyle: 'long',
      })}
    </p>
  )
}

export default EventDate
