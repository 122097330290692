import { PhoneIcon, ClockIcon } from '@heroicons/react/24/outline'

function ContactUs() {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex-col items-center justify-center mb-4 text-grays-dark">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            <h2 className="font-heading mb-4 font-bold tracking-tight text-grays-dark text-3xl sm:text-4xl">
              Get in Touch
            </h2>
            {/* <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
              Please feel free to contact us using one of the methods below.
            </p> */}
          </div>
          <div className="flex items-stretch justify-center">
            {/* <div className="grid md:grid-cols-2"> */}
            <div className="h-full pr-6">
              <ul className="mb-6 md:mb-0">
                <li className="flex mb-2">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-gray-700 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6 text-grays-dark">
                      Our Address
                    </h3>
                    <p>4200 Six Forks Rd</p>
                    <p>Raleigh, NC 27609</p>
                  </div>
                </li>
                <li className="flex mb-2">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-gray-700 text-gray-50">
                    <PhoneIcon className="h-6" />
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6">
                      Billing Issues
                    </h3>
                    <p>Phone: +1 855-770-6686</p>
                    <p>
                      Mail:{' '}
                      <a href="mailto:solutionsbilling@advance-auto.com">
                        solutionsbilling@advance-auto.com
                      </a>
                    </p>
                    <h3 className="mt-4 mb-2 text-lg font-medium leading-6">
                      Everything Else
                    </h3>
                    <p>
                      Mail:{' '}
                      <a href="mailto:ctisupport@carquest.com">
                        ctisupport@carquest.com
                      </a>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-gray-700 text-gray-50">
                    <ClockIcon className="w-6" />
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6">
                      Working hours
                    </h3>
                    <p>Monday - Friday: 8:00am - 5:00pm EST</p>
                    {/* <p>Saturday &amp; Sunday: 08:00 - 12:00</p> */}
                  </div>
                </li>
              </ul>
              {/* </div> */}
              {/* TODO: Do we want a form? <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                <h2 className="mb-4 text-2xl font-bold dark:text-white">
                  Ready to Get Started?
                </h2>
                <form id="contactForm">
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          for="name"
                          className="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="text"
                          id="name"
                          autocomplete="given-name"
                          placeholder="Your name"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="name"
                        />
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          for="email"
                          className="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="email"
                          id="email"
                          autocomplete="email"
                          placeholder="Your email address"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        for="textarea"
                        className="pb-1 text-xs uppercase tracking-wider"
                      ></label>
                      <textarea
                        id="textarea"
                        name="textarea"
                        cols="30"
                        rows="5"
                        placeholder="Write your message..."
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md sm:mb-0"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
