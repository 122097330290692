import React, { useState, useEffect } from 'react'
import { MotionButton } from '../../components/ui/motion-button'
import { useEnrollments } from '../../hooks/enrollment'
import { useUsers } from '../../hooks/users'
import TabContent from '@/components/Tabs/TabContent'
import { toast } from '../../components/ui/use-toast'

interface Subscription {
  id: string
  name: string
  bundle_code: string
  plan_name: string
  plan_code: string
}

interface Course {
  item_id: string
  item_name: string
  item_code: string
  type: number
}

interface Student {
  id: string
  first_name: string
  last_name: string
}

interface Branch {
  id: string
  name: string
  code: string
}

interface BranchList {
  branch: Branch
  children: Branch[]
}

const Enroll: React.FC = () => {
  const { loadBranches } = useUsers()
  const {
    fetchSubContentAndStudents,
    enrollCourseStudents,
    enrollLPStudents,
    fetchSubscriptions,
  } = useEnrollments()

  const [branchData, setBranchData] = useState<BranchList | null>(null)
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [selectedSubscription, setSelectedSubscription] = useState<
    string | null
  >(null)
  const [courses, setCourses] = useState<Course[]>([])
  const [learningPlans, setLearningPlans] = useState<Course[]>([])
  const [students, setStudents] = useState<Student[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])
  const [selectedStudents, setSelectedStudents] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState<'Courses' | 'Learning Plans'>(
    'Courses'
  )
  const [history, setHistory] = useState<Branch[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([])
  const [filteredLearningPlans, setFilteredLearningPlans] = useState<Course[]>(
    []
  )
  const [searchQueryStudents, setSearchQueryStudents] = useState<string>('')
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([])
  const [loadingBranches, setLoadingBranches] = useState<boolean>(false)
  const [loadingSubscriptions, setLoadingSubscriptions] =
    useState<boolean>(false)
  const [loadingContent, setLoadingContent] = useState<boolean>(false)

  useEffect(() => {
    setLoadingBranches(true)
    loadBranches().then((data) => {
      setBranchData(data)
      setSelectedBranch(data.branch)
      setLoadingBranches(false)
    })
    setLoadingSubscriptions(true)
    fetchSubscriptions().then((data) => {
      setSubscriptions(data)
      setLoadingSubscriptions(false)
    })
  }, [])

  useEffect(() => {
    if (selectedBranch) {
      setLoadingSubscriptions(true)
      fetchSubscriptions(selectedBranch.id).then((data) => {
        setSubscriptions(data)
        setLoadingSubscriptions(false)
      })
    }
  }, [selectedBranch])

  useEffect(() => {
    if (selectedSubscription) {
      setLoadingContent(true)
      fetchSubContentAndStudents(selectedSubscription).then(
        ({ courses, students }) => {
          const learningPlans = courses.filter(
            (course) => course.item_type === 'learning_plan'
          )
          const filteredCourses = courses.filter(
            (course) => course.item_type === 'course'
          )

          setCourses(filteredCourses)
          setLearningPlans(learningPlans)
          setFilteredCourses(filteredCourses)
          setFilteredLearningPlans(learningPlans)
          setStudents(students)

          if (filteredCourses.length > 0) {
            setActiveTab('Courses')
          } else if (learningPlans.length > 0) {
            setActiveTab('Learning Plans')
          } else {
            setActiveTab('Courses')
          }

          setLoadingContent(false)
        }
      )
    }
  }, [selectedSubscription])

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase()
    setFilteredCourses(
      courses.filter(
        (course) =>
          course.item_name.toLowerCase().includes(lowerCaseQuery) ||
          course.item_code.toLowerCase().includes(lowerCaseQuery)
      )
    )
    setFilteredLearningPlans(
      learningPlans.filter(
        (plan) =>
          plan.item_name.toLowerCase().includes(lowerCaseQuery) ||
          plan.item_code.toLowerCase().includes(lowerCaseQuery)
      )
    )
  }, [searchQuery, courses, learningPlans])

  useEffect(() => {
    const lowerCaseQuery = searchQueryStudents.toLowerCase()
    setFilteredStudents(
      students.filter(
        (student) =>
          student.first_name.toLowerCase().includes(lowerCaseQuery) ||
          student.last_name.toLowerCase().includes(lowerCaseQuery)
      )
    )
  }, [searchQueryStudents, students])

  const handleBranchSelect = (branch: Branch) => {
    if (selectedBranch) {
      setHistory((prevHistory) => [...prevHistory, selectedBranch])
    }
    setLoadingBranches(true)
    setLoadingSubscriptions(true)
    setSelectedSubscription(null)
    loadBranches(branch.id).then((data) => {
      setBranchData(data)
      setSelectedBranch(data.branch)
      setLoadingBranches(false)
      setLoadingSubscriptions(false)
    })
  }

  const handleBack = () => {
    if (history.length > 0) {
      setLoadingBranches(true)
      const previousBranch = history[history.length - 1]
      setHistory((prevHistory) => prevHistory.slice(0, -1))
      setSelectedBranch(previousBranch)
      setSelectedSubscription(null)
      loadBranches(previousBranch.id).then((data) => {
        setBranchData(data)
      })
      setLoadingBranches(false)
    }
  }

  const handleSubscriptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSubscription(e.target.value)
    setSelectedCourses([])
    setSelectedStudents([])
  }

  const handleTabChange = (tab: 'Courses' | 'Learning Plans') => {
    setActiveTab(tab)
  }

  const handleCourseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSelectedCourses((prev) =>
      prev.includes(value)
        ? prev.filter((id) => id !== value)
        : [...prev, value]
    )
  }

  const handleStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSelectedStudents((prev) =>
      prev.includes(value)
        ? prev.filter((id) => id !== value)
        : [...prev, value]
    )
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleEnroll = async () => {
    if (selectedCourses.length > 0 && selectedStudents.length > 0) {
      const enrollFunction =
        activeTab === 'Courses' ? enrollCourseStudents : enrollLPStudents

      try {
        const response = await enrollFunction(
          selectedSubscription,
          selectedCourses,
          selectedStudents
        )

        if (response?.error) {
          toast({
            description: response.error,
            variant: 'destructive',
          })
        } else {
          setSelectedCourses([])
          setSelectedStudents([])
          toast({
            title: 'Success!',
            description: 'Students successfully enrolled!',
          })
        }
      } catch (error) {
        console.error('Enrollment failed:', error)
        toast({
          title: 'Uh Oh!',
          description: 'An unexpected error occurred. Please try again.',
          variant: 'destructive',
        })
      }
    } else {
      toast({
        description: 'Please select at least one course and one student.',
        variant: 'destructive',
      })
    }
  }

  const isFormComplete =
    selectedCourses.length > 0 && selectedStudents.length > 0

  return (
    <div className="flex flex-col lg:flex-row container gap-4 items-start">
      <section className="w-full lg:w-1/4">
        <div className="p-4 border rounded bg-white">
          <div className="flex justify-between items-start">
            <h2 className="font-medium text-primary mb-4">Branch Navigation</h2>
            {history.length > 0 && (
              <button
                onClick={handleBack}
                className="text-primary hover:underline cursor-pointer"
              >
                &larr; Back
              </button>
            )}
          </div>

          {loadingBranches ? (
            <p>Loading branches...</p>
          ) : branchData ? (
            <>
              <button
                onClick={() => handleBranchSelect(branchData.branch)}
                className={`p-2 text-left w-full ${
                  selectedBranch?.id === branchData.branch.id
                    ? 'bg-primary rounded text-white'
                    : ''
                }`}
              >
                {branchData.branch.name}
              </button>
              <div className="mt-2 pl-4 flex flex-col gap-1 overflow-auto max-h-96">
                {branchData.children.map((child) => (
                  <button
                    key={child.id}
                    onClick={() => handleBranchSelect(child)}
                    className={`p-2 text-left font-normal w-full rounded border hover:bg-gray-100 ${
                      selectedBranch?.id === child.id
                        ? 'bg-primary text-white'
                        : ''
                    }`}
                  >
                    {child.name}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p>No branch data available.</p>
          )}
        </div>
      </section>

      {selectedBranch && (
        <section className="w-full lg:w-1/4">
          <div className="p-4 border rounded bg-white">
            <label className="block mb-2 text-primary">
              Select Subscription:
            </label>
            {loadingSubscriptions ? (
              <p>Loading subscriptions...</p>
            ) : subscriptions.length > 0 ? (
              <select
                className="p-2 border border-gray-300 rounded w-full cursor-pointer"
                value={selectedSubscription || ''}
                onChange={handleSubscriptionChange}
              >
                <option value="" disabled>
                  Select a subscription
                </option>
                {subscriptions.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.plan_name} | {sub.plan_code}
                  </option>
                ))}
              </select>
            ) : (
              <p>No subscriptions available.</p>
            )}
          </div>
        </section>
      )}

      {selectedSubscription && (
        <section className="w-full lg:w-1/2">
          {loadingContent ? (
            <p>Loading courses and students...</p>
          ) : courses.length > 0 && learningPlans.length > 0 ? (
            <>
              <div className="flex gap-4 mt-4">
                <button
                  onClick={() => handleTabChange('Courses')}
                  className={`px-4 py-2 ${
                    activeTab === 'Courses'
                      ? 'bg-primary text-white rounded border-secondary'
                      : ''
                  }`}
                >
                  Courses
                </button>
                <button
                  onClick={() => handleTabChange('Learning Plans')}
                  className={`px-4 py-2 ${
                    activeTab === 'Learning Plans'
                      ? 'bg-primary text-white rounded border-secondary'
                      : ''
                  }`}
                >
                  Learning Plans
                </button>
              </div>
              <div className="w-full lg:w-1/2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search courses or learning plans"
                  className="p-2 border rounded w-full mb-4"
                />
              </div>

              <TabContent
                activeTab={activeTab}
                courses={filteredCourses}
                learningPlans={filteredLearningPlans}
                students={filteredStudents}
                handleCourseChange={handleCourseChange}
                handleStudentChange={handleStudentChange}
                selectedCourses={selectedCourses}
                selectedStudents={selectedStudents}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                searchQueryStudents={searchQueryStudents}
                setSearchQueryStudents={setSearchQueryStudents}
              />
            </>
          ) : (
            <TabContent
              activeTab={courses.length > 0 ? 'Courses' : 'Learning Plans'}
              courses={filteredCourses}
              learningPlans={filteredLearningPlans}
              students={filteredStudents}
              handleCourseChange={handleCourseChange}
              handleStudentChange={handleStudentChange}
              selectedCourses={selectedCourses}
              selectedStudents={selectedStudents}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchQueryStudents={searchQueryStudents}
              setSearchQueryStudents={setSearchQueryStudents}
            />
          )}
          <div className="flex mt-4">
            <MotionButton
              onClick={handleEnroll}
              disabled={!isFormComplete}
              className={`p-2 rounded ${
                isFormComplete
                  ? 'bg-secondary text-white'
                  : 'bg-gray-300 text-white cursor-not-allowed'
              }`}
            >
              Enroll Students
            </MotionButton>
          </div>
        </section>
      )}
    </div>
  )
}

export default Enroll
