import NoSearchResultsCard from './Search/noSearchResultsCard'
import SearchForm from './Search/SearchForm'
import SearchResults from './Search/searchResults'
import { INITIAL_SEARCH_FILTERS, SearchFilterProps } from '../hooks/search'
import { useTranslation } from 'react-i18next'

function InstructorLedCourses({
  searchParams,
  instructorList,
  searchResults,
  onSearch,
  onReset,
  activeCourseType,
}) {
  const { t } = useTranslation('pages')
  const convertParamsToFilters = (
    params: URLSearchParams
  ): SearchFilterProps => {
    return {
      ...INITIAL_SEARCH_FILTERS,
      ...Object.fromEntries(params.entries()),
    } as SearchFilterProps
  }
  return (
    <>
      <div className="bg-grays-light p-6 w-full rounded-b flex flex-col text-grays-dark h-full border-x border-b border-grays-lightmedium shadow">
        {t('inperson_training.description')}
      </div>
      <div className="sm:flex w-full mt-4">
        <div className="sm:mr-4 items-stretch basis-auto sm:basis-1/2 lg:basis-1/3">
          <SearchForm
            onReset={onReset}
            onSearch={onSearch}
            instructorList={instructorList}
            initialValues={convertParamsToFilters(searchParams)}
            activeCourseType={activeCourseType}
          />
        </div>
        <div
          className="frontpage-image self-end hidden basis-0 rounded-sm sm:block sm:basis-1/2 lg:basis-2/3"
          role="img"
          aria-label="mechanics diagnosing car"
        ></div>
      </div>
      <section className="container mx-auto mt-4">
        <div className="flex justify-between">
          <h4 className="text-sm text-secondary font-medium mb-2">
            {searchResults.length} Results Found
          </h4>
          <button
            type="button"
            className="text-primary hover:underline rounded text-sm mb-2"
            onClick={onReset}
          >
            Clear Search
          </button>
        </div>
        <div className="max-h-320 overflow-x-auto rounded-sm border-y border-grays-mediumlight">
          <div>
            {searchResults.length ? (
              <SearchResults results={searchResults} />
            ) : (
              <NoSearchResultsCard />
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default InstructorLedCourses
