import { MotionButton } from '../ui/motion-button'
import { useLMSRedirect } from '@/hooks/useLMSRedirect'

function LinkToLMSButton() {
  const { handleRedirect, loading } = useLMSRedirect()

  return (
    <MotionButton
      variant="secondary"
      className="font-semibold mx-4"
      onClick={handleRedirect}
      disabled={loading}
    >
      {loading ? 'Redirecting...' : 'Access Your Training'}
    </MotionButton>
  )
}

export default LinkToLMSButton