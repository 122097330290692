import '@/config/sentry' // Sentry needs to be imported first

import * as React from 'react'
import { createRoot } from 'react-dom/client'

import './styles/index.scss'
import './styles/nav.scss'

import App from './app/App'
// import { enableMocking } from './testing/mocks'

const root = document.getElementById('root')
if (!root) {
  throw new Error('No root element found')
}

// enableMocking().then(() => {
createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
// })
