import React from 'react'

interface Course {
  item_id: string
  item_name: string
  item_code: string
  type: number
}

interface TabContentNoStudentsProps {
  activeTab: 'Courses' | 'Learning Plans'
  courses: Course[]
  learningPlans: Course[]
  selectedCourses: string[]
  handleCourseChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TabContentNoStudents: React.FC<TabContentNoStudentsProps> = ({
  activeTab,
  courses,
  learningPlans,
  selectedCourses,
  handleCourseChange,
}) => {
  const displayItems = activeTab === 'Courses' ? courses : learningPlans

  return (
    <div className="p-4 border rounded bg-white max-h-96 overflow-auto">
      {displayItems.length > 0 ? (
        <div className="flex flex-col gap-2">
          {displayItems.map((item) => (
            <label key={item.item_id} className="block">
              <input
                className="mr-2"
                type="checkbox"
                value={item.item_id}
                onChange={handleCourseChange}
                defaultChecked={selectedCourses.includes(item.item_id)}
              />
              {item.item_name}
            </label>
          ))}
        </div>
      ) : (
        <p>No {activeTab.toLowerCase()} available.</p>
      )}
    </div>
  )
}

export default TabContentNoStudents
