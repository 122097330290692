import { motion } from 'framer-motion'
import { ScheduleCard, CardContent } from '@/components/Card'
import { PrintSchedule } from '../components/Print/printSchedule'
import { useContext, useEffect, useState, useRef } from 'react'
import SearchFilterContext from '../hooks/search'
import { months, formatBulletPoints } from '../lib/helpers'
import PrintIcon from '../../public/images/print-icon.svg'
import { convertTime } from '@/lib/dateConversions'
import EventDate from '@/components/Search/EventDate'

function Schedule() {
  const { sessionData, loading } = useContext(SearchFilterContext)
  const [sessionState, setSessionState] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const [descriptionVisibility, setDescriptionVisibility] = useState({})
  const scheduleRef = useRef<HTMLDivElement>(null)
  const today = new Date()
  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()

  useEffect(() => {
    if (!loading && sessionData) {
      const seen = new Set()
      const uniqueSessions = sessionData
        .map((course) => ({
          ...course,
          events: course.events.map((event) => ({
            ...event,
            startDateTime: new Date(event.startDateTime), // Convert string to Date
            endDateTime: new Date(event.endDateTime),
          })),
        }))
        .filter((course) => {
          const eventDates = course.events
            .map((event) => event.date)
            .sort()
            .join(',')
          const key = `${course.uid}-${eventDates}`
          if (seen.has(key)) return false
          seen.add(key)
          return true
        })

      setSessionState(uniqueSessions)

      // Auto-select the earliest available month (up to 12 months ahead)
      const today = new Date()
      let earliestDate = null

      for (let i = 0; i < 12; i++) {
        const searchDate = new Date(
          today.getFullYear(),
          today.getMonth() + i,
          1
        )
        const found = uniqueSessions.some((course) =>
          course.events.some((event) => {
            const eventDate = new Date(event.date)
            return (
              eventDate.getFullYear() === searchDate.getFullYear() &&
              eventDate.getMonth() === searchDate.getMonth()
            )
          })
        )

        if (found) {
          earliestDate = searchDate
          break
        }
      }

      if (earliestDate) {
        setCurrentDate(earliestDate)
      }
    }
  }, [loading, sessionData])

  const filteredCourses = sessionState
    .filter((course) => {
      if (course.events.length > 0 && course.courseType === 'vilt') {
        const firstEventDate = new Date(course.events[0].date)
        return (
          firstEventDate.getFullYear() === currentDate.getFullYear() &&
          firstEventDate.getMonth() === currentDate.getMonth()
        )
      }
      return false
    })
    // Sort the courses based on the first event date
    .sort((a, b) => {
      const firstEventA = new Date(a.events[0].date).getTime()
      const firstEventB = new Date(b.events[0].date).getTime()
      return firstEventA - firstEventB
    })
  const handlePrint = () => {
    if (scheduleRef.current) {
      window.print() // Directly trigger the print
    }
  }

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1,
        1
      )
      return nextMonth
    })
  }

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1,
        1
      )
      return prevMonth
    })
  }

  const toggleDescriptionVisibility = (index) => {
    setDescriptionVisibility((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const isPreviousMonthDisabled =
    currentDate.getFullYear() === currentYear &&
    currentDate.getMonth() <= currentMonth

  return (
    <div className="grow py-8 main-container-height w-full container">
      <div className="flex justify-between">
        <h2 className="text-grays-dark text-2xl font-bold mb-4">
          Virtual Classroom Schedule <br />{' '}
          <span className="text-primary">
            {months[currentDate.getMonth()]} {currentDate.getFullYear()}
          </span>
        </h2>
        <div className="flex gap-2 mb-4 justify-center">
          {filteredCourses.length > 0 && (
            <motion.button
              onClick={handlePrint}
              className="h-10 justify-self-center"
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 1 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            >
              <img src={PrintIcon} alt="Print icon" />
            </motion.button>
          )}
          <motion.button
            onClick={handlePreviousMonth}
            disabled={isPreviousMonthDisabled}
            className={`h-10 blue-btn text-sm text-white rounded p-2 justify-self-center ${isPreviousMonthDisabled ? 'bg-grays-mediumlight' : 'bg-primary'}`}
            whileHover={isPreviousMonthDisabled ? {} : { scale: 1.025 }}
            whileTap={isPreviousMonthDisabled ? {} : { scale: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          >
            Previous Month
          </motion.button>
          <motion.button
            onClick={handleNextMonth}
            className="h-10 blue-btn bg-primary text-sm text-white rounded p-2 justify-self-center"
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          >
            Next Month
          </motion.button>
        </div>
      </div>

      <div className="bg-grays shadow rounded-md">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course, index) => (
            <div className="schedule-card" key={index}>
              <ScheduleCard>
                <CardContent>
                  <div className="">
                    <div className="flex gap-2">
                      <div className="basis-1/2 p-4">
                        <h4 className="text-sm font-semibold text-primary">
                          {course.courseName}
                        </h4>
                        <p className="text-sm font-normal text-grays-dark">
                          {course.courseCode}
                        </p>
                      </div>
                      <div className="basis-1/2 rounded-tr-md p-4">
                        {course.instructors.map((instructor, i) => (
                          <p
                            key={i}
                            className="text-sm font-normal"
                          >{`${instructor.firstname} ${instructor.lastname}`}</p>
                        ))}
                        <div className="mt-2">
                          {course.events.map((event, index) => (
                            <EventDate
                              key={index}
                              event={event}
                              className={'text-sm'}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="px-4 pb-4">
                      <button
                        onClick={() => toggleDescriptionVisibility(index)}
                        className="text-sm text-primary hover:underline"
                      >
                        {descriptionVisibility[index]
                          ? 'Hide Description'
                          : 'Show Description'}
                      </button>
                      {descriptionVisibility[index] && (
                        <p className="text-sm text-grays-dark font-normal mt-2">
                          {formatBulletPoints(course.courseDescription)}
                        </p>
                      )}
                    </div>
                  </div>
                </CardContent>
              </ScheduleCard>
            </div>
          ))
        ) : (
          <p className="p-4">
            There is currently no Virtual Classroom courses available for this
            month.
          </p>
        )}
      </div>
      <div id="scheduleToPrint" ref={scheduleRef}>
        <PrintSchedule
          filteredCourses={filteredCourses}
          currentDate={currentDate}
          months={months}
          convertTime={convertTime}
        />
      </div>
    </div>
  )
}

export default Schedule
