import React from 'react'
import { Hash } from '@/types/Hash'

function ErrorMessage({ message }: { message: string | string[] }) {
  return <li className="text-red-500 text-center ">{message}</li>
}

/*
 * {"errors":{"base":["This username already exists, please choose a different one"]}}
 */
function FormErrors({ errors }: { errors: Hash<string | string[]> | null }) {
  if (!errors) return null
  return Object.keys(errors).map(function (key) {
    const message = errors[key]

    return <ErrorMessage message={message} key={`form_errors_${key}`} />
  })
}

export { FormErrors }
