import { axiosInstance } from '../lib/services/axiosinstance'
import { API_URL } from '../lib/constants'
import { useAuth } from './useAuth'

const USERS_API_URL = `${API_URL}/user_api/v1/users`

export const useUsers = () => {
  const { getAuthHeaders } = useAuth()

  const loadUsers = async (branchId?: string) => {
    try {
      const endpoint = branchId
        ? `${USERS_API_URL}?branch_id=${branchId}`
        : USERS_API_URL

      const response = await axiosInstance.get(endpoint, {
        headers: getAuthHeaders(),
      })

      if (response.status !== 200) {
        console.warn(
          `WARN: Couldn't load users: '${response.status}':'${response.statusText}'`
        )
        return []
      }

      return response.data
    } catch (error) {
      console.error("ERROR: Couldn't load users", error)
      return []
    }
  }

  const addUser = async (user) => {
    try {
      const response = await axiosInstance.post(USERS_API_URL, user, {
        headers: getAuthHeaders(),
      })

      if (response.status !== 200) {
        throw new Error(`Couldn't add user: ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || 'An error occurred.'
      console.error("ERROR: Couldn't add user", error)
      throw new Error(errorMessage) // Throw error to be handled by caller
    }
  }

  const updateUser = async (user) => {
    try {
      const response = await axiosInstance.put(
        `${USERS_API_URL}/${user.id}`,
        user,
        { headers: getAuthHeaders() }
      )

      if (response.status !== 200) {
        throw new Error(`Couldn't update user: ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || 'An error occurred.'
      console.error("ERROR: Couldn't update user", error)
      throw new Error(errorMessage)
    }
  }

  const loadBranches = async (branchId?: string) => {
    try {
      const endpoint = branchId
        ? `${USERS_API_URL}/get_branch_and_sub_branches?branch_id=${branchId}`
        : `${USERS_API_URL}/get_branch_and_sub_branches`
      const response = await axiosInstance.get(endpoint, {
        headers: getAuthHeaders(),
      })

      if (response.status !== 200) {
        console.warn(
          `WARN: Couldn't load branches: '${response.status}':'${response.statusText}'`
        )
        return []
      }

      return response.data
    } catch (error) {
      console.error("ERROR: Couldn't load branches", error)
      return []
    }
  }

  return { loadUsers, addUser, updateUser, loadBranches }
}
