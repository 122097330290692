import React from 'react'
import { NavLink } from 'react-router-dom'

function DropdownNavItem({ title, to }: { title: string; to: string }) {
  return (
    <div className="nav-item nav-item-dropdown">
      <NavLink
        to={to}
        className={({ isActive }) =>
          'text-xs md:text-base hover:text-primary hover:border-b-2 hover:border-primary nav-dropdown-link ' +
          (isActive ? 'active-nav-dropdown-link' : 'nav-dropdown-link')
        }
      >
        {title}
      </NavLink>
    </div>
  )
}

export default DropdownNavItem
