import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

const menuItems = [
  { id: 'subscriptions', label: 'My Subscriptions', path: 'subscriptions' },
  { id: 'manage-users', label: 'Manage Users', path: 'manageusers' },
  { id: 'enroll-users', label: 'Enroll Users', path: 'enroll' },
  // TODO: are these used?
  // { id: 'enroll-user', label: 'Enroll User', path: 'enrolluser' },
  // { id: 'manage-user', label: 'Manage User', path: 'manageuser' },
]

const Admin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser } = useAuth()

  const currentPath = location.pathname.split('/').pop()

  //might change based on what user_level numbers can do what
  const filteredMenuItems = menuItems.filter((item) => {
    if (currentUser?.is_individual) {
      return item.id === 'subscriptions'
    } else if (currentUser?.user_level >= 6) {
      return ['subscriptions', 'enroll-user'].includes(item.id)
    } else {
      return ['subscriptions', 'enroll-users', 'manage-users'].includes(item.id)
    }
  })

  return (
    <div className="flex flex-col md:flex-row container mt-6 mb-6 gap-2 items-start">
      <nav className="w-52 border rounded border-gray-300 p-4 w-full md:w-auto">
        <ul className="space-y-1">
          {filteredMenuItems.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => navigate(item.path)}
                className={`w-full text-left p-2 pl-4 rounded ${
                  currentPath === item.path
                    ? 'bg-primary text-white'
                    : 'bg-white text-gray-900 hover:bg-gray-200'
                }`}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Content section */}
      <div className="flex-1 border rounded border-gray-300 p-6 w-full">
        <Outlet />
      </div>
    </div>
  )
}

export default Admin
