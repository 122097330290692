import { Course, Event, EventImpl, Session } from '@/types/Course'

export const flattenCourseData = (courses: Course[]) => {
  return courses.flatMap((course) => createSessionsForCourse(course))
}

export const createSessionsForCourse = (course: Course) => {
  // Filter out sessions where all events are in the past
  return course.sessions
    .filter((session) => sessionHasFutureEvent(session) && !session.is_private)
    .map((session) => createSessionDataForCourse(course, session))
}

const sessionHasFutureEvent = (session: Session) => {
  const now = new Date()
  return session.events.some((event) => new Date(event.date) >= now)
}

const createEventObjects = (events: Event[]) => {
  return events
    .map(
      (event) =>
        new EventImpl(
          event.date,
          event.start_time,
          event.end_time,
          event.effective_duration,
          event.timezone
        )
    )
    .sort((a, b) => a.endDateTime.getTime() - b.endDateTime.getTime())
}

const createSessionDataForCourse = (course: Course, session: Session) => {
  return {
    courseName: course.name,
    courseCode: course.code,
    courseDescription: course.description,
    courseType: course.course_type,
    courseDurationInMinutes: course.duration_in_minutes,
    uid: session.uid,
    numEnrolled: session.num_enrolled,
    maxEnrollment: session.max_enrollment,
    is_private: session.is_private,
    instructors: session.instructors ?? [],
    events: createEventObjects(session.events),
    locations: session.locations,
    plans: course.plans,
    keyword: course.name, // Assign keyword from course name
    instructor: session.instructors?.length
      ? session.instructors.map((inst) => `${inst.firstname} ${inst.lastname}`).join(', ')
      : '', // Use the first instructor if available
  }
}


export type SessionData = ReturnType<typeof createSessionDataForCourse>
