import React from 'react'

interface TabContentProps {
  activeTab: 'Courses' | 'Learning Plans'
  courses: Course[]
  learningPlans: Course[]
  students: Student[]
  handleCourseChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleStudentChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedCourses: string[]
  selectedStudents: string[]
  searchQuery: string
  setSearchQuery: (value: string) => void
  searchQueryStudents: string
  setSearchQueryStudents: (value: string) => void
}

interface Course {
  item_id: string
  item_name: string
  item_code: string
  type: number
}

interface Student {
  id: string
  first_name: string
  last_name: string
}

const TabContent: React.FC<TabContentProps> = ({
  activeTab,
  courses,
  learningPlans,
  students,
  handleCourseChange,
  handleStudentChange,
  selectedCourses,
  selectedStudents,
  searchQuery,
  setSearchQuery,
  searchQueryStudents,
  setSearchQueryStudents,
}) => (
  <div className="basis-full">
    <input
      type="text"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder={`Filter ${activeTab === 'Courses' ? 'courses' : 'learning plans'}`}
      className="p-2 border rounded w-full mt-4 lg:mt-0 mb-4 cursor-pointer"
    />
    <label className="block mb-2 text-primary">
      Select {activeTab === 'Courses' ? 'Courses' : 'Learning Plans'}:
    </label>
    <div className="space-y-2 mb-4 h-48 overflow-auto border rounded p-4">
      {(activeTab === 'Courses' ? courses : learningPlans).map((item) => (
        <div key={item.item_id}>
          <input
            type="checkbox"
            id={item.item_id}
            className="mr-2 cursor-pointer"
            value={item.item_id}
            defaultChecked={selectedCourses.includes(item.item_id)}
            onChange={handleCourseChange}
          />
          <label htmlFor={item.item_id}>
            {`${item.item_code} - ${item.item_name}`}
          </label>
        </div>
      ))}
    </div>
    <hr className="color-primary"></hr>
    <input
      type="text"
      value={searchQueryStudents}
      onChange={(e) => setSearchQueryStudents(e.target.value)}
      placeholder="Filter students"
      className="p-2 border rounded w-full my-4 cursor-pointer"
    />
    <label className="block mb-2 text-primary">Select Students:</label>
    <div className="space-y-2 mb-4 h-48 overflow-auto border rounded p-4">
      {students.map((student) => (
        <div key={student.id}>
          <input
            type="checkbox"
            id={student.id}
            className="mr-2 cursor-pointer"
            value={student.id}
            defaultChecked={selectedStudents.includes(student.id)}
            onChange={handleStudentChange}
          />
          <label htmlFor={student.id}>
            {`${student.first_name} ${student.last_name}`}
          </label>
        </div>
      ))}
    </div>
  </div>
)

export default TabContent
