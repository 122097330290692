import React, { useState, useEffect, useRef } from 'react'
import ChargebeeErrorMessage from '../PaymentMethods/ChargebeeErrorMessage'
import { ChargebeeCardComponent, ChargebeeFieldComponent } from '@/global'

export interface ElementProps {
  id: string
  label: string
  errorText: string
  type?: string
  placeholder?: string
  className?: string
  invalid: boolean
  onBlur?: React.MouseEventHandler
  onChange?: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
  onReady?: React.EventHandler<React.SyntheticEvent>
  setInvalid: (val: boolean) => void
}

function Element({
  id,
  label,
  errorText,
  type,
  invalid,
  setInvalid,
  cardComponent,
}: ElementProps & { cardComponent: ChargebeeCardComponent }) {
  const elementId = `card-${id}`
  const elementRef = useRef<HTMLDivElement>(null)

  const [field, setField] = useState<ChargebeeFieldComponent | null>(null)

  const [elementInitialized, setElementInitialized] = useState(false)

  useEffect(() => {
    if (elementInitialized || !elementRef.current) return

    // const existing = cardComponent.fields.find(
    //   (field) => field.id === elementId
    // )
    // if (existing) {
    //   console.log('Found exsiting field', existing.id)
    //   setField(existing)
    // } else {
    console.log(`Creating ${type} field at ${elementId}`)
    const cbField = cardComponent.createField(type).at(`#${elementId}`)
    cbField.on('focus', () => setInvalid(false))

    setField(cbField)
    console.log('Card component is mounted', cardComponent.isMounted())
    cardComponent.update({})
    //}

    setElementInitialized(true)

    return () => {
      if (field) field.deregister()
    }
  }, [cardComponent])

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <div id={elementId} className="chargebee-input" ref={elementRef}></div>
        {invalid && <ChargebeeErrorMessage>{errorText}</ChargebeeErrorMessage>}
      </div>
    </div>
  )
}

export default Element
