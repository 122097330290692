import { axiosInstance } from '@/lib/services/axiosinstance'
import { useAuth } from './useAuth'
import { API_URL } from '@/lib/constants'

export interface ISubscription {
  id: string
  active: boolean
}

export function useSubscriptions() {
  const { getAuthHeaders } = useAuth()
  async function listSubscriptions() {
    try {
      /* Note passport can accept both an email or username for login */
      const response = await axiosInstance.get(
        `${API_URL}/api/v1/subscriptions.json`,
        { headers: getAuthHeaders() }
      )

      const data = await response.data
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  async function updateSubscription(subscription: ISubscription) {
    try {
      /* Note passport can accept both an email or username for login */
      const response = await axiosInstance.put(
        `${API_URL}/api/v1/subscriptions.json`,
        { subscription: subscription },
        { headers: getAuthHeaders() }
      )

      return response.data
    } catch (e) {
      console.log(e)
      return { error: 'Could not cancel subscription, please contact support ' }
    }
  }

  return {
    listSubscriptions,
    updateSubscription,
  }
}
