import { Link } from 'react-router-dom'

const Footer = () => (
  <div className="mt-auto shrink-0 w-full bg-grays-dark p-6">
    <nav className="w-full container mx-auto flex justify-between items-center">
      <img
        src="/images/CTI_WTI_Logo_Rev.png"
        alt="CTI logo"
        className="hidden sm:block w-36"
      />
      <div className="w-full flex justify-between sm:w-2/3 md:w-3/5">
        <div>
          <ul>
            <li className="m-0 p-0">
              <Link
                to="/catalog"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Catalog
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/monthlyschedule"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Monthly Schedule
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/destination"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Destination Events
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/help-faq"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Help & FAQ
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className="m-0 p-0">
              <Link
                to="/about"
                className="text-white text-xs font-extralight hover:underline block"
              >
                About CTI
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/rdc"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Research & Development Center
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/instructors"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Meet Our Instructors
              </Link>
            </li>
            <li className="m-0 p-0">
              <Link
                to="/contact-us"
                className="text-white text-xs font-extralight hover:underline block"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
)

export default Footer
