import React from 'react'

import { cn } from '@/lib/utils'
import technetLogo from '../../../public/images/technet_logo.jpg'

type Sizes = 'sm' | 'md' | 'lg'
function TechnetLogo({ size, className }: { size: Sizes; className?: string }) {
  const getSizeClass = (size: Sizes) => {
    switch (size) {
      case 'sm':
        return 'w-8'
      case 'md':
        return 'w-16'
      case 'lg':
        return 'w-32'
      default:
        return 'w-8'
    }
  }

  const sizeClass = getSizeClass(size)
  return (
    <img
      className={cn(className, 'inline', sizeClass)}
      src={technetLogo}
      alt="Technet Logo"
    />
  )
}

export default TechnetLogo
